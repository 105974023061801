import i18n from "../i18n";
import {
  ApiErrorResponseWithMessage,
  ApiErrorResponseWithMessageFromJSON,
  ResponseError
} from "../api_client";


const getErrorMessage =
  (o: ApiErrorResponseWithMessage): string | undefined => {

    let result: string | undefined;
    // Пример с использованием типизации и payload
    // if (isArgumentIsRequiredBadRequestException(o)) {
    //   result = `Аргумент обязателен: ${o.payload.argumentName}`;
    // }
    if (!!o.errorName && i18n.exists(o.errorName, {ns: "apiExceptions"})) {
      result = i18n.t(o.errorName, {ns: "apiExceptions", ...o});
    }
    return result;
};

const tryParseApiError = async (e: any) => {
  let result: string | undefined;

  if (e instanceof ResponseError) {
    try {
      let jsonError = await e.response.clone().json();
      let typedApiError = ApiErrorResponseWithMessageFromJSON(jsonError);
      result = getErrorMessage(typedApiError) ?? undefined;
      if (!result) {
        throw e;
      }
    } catch {
      let textError = await e.response.clone().text();
      // Проверяем что текст - не json
      try {
        JSON.parse(textError);
      } catch {
        result = textError;
      }
    } finally {
      return result ?? "";
    }
  }
};

export default tryParseApiError;
