import React from "react";
import {Redirect, RouteProps} from "react-router-dom";
import {FallbackLoader} from "../shared/FallbackLoader";
import UserClaims from "./user-claims/UserClaims";
const Participants = React.lazy(() => import("../features/participants/routes/Participants"));
const Participant = React.lazy(() => import("../features/participants/routes/Participant"));
const CreateGroup = React.lazy(() => import("./groups-create/CreateGroup"));
const Group = React.lazy(() => import("./groups-id/Group"));
const Groups = React.lazy(() => import("./groups/Groups"));
const CreateUser = React.lazy(() => import("./users-create/CreateUser"));
const UserContainer = React.lazy(() => import("./users-id/UserContainer"));
const Users = React.lazy(() => import("./users/Users"));
const ParticipantUserContainer = React.lazy(() => import("./participants-user/ParticipantUserContainer"));

interface AppRoute extends RouteProps {
  ChildComponent: React.FC;
}

// TODO: сделать lazy-loading всех компонент
export const routes: AppRoute[] = [
  // Groups
  {
    exact: true,
    path: "/groups",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Groups />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/groups/create",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <CreateGroup />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/groups/:id/:tab",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Group />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/groups/:id",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Group />
      </React.Suspense>
    )
  },

  // Users
  {
    exact: true,
    path: "/users",

    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Users />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/users/create",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <CreateUser />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/users/:id/:tab",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <UserContainer />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/users/:id",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <UserContainer />
      </React.Suspense>
    )
  },
  // Participants
  {
    exact: true,
    path: "/participants/:id",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Participant />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/participants",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <Participants />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/participants/:id/users/:userId",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <ParticipantUserContainer />
      </React.Suspense>
    )
  },
  {
    exact: true,
    path: "/participants/:id/users/:userId/:tab",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <ParticipantUserContainer />
      </React.Suspense>
    )
  },

  {
    exact: true,
    path: "/userclaims",
    ChildComponent: () => (
      <React.Suspense fallback={<FallbackLoader />}>
        <UserClaims />
      </React.Suspense>
    )
  },
  // Default
  {
    path: "*",
    ChildComponent: () => <Redirect to="/users" />
  }
];

export const routePaths = routes.map((route) => route.path);
