import {Button, Form, Input} from "@p4ma/p4md-react";
import React, {useEffect, useState, useCallback, useMemo, useRef} from "react";
import {IAuthError} from "../types";
import {useHistory} from "react-router-dom";
import s from "../styles/loginForm.module.css";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

type ValidateStatus = "" | "error" | "success" | "warning" | "validating";

interface IFormData {
  login: string;
  password: string;
}

interface IProps {
  onSignIn: (values: IFormData) => void;
  extra?: React.ReactElement | React.ReactElement[];
  loading?: boolean;
  error?: IAuthError;
  showRegistrationBtn: boolean
}

const LoginForm = ({loading = false, extra, error, onSignIn, showRegistrationBtn}: IProps) => {
  const history = useHistory();
  const [extraItems, setExtraItems] = useState<React.ReactElement[]>();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
  const [form] = Form.useForm<{login: string; password: string}>();
  const loginValue = Form.useWatch("login", form);
  const passwordValue = Form.useWatch("password", form);
  const [firstRenderFormFields, setFirstRenderFormFields] = useState({
    login: true,
    password: true
  });
  const refLogin = useRef<any>();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [readonly, setReadonly] = useState(true);
  const {t} = useTranslation(["loginPage"]);

  useEffect(() => {
    setTimeout(() => {
      setReadonly(false);
      refLogin.current?.focus();
    }, 800);
  }, []);

  useEffect(() => {
    if (!loginValue) {
      return;
    }
    setFirstRenderFormFields((prev) => ({
      ...prev,
      login: false
    }));
  }, [loginValue]);

  useEffect(() => {
    if (!passwordValue) {
      return;
    }
    setFirstRenderFormFields((prev) => ({
      ...prev,
      password: false
    }));
  }, [passwordValue]);

  useEffect(() => {
    if (extra) {
      configureExtraItems(extra);
    }
  }, [extra]);

  useEffect(() => {
    if (error && error.code === "DISABLED") {
      setLoginErrorMessage(error.message);
    }
    if (error && error.code === "FAILED") {
      setPasswordErrorMessage(error.message);
    }
    if (error && error.code === "USER_PERMISSION_ERR") {
      setLoginErrorMessage(error.message);
    }

    return function cleanUp() {
      setLoginErrorMessage(undefined);
      setPasswordErrorMessage(undefined);
    };
  }, [error]);

  const handleOnRegisterClick = useCallback(() => history.push("/registration"), [history]);

  const handleOnRestorePasswordClick = useCallback(() => history.push("/restore"), [history]);

  const getValidateStatusLogin = (): ValidateStatus => {
    if (loading) {
      return "validating";
    }
    if (loginErrorMessage || error?.code === "FAILED") {
      return "error";
    }
    if (!loginValue && !firstRenderFormFields.login) {
      return "error";
    }
    return "";
  };

  const getValidateStatusPassword = (): ValidateStatus => {
    if (loading) {
      return "validating";
    }
    if (passwordErrorMessage) {
      return "error";
    }
    if (!passwordValue && !firstRenderFormFields.password) {
      return "error";
    }
    return "";
  };

  const onFinish = (values: IFormData) => {
    onSignIn(values);
  };

  const configureExtraItems = (extra: React.ReactElement | React.ReactElement[]) => {
    let extraItems: React.ReactElement[] = [];

    if (!Array.isArray(extra)) {
      extraItems = [
        <Form.Item key={1} className={`${s.form__item} ${s.margin__less}`}>
          {extra}
        </Form.Item>
      ];
    } else {
      extraItems = extra.map((el, i) => {
        return (
          <Form.Item key={i} className={`${s.form__item} ${s.margin__less}`}>
            {el}
          </Form.Item>
        );
      });
    }
    setExtraItems(extraItems);
  };

  const Icon = useMemo(() => {
    if(!visiblePassword) {
      return <EyeInvisibleOutlined onClick={() => setVisiblePassword(prevState => !prevState)}/>;
    }

    return <EyeOutlined onClick={() => setVisiblePassword(prevState => !prevState)}/>;
  }, [visiblePassword]);

  return (
    <Form form={form} layout="vertical" name="login" onFinish={onFinish} className={s.form} requiredMark={"optional"}>
      <Form.Item
        className={s.form__item}
        name="login"
        rules={[{required: true, message: t("rules.loginRequiredValidation")}]}
        validateStatus={getValidateStatusLogin()}
        help={loginErrorMessage}
        label={loginValue && t("form.loginCredentials")}
      >
        <Input
          className={s.form__input}
          placeholder={t("form.loginCredentials")}
          disabled={readonly}
          ref={refLogin}
          // autoComplete="login"
        />
      </Form.Item>

      <Form.Item
        className={`${s.form__item} ${s.margin__more}`}
        name="password"
        rules={[{required: true, message: t("rules.passwordRequiredValidation")}]}
        validateStatus={getValidateStatusPassword()}
        help={passwordErrorMessage}
        label={passwordValue && t("form.password")}
      >
        <Input
          type={!visiblePassword ? "password" : "text"}
          placeholder={t("form.password")}
          className={`${s.form__input} ${s.form__input__password}`}
          disabled={readonly}
          // autoComplete="new-password"
        />
      </Form.Item>

      <div className={s.form__item__icon}>
        {Icon}
      </div>

      <a
        className={`${s.form__item} ${s.form__href}`}
        onClick={handleOnRestorePasswordClick}
      >
        {t("restorePassword")}
      </a>

      <Form.Item className={`${s.form__item} ${s.margin__less}`}>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          disabled={!loginValue || !passwordValue}
          className={s.form__button}
        >
          {t("form.login")}
        </Button>
      </Form.Item>

      {/* Кнопка входа через госуслуги */}
      {extraItems}

      {showRegistrationBtn && <a
        className={`${s.form__item} ${s.form__href}`}
        onClick={handleOnRegisterClick}
      >
        {t("form.registration")}
      </a>}
    </Form>
  );
};

export default LoginForm;
