/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';
import type { ParticipantAttribute } from './ParticipantAttribute';
import {
    ParticipantAttributeFromJSON,
    ParticipantAttributeFromJSONTyped,
    ParticipantAttributeToJSON,
} from './ParticipantAttribute';

/**
 * 
 * @export
 * @interface ParticipantAttributeValue
 */
export interface ParticipantAttributeValue {
    /**
     * 
     * @type {number}
     * @memberof ParticipantAttributeValue
     */
    id?: number;
    /**
     * 
     * @type {ParticipantAttribute}
     * @memberof ParticipantAttributeValue
     */
    attribute?: ParticipantAttribute;
    /**
     * 
     * @type {Participant}
     * @memberof ParticipantAttributeValue
     */
    participant?: Participant;
    /**
     * 
     * @type {string}
     * @memberof ParticipantAttributeValue
     */
    value?: string;
}

/**
 * Check if a given object implements the ParticipantAttributeValue interface.
 */
export function instanceOfParticipantAttributeValue(value: object): boolean {
    return true;
}

export function ParticipantAttributeValueFromJSON(json: any): ParticipantAttributeValue {
    return ParticipantAttributeValueFromJSONTyped(json, false);
}

export function ParticipantAttributeValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantAttributeValue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'attribute': json['attribute'] == null ? undefined : ParticipantAttributeFromJSON(json['attribute']),
        'participant': json['participant'] == null ? undefined : ParticipantFromJSON(json['participant']),
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ParticipantAttributeValueToJSON(value?: ParticipantAttributeValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'attribute': ParticipantAttributeToJSON(value['attribute']),
        'participant': ParticipantToJSON(value['participant']),
        'value': value['value'],
    };
}

