/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthClaimReadDto,
  GetUserInformationDto,
  LoginDto,
  UserWithClaimsDto,
} from '../models/index';
import {
    AuthClaimReadDtoFromJSON,
    AuthClaimReadDtoToJSON,
    GetUserInformationDtoFromJSON,
    GetUserInformationDtoToJSON,
    LoginDtoFromJSON,
    LoginDtoToJSON,
    UserWithClaimsDtoFromJSON,
    UserWithClaimsDtoToJSON,
} from '../models/index';

export interface UiApiAuthLoginPostRequest {
    loginDto: LoginDto;
}

export interface UiApiAuthLogoutGetRequest {
    logoutId?: string;
}

export interface UiApiAuthUserInfoPostRequest {
    getUserInformationDto: GetUserInformationDto;
}

/**
 * 
 */
export class UiApiAuthApi extends runtime.BaseAPI {

    /**
     * Вход пользователя в модуль
     */
    async uiApiAuthLoginPostRaw(requestParameters: UiApiAuthLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['loginDto'] == null) {
            throw new runtime.RequiredError(
                'loginDto',
                'Required parameter "loginDto" was null or undefined when calling uiApiAuthLoginPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDtoToJSON(requestParameters['loginDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Вход пользователя в модуль
     */
    async uiApiAuthLoginPost(requestParameters: UiApiAuthLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiAuthLoginPostRaw(requestParameters, initOverrides);
    }

    /**
     * Выполняет выход пользователя из системы
     */
    async uiApiAuthLogoutGetRaw(requestParameters: UiApiAuthLogoutGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['logoutId'] != null) {
            queryParameters['logoutId'] = requestParameters['logoutId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Выполняет выход пользователя из системы
     */
    async uiApiAuthLogoutGet(requestParameters: UiApiAuthLogoutGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiAuthLogoutGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async uiApiAuthUserInfoPostRaw(requestParameters: UiApiAuthUserInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserWithClaimsDto>> {
        if (requestParameters['getUserInformationDto'] == null) {
            throw new runtime.RequiredError(
                'getUserInformationDto',
                'Required parameter "getUserInformationDto" was null or undefined when calling uiApiAuthUserInfoPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/auth/user-info`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUserInformationDtoToJSON(requestParameters['getUserInformationDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserWithClaimsDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiApiAuthUserInfoPost(requestParameters: UiApiAuthUserInfoPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserWithClaimsDto> {
        const response = await this.uiApiAuthUserInfoPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка клайм модуля авторизации текущего пользователя
     */
    async uiApiAuthUserPermissionsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuthClaimReadDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/auth/user-permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthClaimReadDtoFromJSON));
    }

    /**
     * Получение списка клайм модуля авторизации текущего пользователя
     */
    async uiApiAuthUserPermissionsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuthClaimReadDto>> {
        const response = await this.uiApiAuthUserPermissionsGetRaw(initOverrides);
        return await response.value();
    }

}
