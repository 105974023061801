/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * DTO для отправки данных о участнике в UI
 * @export
 * @interface ParticipantReadDto
 */
export interface ParticipantReadDto {
    /**
     * ID участника
     * @type {string}
     * @memberof ParticipantReadDto
     */
    id?: string;
    /**
     * Имя участника
     * @type {string}
     * @memberof ParticipantReadDto
     */
    name?: string;
    /**
     * Описание участника
     * @type {string}
     * @memberof ParticipantReadDto
     */
    description?: string;
}

/**
 * Check if a given object implements the ParticipantReadDto interface.
 */
export function instanceOfParticipantReadDto(value: object): boolean {
    return true;
}

export function ParticipantReadDtoFromJSON(json: any): ParticipantReadDto {
    return ParticipantReadDtoFromJSONTyped(json, false);
}

export function ParticipantReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function ParticipantReadDtoToJSON(value?: ParticipantReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
    };
}

