/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientAllClaim } from './ClientAllClaim';
import {
    ClientAllClaimFromJSON,
    ClientAllClaimFromJSONTyped,
    ClientAllClaimToJSON,
} from './ClientAllClaim';
import type { ParticipantUser } from './ParticipantUser';
import {
    ParticipantUserFromJSON,
    ParticipantUserFromJSONTyped,
    ParticipantUserToJSON,
} from './ParticipantUser';

/**
 * 
 * @export
 * @interface ParticipantUserClaim
 */
export interface ParticipantUserClaim {
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserClaim
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserClaim
     */
    participantUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaim
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaim
     */
    claimValue?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantUserClaim
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantUserClaim
     */
    validTo?: Date;
    /**
     * 
     * @type {ParticipantUser}
     * @memberof ParticipantUserClaim
     */
    participantUser?: ParticipantUser;
    /**
     * 
     * @type {ClientAllClaim}
     * @memberof ParticipantUserClaim
     */
    clientClaim?: ClientAllClaim;
}

/**
 * Check if a given object implements the ParticipantUserClaim interface.
 */
export function instanceOfParticipantUserClaim(value: object): boolean {
    return true;
}

export function ParticipantUserClaimFromJSON(json: any): ParticipantUserClaim {
    return ParticipantUserClaimFromJSONTyped(json, false);
}

export function ParticipantUserClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUserClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantUserId': json['participantUserId'] == null ? undefined : json['participantUserId'],
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'claimValue': json['claimValue'] == null ? undefined : json['claimValue'],
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
        'participantUser': json['participantUser'] == null ? undefined : ParticipantUserFromJSON(json['participantUser']),
        'clientClaim': json['clientClaim'] == null ? undefined : ClientAllClaimFromJSON(json['clientClaim']),
    };
}

export function ParticipantUserClaimToJSON(value?: ParticipantUserClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantUserId': value['participantUserId'],
        'clientId': value['clientId'],
        'claimType': value['claimType'],
        'claimValue': value['claimValue'],
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString()),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo']).toISOString()),
        'participantUser': ParticipantUserToJSON(value['participantUser']),
        'clientClaim': ClientAllClaimToJSON(value['clientClaim']),
    };
}

