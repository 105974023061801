/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupingInfo } from './GroupingInfo';
import {
    GroupingInfoFromJSON,
    GroupingInfoFromJSONTyped,
    GroupingInfoToJSON,
} from './GroupingInfo';
import type { SortingInfo } from './SortingInfo';
import {
    SortingInfoFromJSON,
    SortingInfoFromJSONTyped,
    SortingInfoToJSON,
} from './SortingInfo';
import type { SummaryInfo } from './SummaryInfo';
import {
    SummaryInfoFromJSON,
    SummaryInfoFromJSONTyped,
    SummaryInfoToJSON,
} from './SummaryInfo';

/**
 * 
 * @export
 * @interface DataSourceLoadOptions
 */
export interface DataSourceLoadOptions {
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    requireTotalCount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    requireGroupCount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    isCountQuery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    isSummaryQuery?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DataSourceLoadOptions
     */
    skip?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSourceLoadOptions
     */
    take?: number;
    /**
     * 
     * @type {Array<SortingInfo>}
     * @memberof DataSourceLoadOptions
     */
    sort?: Array<SortingInfo>;
    /**
     * 
     * @type {Array<GroupingInfo>}
     * @memberof DataSourceLoadOptions
     */
    group?: Array<GroupingInfo>;
    /**
     * 
     * @type {Array<any>}
     * @memberof DataSourceLoadOptions
     */
    filter?: Array<any>;
    /**
     * 
     * @type {Array<SummaryInfo>}
     * @memberof DataSourceLoadOptions
     */
    totalSummary?: Array<SummaryInfo>;
    /**
     * 
     * @type {Array<SummaryInfo>}
     * @memberof DataSourceLoadOptions
     */
    groupSummary?: Array<SummaryInfo>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSourceLoadOptions
     */
    select?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSourceLoadOptions
     */
    preSelect?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    remoteSelect?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    remoteGrouping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    expandLinqSumType?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataSourceLoadOptions
     */
    primaryKey?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataSourceLoadOptions
     */
    defaultSort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    stringToLower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    paginateViaPrimaryKey?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    sortByPrimaryKey?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceLoadOptions
     */
    allowAsyncOverSync?: boolean;
}

/**
 * Check if a given object implements the DataSourceLoadOptions interface.
 */
export function instanceOfDataSourceLoadOptions(value: object): boolean {
    return true;
}

export function DataSourceLoadOptionsFromJSON(json: any): DataSourceLoadOptions {
    return DataSourceLoadOptionsFromJSONTyped(json, false);
}

export function DataSourceLoadOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceLoadOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'requireTotalCount': json['requireTotalCount'] == null ? undefined : json['requireTotalCount'],
        'requireGroupCount': json['requireGroupCount'] == null ? undefined : json['requireGroupCount'],
        'isCountQuery': json['isCountQuery'] == null ? undefined : json['isCountQuery'],
        'isSummaryQuery': json['isSummaryQuery'] == null ? undefined : json['isSummaryQuery'],
        'skip': json['skip'] == null ? undefined : json['skip'],
        'take': json['take'] == null ? undefined : json['take'],
        'sort': json['sort'] == null ? undefined : ((json['sort'] as Array<any>).map(SortingInfoFromJSON)),
        'group': json['group'] == null ? undefined : ((json['group'] as Array<any>).map(GroupingInfoFromJSON)),
        'filter': json['filter'] == null ? undefined : json['filter'],
        'totalSummary': json['totalSummary'] == null ? undefined : ((json['totalSummary'] as Array<any>).map(SummaryInfoFromJSON)),
        'groupSummary': json['groupSummary'] == null ? undefined : ((json['groupSummary'] as Array<any>).map(SummaryInfoFromJSON)),
        'select': json['select'] == null ? undefined : json['select'],
        'preSelect': json['preSelect'] == null ? undefined : json['preSelect'],
        'remoteSelect': json['remoteSelect'] == null ? undefined : json['remoteSelect'],
        'remoteGrouping': json['remoteGrouping'] == null ? undefined : json['remoteGrouping'],
        'expandLinqSumType': json['expandLinqSumType'] == null ? undefined : json['expandLinqSumType'],
        'primaryKey': json['primaryKey'] == null ? undefined : json['primaryKey'],
        'defaultSort': json['defaultSort'] == null ? undefined : json['defaultSort'],
        'stringToLower': json['stringToLower'] == null ? undefined : json['stringToLower'],
        'paginateViaPrimaryKey': json['paginateViaPrimaryKey'] == null ? undefined : json['paginateViaPrimaryKey'],
        'sortByPrimaryKey': json['sortByPrimaryKey'] == null ? undefined : json['sortByPrimaryKey'],
        'allowAsyncOverSync': json['allowAsyncOverSync'] == null ? undefined : json['allowAsyncOverSync'],
    };
}

export function DataSourceLoadOptionsToJSON(value?: DataSourceLoadOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'requireTotalCount': value['requireTotalCount'],
        'requireGroupCount': value['requireGroupCount'],
        'isCountQuery': value['isCountQuery'],
        'isSummaryQuery': value['isSummaryQuery'],
        'skip': value['skip'],
        'take': value['take'],
        'sort': value['sort'] == null ? undefined : ((value['sort'] as Array<any>).map(SortingInfoToJSON)),
        'group': value['group'] == null ? undefined : ((value['group'] as Array<any>).map(GroupingInfoToJSON)),
        'filter': value['filter'],
        'totalSummary': value['totalSummary'] == null ? undefined : ((value['totalSummary'] as Array<any>).map(SummaryInfoToJSON)),
        'groupSummary': value['groupSummary'] == null ? undefined : ((value['groupSummary'] as Array<any>).map(SummaryInfoToJSON)),
        'select': value['select'],
        'preSelect': value['preSelect'],
        'remoteSelect': value['remoteSelect'],
        'remoteGrouping': value['remoteGrouping'],
        'expandLinqSumType': value['expandLinqSumType'],
        'primaryKey': value['primaryKey'],
        'defaultSort': value['defaultSort'],
        'stringToLower': value['stringToLower'],
        'paginateViaPrimaryKey': value['paginateViaPrimaryKey'],
        'sortByPrimaryKey': value['sortByPrimaryKey'],
        'allowAsyncOverSync': value['allowAsyncOverSync'],
    };
}

