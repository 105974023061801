/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    password: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCreateDto
     */
    clientIds?: Array<string>;
}

/**
 * Check if a given object implements the UserCreateDto interface.
 */
export function instanceOfUserCreateDto(value: object): boolean {
    if (!('userName' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('password' in value)) return false;
    return true;
}

export function UserCreateDtoFromJSON(json: any): UserCreateDto {
    return UserCreateDtoFromJSONTyped(json, false);
}

export function UserCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'firstName': json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'password': json['password'],
        'clientIds': json['clientIds'] == null ? undefined : json['clientIds'],
    };
}

export function UserCreateDtoToJSON(value?: UserCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userName': value['userName'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'password': value['password'],
        'clientIds': value['clientIds'],
    };
}

