/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaimValuesTree
 */
export interface ClaimValuesTree {
    /**
     * 
     * @type {string}
     * @memberof ClaimValuesTree
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimValuesTree
     */
    description?: string;
    /**
     * 
     * @type {Array<ClaimValuesTree>}
     * @memberof ClaimValuesTree
     */
    children?: Array<ClaimValuesTree>;
}

/**
 * Check if a given object implements the ClaimValuesTree interface.
 */
export function instanceOfClaimValuesTree(value: object): boolean {
    return true;
}

export function ClaimValuesTreeFromJSON(json: any): ClaimValuesTree {
    return ClaimValuesTreeFromJSONTyped(json, false);
}

export function ClaimValuesTreeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimValuesTree {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'description': json['description'] == null ? undefined : json['description'],
        'children': json['children'] == null ? undefined : ((json['children'] as Array<any>).map(ClaimValuesTreeFromJSON)),
    };
}

export function ClaimValuesTreeToJSON(value?: ClaimValuesTree | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'description': value['description'],
        'children': value['children'] == null ? undefined : ((value['children'] as Array<any>).map(ClaimValuesTreeToJSON)),
    };
}

