/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientClaimReadDto
 */
export interface ClientClaimReadDto {
    /**
     * Тип клейма
     * @type {string}
     * @memberof ClientClaimReadDto
     */
    type?: string;
    /**
     * Значение клейма
     * @type {string}
     * @memberof ClientClaimReadDto
     */
    value?: string;
}

/**
 * Check if a given object implements the ClientClaimReadDto interface.
 */
export function instanceOfClientClaimReadDto(value: object): boolean {
    return true;
}

export function ClientClaimReadDtoFromJSON(json: any): ClientClaimReadDto {
    return ClientClaimReadDtoFromJSONTyped(json, false);
}

export function ClientClaimReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientClaimReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ClientClaimReadDtoToJSON(value?: ClientClaimReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'value': value['value'],
    };
}

