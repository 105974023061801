/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AttributeType } from './AttributeType';
import {
    AttributeTypeFromJSON,
    AttributeTypeFromJSONTyped,
    AttributeTypeToJSON,
} from './AttributeType';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';

/**
 * 
 * @export
 * @interface ParticipantAttribute
 */
export interface ParticipantAttribute {
    /**
     * 
     * @type {number}
     * @memberof ParticipantAttribute
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantAttribute
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantAttribute
     */
    description?: string;
    /**
     * 
     * @type {AttributeType}
     * @memberof ParticipantAttribute
     */
    attributeType?: AttributeType;
    /**
     * 
     * @type {ParticipantType}
     * @memberof ParticipantAttribute
     */
    participantType?: ParticipantType;
}

/**
 * Check if a given object implements the ParticipantAttribute interface.
 */
export function instanceOfParticipantAttribute(value: object): boolean {
    return true;
}

export function ParticipantAttributeFromJSON(json: any): ParticipantAttribute {
    return ParticipantAttributeFromJSONTyped(json, false);
}

export function ParticipantAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantAttribute {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'attributeType': json['attributeType'] == null ? undefined : AttributeTypeFromJSON(json['attributeType']),
        'participantType': json['participantType'] == null ? undefined : ParticipantTypeFromJSON(json['participantType']),
    };
}

export function ParticipantAttributeToJSON(value?: ParticipantAttribute | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'attributeType': AttributeTypeToJSON(value['attributeType']),
        'participantType': ParticipantTypeToJSON(value['participantType']),
    };
}

