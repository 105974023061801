/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    newPassword: string;
}

/**
 * Check if a given object implements the ResetPasswordDto interface.
 */
export function instanceOfResetPasswordDto(value: object): boolean {
    if (!('userName' in value)) return false;
    if (!('token' in value)) return false;
    if (!('newPassword' in value)) return false;
    return true;
}

export function ResetPasswordDtoFromJSON(json: any): ResetPasswordDto {
    return ResetPasswordDtoFromJSONTyped(json, false);
}

export function ResetPasswordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'token': json['token'],
        'newPassword': json['newPassword'],
    };
}

export function ResetPasswordDtoToJSON(value?: ResetPasswordDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userName': value['userName'],
        'token': value['token'],
        'newPassword': value['newPassword'],
    };
}

