/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeType
 */
export interface AttributeType {
    /**
     * 
     * @type {number}
     * @memberof AttributeType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AttributeType
     */
    name?: string;
}

/**
 * Check if a given object implements the AttributeType interface.
 */
export function instanceOfAttributeType(value: object): boolean {
    return true;
}

export function AttributeTypeFromJSON(json: any): AttributeType {
    return AttributeTypeFromJSONTyped(json, false);
}

export function AttributeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function AttributeTypeToJSON(value?: AttributeType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

