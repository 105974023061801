import {UpdateUserParams} from "../api";

export const GET_USERS = "$USERS-SAGA/GET_USERS";
export const GET_USER_BY_ID = "$USERS-SAGA/GET_USER_BY_ID";
export const DELETE_USER = "$USERS-SAGA/DELETE_USER";
export const UPDATE_USER = "$USERS-SAGA/UPDATE_USER";
export const CREATE_USER = "$USERS-SAGA/CREATE_USER";

const getUsers = () => ({type: GET_USERS});
const getUserById = (id: string) => ({type: GET_USER_BY_ID, payload: id});
const deleteUser = (id: string) => ({type: DELETE_USER, payload: id});
const updateUser = (payload: UpdateUserParams) => ({
  type: UPDATE_USER,
  payload
});

export const usersActions = {
  getAll: getUsers,
  getById: getUserById,
  delete: deleteUser,
  update: updateUser
};
