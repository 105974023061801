/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationUser } from './ApplicationUser';
import {
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ApplicationUserLogin
 */
export interface ApplicationUserLogin {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserLogin
     */
    loginProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserLogin
     */
    providerKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserLogin
     */
    providerDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserLogin
     */
    userId: string;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUserLogin
     */
    user?: ApplicationUser;
}

/**
 * Check if a given object implements the ApplicationUserLogin interface.
 */
export function instanceOfApplicationUserLogin(value: object): boolean {
    if (!('userId' in value)) return false;
    return true;
}

export function ApplicationUserLoginFromJSON(json: any): ApplicationUserLogin {
    return ApplicationUserLoginFromJSONTyped(json, false);
}

export function ApplicationUserLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUserLogin {
    if (json == null) {
        return json;
    }
    return {
        
        'loginProvider': json['loginProvider'] == null ? undefined : json['loginProvider'],
        'providerKey': json['providerKey'] == null ? undefined : json['providerKey'],
        'providerDisplayName': json['providerDisplayName'] == null ? undefined : json['providerDisplayName'],
        'userId': json['userId'],
        'user': json['user'] == null ? undefined : ApplicationUserFromJSON(json['user']),
    };
}

export function ApplicationUserLoginToJSON(value?: ApplicationUserLogin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'loginProvider': value['loginProvider'],
        'providerKey': value['providerKey'],
        'providerDisplayName': value['providerDisplayName'],
        'userId': value['userId'],
        'user': ApplicationUserToJSON(value['user']),
    };
}

