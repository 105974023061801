/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserUpdateDto
     */
    clientIds?: Array<string>;
}

/**
 * Check if a given object implements the UserUpdateDto interface.
 */
export function instanceOfUserUpdateDto(value: object): boolean {
    return true;
}

export function UserUpdateDtoFromJSON(json: any): UserUpdateDto {
    return UserUpdateDtoFromJSONTyped(json, false);
}

export function UserUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userName': json['userName'] == null ? undefined : json['userName'],
        'password': json['password'] == null ? undefined : json['password'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'emailConfirmed': json['emailConfirmed'] == null ? undefined : json['emailConfirmed'],
        'isEnabled': json['isEnabled'] == null ? undefined : json['isEnabled'],
        'clientIds': json['clientIds'] == null ? undefined : json['clientIds'],
    };
}

export function UserUpdateDtoToJSON(value?: UserUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userName': value['userName'],
        'password': value['password'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'emailConfirmed': value['emailConfirmed'],
        'isEnabled': value['isEnabled'],
        'clientIds': value['clientIds'],
    };
}

