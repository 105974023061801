import {SagaReturnType} from "redux-saga/effects";
import {APIUrl, BasePathUrl} from "../appsettings";

interface GetParams {
    queryParams: any;
}

interface POSTParams {
    body?: any;
    queryParams?: any;
}

export type ApiGet = SagaReturnType<typeof get>
export type ApiPost = SagaReturnType<typeof post>

function get(endpoint: string, params?: GetParams) {
  const url = new URL(BasePathUrl + "/" + endpoint, APIUrl);

  if (params) {
    const {queryParams} = params;
    url.search = new URLSearchParams(queryParams).toString();
  };

  return fetch(url.toString());
}

function post(endpoint: string, params?: POSTParams) {
  const url = new URL(BasePathUrl + "/" + endpoint, APIUrl);

  if (params && params.queryParams) {
    const {queryParams} = params;
    url.search = new URLSearchParams(queryParams).toString();
  };

  if (params && params.body) {
    const {body} = params;
    return fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  return fetch(url.toString(), {
    method: "POST"
  });
}

function deleteReq(endpoint: string, params?: POSTParams) {
  const url = new URL(BasePathUrl + "/" + endpoint, APIUrl);

  if (params && params.queryParams) {
    const {queryParams} = params;
    url.search = new URLSearchParams(queryParams).toString();
  };

  if (params && params.body) {
    const {body} = params;
    return fetch(url.toString(), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  return fetch(url.toString(), {
    method: "DELETE"
  });
}

export const api = {
  get,
  post,
  delete: deleteReq
};
