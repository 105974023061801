import React from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {LoginDto, ResponseError} from "../../../api_client";
import {useApi} from "../../../hooks/useApi";
import {State} from "../../../redux/store";
import {authStore} from "../data";
import {ILoadingState, IRestore} from "../data/authStore";
import {t} from "i18next";

export const useAuthorization = () => {
  const {authApi, usersApi} = useApi();
  const {actions} = authStore;
  const dispatch = useDispatch();

  const beginLoading = (type: keyof ILoadingState) => {
    dispatch(actions.setError(undefined));
    dispatch(actions.setLoading({type: type, isLoading: true}));
  };

  const endLoading = (type: keyof ILoadingState) => {
    dispatch(actions.setLoading({type: type, isLoading: false}));
  };

  const setError = (err: any) => {
    dispatch(actions.setError(err));
  };

  const setRestore = (restore: IRestore) => {
    dispatch(actions.setRestore(restore));
  };

  const getUserClaims = async () => {
    const userClaims = await authApi.uiApiAuthUserPermissionsGet();
    dispatch(actions.setUserClaims(userClaims));
    const haveAccess = userClaims.some(({type, value}) => {
      return type === "access" && value === "accounts";
    });
    if (haveAccess) {
      dispatch(actions.setIsAuth(true));
    } else {
      setError({
        code: "USER_PERMISSION_ERR",
        message: t("messages.userAccessIsNotAllowed")
      });
      dispatch(actions.setIsAuth(false));
    }
  };

  const getUserInfo = async () => {
    const user = await usersApi.uiApiUsersCurrentGet();

    dispatch(actions.setUser(user));
  };

  const signIn = async (model: LoginDto) => {
    try {
      beginLoading("signIn");
      await authApi.uiApiAuthLoginPost({loginDto: model});
      const urlParams = new URLSearchParams(window.location.search);
      const returnUrl = urlParams.get("ReturnUrl");
      if (returnUrl) {
        window.location.replace(returnUrl);
      } else {
        await getUserClaims();
        await getUserInfo();
      }
    } catch (error) {
      if (error instanceof ResponseError) {
        error = await error.response.json();
      }
      setError(error);
    } finally {
      endLoading("signIn");
    }
  };

  const signOut = async () => {
    try {
      beginLoading("signOut");
      await authApi.uiApiAuthLogoutGet();
      dispatch(actions.setUserClaims([]));
      dispatch(actions.setIsAuth(false));
    } catch (error) {
      setError(error);
    } finally {
      endLoading("signOut");
    }
  };

  const error = useSelector((state: State) => state.auth.error, shallowEqual);
  const restore = useSelector((state: State) => state.auth.restore, shallowEqual);
  const loading = useSelector((state: State) => state.auth.loading, shallowEqual);
  const user = useSelector((state: State) => state.auth.data.user, shallowEqual);

  return {
    signIn,
    signOut,
    setRestore,
    error,
    loading,
    restore,
    user
  };
};
