import React from "react";

export interface UISettingsInterface  {
  name: string,
  logo: string,
  logoSmall?: string
}

const UISettingsContext = React.createContext<UISettingsInterface>({} as UISettingsInterface);

export default UISettingsContext;
