/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParticipantUserClaimReadDto
 */
export interface ParticipantUserClaimReadDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserClaimReadDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserClaimReadDto
     */
    participantUserId: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaimReadDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaimReadDto
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaimReadDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaimReadDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserClaimReadDto
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantUserClaimReadDto
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantUserClaimReadDto
     */
    validTo?: Date;
}

/**
 * Check if a given object implements the ParticipantUserClaimReadDto interface.
 */
export function instanceOfParticipantUserClaimReadDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('participantUserId' in value)) return false;
    if (!('clientId' in value)) return false;
    if (!('clientName' in value)) return false;
    if (!('type' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function ParticipantUserClaimReadDtoFromJSON(json: any): ParticipantUserClaimReadDto {
    return ParticipantUserClaimReadDtoFromJSONTyped(json, false);
}

export function ParticipantUserClaimReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUserClaimReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'participantUserId': json['participantUserId'],
        'clientId': json['clientId'],
        'clientName': json['clientName'],
        'type': json['type'],
        'value': json['value'],
        'description': json['description'] == null ? undefined : json['description'],
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
    };
}

export function ParticipantUserClaimReadDtoToJSON(value?: ParticipantUserClaimReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantUserId': value['participantUserId'],
        'clientId': value['clientId'],
        'clientName': value['clientName'],
        'type': value['type'],
        'value': value['value'],
        'description': value['description'],
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString()),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo']).toISOString()),
    };
}

