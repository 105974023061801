/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationRoleClaim } from './ApplicationRoleClaim';
import {
    ApplicationRoleClaimFromJSON,
    ApplicationRoleClaimFromJSONTyped,
    ApplicationRoleClaimToJSON,
} from './ApplicationRoleClaim';
import type { ApplicationUserRole } from './ApplicationUserRole';
import {
    ApplicationUserRoleFromJSON,
    ApplicationUserRoleFromJSONTyped,
    ApplicationUserRoleToJSON,
} from './ApplicationUserRole';

/**
 * 
 * @export
 * @interface ApplicationRole
 */
export interface ApplicationRole {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    normalizedName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    concurrencyStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationRole
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRole
     */
    clientId: string;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationRole
     */
    userRoles?: Array<ApplicationUserRole>;
    /**
     * 
     * @type {Array<ApplicationRoleClaim>}
     * @memberof ApplicationRole
     */
    roleClaims?: Array<ApplicationRoleClaim>;
}

/**
 * Check if a given object implements the ApplicationRole interface.
 */
export function instanceOfApplicationRole(value: object): boolean {
    if (!('clientId' in value)) return false;
    return true;
}

export function ApplicationRoleFromJSON(json: any): ApplicationRole {
    return ApplicationRoleFromJSONTyped(json, false);
}

export function ApplicationRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'normalizedName': json['normalizedName'] == null ? undefined : json['normalizedName'],
        'concurrencyStamp': json['concurrencyStamp'] == null ? undefined : json['concurrencyStamp'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'clientId': json['clientId'],
        'userRoles': json['userRoles'] == null ? undefined : ((json['userRoles'] as Array<any>).map(ApplicationUserRoleFromJSON)),
        'roleClaims': json['roleClaims'] == null ? undefined : ((json['roleClaims'] as Array<any>).map(ApplicationRoleClaimFromJSON)),
    };
}

export function ApplicationRoleToJSON(value?: ApplicationRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'normalizedName': value['normalizedName'],
        'concurrencyStamp': value['concurrencyStamp'],
        'description': value['description'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'clientId': value['clientId'],
        'userRoles': value['userRoles'] == null ? undefined : ((value['userRoles'] as Array<any>).map(ApplicationUserRoleToJSON)),
        'roleClaims': value['roleClaims'] == null ? undefined : ((value['roleClaims'] as Array<any>).map(ApplicationRoleClaimToJSON)),
    };
}

