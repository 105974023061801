/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';
import type { ParticipantAttribute } from './ParticipantAttribute';
import {
    ParticipantAttributeFromJSON,
    ParticipantAttributeFromJSONTyped,
    ParticipantAttributeToJSON,
} from './ParticipantAttribute';

/**
 * 
 * @export
 * @interface ParticipantType
 */
export interface ParticipantType {
    /**
     * 
     * @type {number}
     * @memberof ParticipantType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantType
     */
    description?: string;
    /**
     * 
     * @type {Array<ParticipantAttribute>}
     * @memberof ParticipantType
     */
    attributes?: Array<ParticipantAttribute>;
    /**
     * 
     * @type {Array<Participant>}
     * @memberof ParticipantType
     */
    participants?: Array<Participant>;
}

/**
 * Check if a given object implements the ParticipantType interface.
 */
export function instanceOfParticipantType(value: object): boolean {
    return true;
}

export function ParticipantTypeFromJSON(json: any): ParticipantType {
    return ParticipantTypeFromJSONTyped(json, false);
}

export function ParticipantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'attributes': json['attributes'] == null ? undefined : ((json['attributes'] as Array<any>).map(ParticipantAttributeFromJSON)),
        'participants': json['participants'] == null ? undefined : ((json['participants'] as Array<any>).map(ParticipantFromJSON)),
    };
}

export function ParticipantTypeToJSON(value?: ParticipantType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'attributes': value['attributes'] == null ? undefined : ((value['attributes'] as Array<any>).map(ParticipantAttributeToJSON)),
        'participants': value['participants'] == null ? undefined : ((value['participants'] as Array<any>).map(ParticipantToJSON)),
    };
}

