import React, {ReactElement} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {State} from "../../redux/store";
// import {selectIsAuth} from "../../redux/auth/selectors";

interface Props {
  children: ReactElement<any, any>;
}

const NotAuthRoute: React.FC<Props & RouteProps> = (props: Props) => {
  const {children, ...rest} = props;

  const isAuth = useSelector((state: State) => state.auth.data.isAuth);

  function renderer(location: any) {
    if (!isAuth) return children;
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={renderer} />;
};

export default NotAuthRoute;
