/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientReadDto
 */
export interface ClientReadDto {
    /**
     * ID Клиента
     * @type {string}
     * @memberof ClientReadDto
     */
    id?: string;
    /**
     * Имя клиента
     * @type {string}
     * @memberof ClientReadDto
     */
    name?: string;
}

/**
 * Check if a given object implements the ClientReadDto interface.
 */
export function instanceOfClientReadDto(value: object): boolean {
    return true;
}

export function ClientReadDtoFromJSON(json: any): ClientReadDto {
    return ClientReadDtoFromJSONTyped(json, false);
}

export function ClientReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ClientReadDtoToJSON(value?: ClientReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

