/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryInfo
 */
export interface SummaryInfo {
    /**
     * 
     * @type {string}
     * @memberof SummaryInfo
     */
    selector?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryInfo
     */
    summaryType?: string;
}

/**
 * Check if a given object implements the SummaryInfo interface.
 */
export function instanceOfSummaryInfo(value: object): boolean {
    return true;
}

export function SummaryInfoFromJSON(json: any): SummaryInfo {
    return SummaryInfoFromJSONTyped(json, false);
}

export function SummaryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'selector': json['selector'] == null ? undefined : json['selector'],
        'summaryType': json['summaryType'] == null ? undefined : json['summaryType'],
    };
}

export function SummaryInfoToJSON(value?: SummaryInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selector': value['selector'],
        'summaryType': value['summaryType'],
    };
}

