/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationRole } from './ApplicationRole';
import {
    ApplicationRoleFromJSON,
    ApplicationRoleFromJSONTyped,
    ApplicationRoleToJSON,
} from './ApplicationRole';

/**
 * 
 * @export
 * @interface ApplicationRoleClaim
 */
export interface ApplicationRoleClaim {
    /**
     * 
     * @type {number}
     * @memberof ApplicationRoleClaim
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRoleClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRoleClaim
     */
    claimValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRoleClaim
     */
    roleId: string;
    /**
     * 
     * @type {ApplicationRole}
     * @memberof ApplicationRoleClaim
     */
    role?: ApplicationRole;
}

/**
 * Check if a given object implements the ApplicationRoleClaim interface.
 */
export function instanceOfApplicationRoleClaim(value: object): boolean {
    if (!('roleId' in value)) return false;
    return true;
}

export function ApplicationRoleClaimFromJSON(json: any): ApplicationRoleClaim {
    return ApplicationRoleClaimFromJSONTyped(json, false);
}

export function ApplicationRoleClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationRoleClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'claimValue': json['claimValue'] == null ? undefined : json['claimValue'],
        'roleId': json['roleId'],
        'role': json['role'] == null ? undefined : ApplicationRoleFromJSON(json['role']),
    };
}

export function ApplicationRoleClaimToJSON(value?: ApplicationRoleClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'claimType': value['claimType'],
        'claimValue': value['claimValue'],
        'roleId': value['roleId'],
        'role': ApplicationRoleToJSON(value['role']),
    };
}

