import {BankOutlined, TeamOutlined, ToolOutlined, UserOutlined} from "@ant-design/icons";
import {Col, Layout, Menu, Row} from "@p4ma/p4md-react";
import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BasePathUrl} from "../../appsettings";
import UISettingsContext from "../../utils/UISettingsContext";
import UserMenu from "./components/UserMenu/UserMenu";
import s from "./defaultLayout.module.css";
import {useAuthorization} from "../../features/auth/api/useAuthorization";
import {useTranslation} from "react-i18next";
import LocalizationSelect from "../LocalizationSelect/LocalizationSelect";

const {Header, Content, Sider} = Layout;

const DefaultLayout: React.FC = (props) => {
  const {children} = props;
  const [selectedItems, setSelectedItems] = useState<string[]>();
  const location = useLocation();
  const {user} = useAuthorization();
  const {name, logo, logoSmall} = useContext(UISettingsContext);
  const {t} = useTranslation();

  useEffect(() => {
    // Route like /lk/auth/users
    let currentPage = location.pathname.replace(BasePathUrl, "");

    // Route like /lk/auth/users/userId/.../
    const regExp = new RegExp("^/([^/])*(/)");
    const matchedStr = currentPage.match(regExp);
    // RegExp will return /users/userId/.../ => /users/
    if (matchedStr?.length) {
      // currentPage = "/users" ("/users/".substring(...) => "/users")
      currentPage = matchedStr[0].substring(0, matchedStr[0].length - 1);
    }
    setSelectedItems([currentPage]);
  }, [props.children, location]);

  return (
    <Layout>
      {/* Header */}
      <Header style={{padding: "0 24px"}}>
        <Row justify="space-between" align="middle" wrap={false}>
          <Col>
            <div className={s.header_logo}>
              <img
                className={s.header_logo__icon}
                src={logoSmall ? logoSmall : logo}
                alt="ПЛАТФОРМА"
              />
              <label className={s.header_logo__label}>
                {name}
              </label>
            </div>
          </Col>
          <Col style={{display: "flex", alignItems: "center", gap: 15, flexWrap: "nowrap"}}>
            <LocalizationSelect/>
            <UserMenu />
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider width={180}>
          <Menu
            mode="inline"
            theme="light"
            style={{
              height: "100%",
              boxSizing: "border-box",
              paddingTop: "20px"
            }}
            selectedKeys={selectedItems}
          >
            <Menu.Item icon={<UserOutlined />} key="/users">
              <Link onClick={() => setSelectedItems(["/users"])} to="/users">
                {t("users")}
              </Link>
            </Menu.Item>
            <Menu.Item icon={<TeamOutlined />} key="/groups">
              <Link onClick={() => setSelectedItems(["/groups"])} to="/groups">
                {t("roles")}
              </Link>
            </Menu.Item>
            <Menu.Item icon={<BankOutlined />} key="/participants">
              <Link
                onClick={() => setSelectedItems(["/participants"])}
                to="/participants"
              >
                {t("participants")}
              </Link>
            </Menu.Item>
            <Menu.Item icon={<ToolOutlined />} key="/userclaims">
              <Link
                onClick={() => setSelectedItems(["/userclaims"])}
                to="/userclaims"
              >
                {t("userRights")}
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content className={s.content}>
            <div className={s.content__component}>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
