import {GroupToCreate} from "../groupsSlice";
export const DELETE_GROUP = "$GROUP-SAGA/DELETE_GROUP";
export const GET_GROUPS = "$GROUP-SAGA/GET_GROUPS";
export const CREATE_GROUP = "$GROUP-SAGA/CREATE_GROUP";

const getAll = () => ({
  type: GET_GROUPS
});
const createGroup = (payload: GroupToCreate) => ({
  type: CREATE_GROUP,
  payload
});
const deleteGroup = (payload: string) => ({
  type: DELETE_GROUP,
  payload
});

export const groupsActions = {
  delete: deleteGroup,
  create: createGroup,
  getAll
};
