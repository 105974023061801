import staticRU from "./locales/ru/static.json";
import staticKK from "./locales/kk/static.json";
import staticEN from "./locales/en/static.json";

import commonRU from "./locales/ru/common.json";
import commonKK from "./locales/kk/common.json";
import commonEN from "./locales/en/common.json";

import loginPageRU from "./locales/ru/loginPage.json";
import loginPageEN from "./locales/en/loginPage.json";
import loginPageKK from "./locales/kk/loginPage.json";

import usersModuleRU from "./locales/ru/usersModule.json";
import usersModuleKK from "./locales/kk/usersModule.json";
import usersModuleEN from "./locales/en/usersModule.json";

import rolesModuleRU from "./locales/ru/rolesModule.json";
import rolesModuleKK from "./locales/kk/rolesModule.json";
import rolesModuleEN from "./locales/en/rolesModule.json";

import participantsModuleRU from "./locales/ru/participantsModule.json";
import participantsModuleKK from "./locales/kk/participantsModule.json";
import participantsModuleEN from "./locales/en/participantsModule.json";

import claimsModuleRU from "./locales/ru/claimsModule.json";
import claimsModuleKK from "./locales/kk/claimsModule.json";
import claimsModuleEN from "./locales/en/claimsModule.json";

import apiExceptionsRU from "./locales/ru/apiExceptions.json";
import apiExceptionsKK from "./locales/kk/apiExceptions.json";
import apiExceptionsEN from "./locales/en/apiExceptions.json";

import LanguageDetector from "i18next-browser-languagedetector";
import i18n, {InitOptions} from "i18next";
import {initReactI18next} from "react-i18next";

// #region types
// Все локали должны быть одинаковы по ключам
// Смотрим на русский язык - так как скорее всего там все ключи определены
/**Все namespace для i18next */
type CustomLngResource = {
    /**Статичные для языка (название самого языка)*/
    static: typeof staticRU,
    common: typeof commonRU,
    /**Страница авторизации */
    loginPage: typeof loginPageRU,
    usersModule: typeof usersModuleRU,
    rolesModule: typeof rolesModuleRU,
    participantsModule: typeof participantsModuleRU,
    claimsModule: typeof claimsModuleRU,
    apiExceptions: typeof apiExceptionsRU
}

/** Все ресурсы для i18next */
type AllLngResources = {
    [LngKey in SupportedLanguage]: CustomLngResource
}
/** Все значения которые может принимать язык в i18next */
type SupportedLanguage = typeof supportedLanguages[number]
// #endregion


const i18nResources: AllLngResources = {
    ru: {
        static: staticRU,
        common: commonRU,
        loginPage: loginPageRU,
        usersModule: usersModuleRU,
        rolesModule: rolesModuleRU,
        participantsModule: participantsModuleRU,
        claimsModule: claimsModuleRU,
        apiExceptions: apiExceptionsRU
    },
    en: {
        static: staticEN,
        common: commonEN,
        loginPage: loginPageEN,
        usersModule: usersModuleEN,
        rolesModule: rolesModuleEN,
        participantsModule: participantsModuleEN,
        claimsModule: claimsModuleEN,
        apiExceptions: apiExceptionsEN
    },
    kk: {
        static: staticKK,
        common: commonKK,
        loginPage: loginPageKK,
        usersModule: usersModuleKK,
        rolesModule: rolesModuleKK,
        participantsModule: participantsModuleKK,
        claimsModule: claimsModuleKK,
        apiExceptions: apiExceptionsKK
    }
} as const;

/** Все языки для i18next */
const supportedLanguages = ["ru", "en", "kk"] as const;

const i18nInitOptions: InitOptions<AllLngResources> = {
    resources: i18nResources,
    ns: ["common"],
    supportedLngs: supportedLanguages,
    // 'ru'
    fallbackLng: "ru",
    debug: false, // Установите в false в продакшене
    interpolation: {
        escapeValue: false // Не экранировать строки HTML
    },
    react: {
        useSuspense: false
    }
} as const;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nInitOptions);

document.documentElement.lang = i18n.language;
i18n.on("languageChanged", (lng) => {document.documentElement.setAttribute("lang", lng);});

export default i18n;
export {supportedLanguages, type SupportedLanguage};