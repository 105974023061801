import {GroupToCreate} from "./../groupsSlice";
import {UpdateUserParams} from "./../../users/api/index";
import {usersApi} from "../../users/api";
import {api} from "./../../../utils/requestConstructor";
import {
  ApplicationUser,
  ApplicationUserRole
} from "../../../model-types/modelTypes";

// GET ALL
const getAllGroups = async () => {
  const res = await api.post("Account/GetRoles", {body: {}});
  return await res.json();
};

// GET BY ID
const getGroupById = async (id: string) => {
  const res = await api.get(`Account/GetRole/${id}`);
  if (res.ok) {
    const group = await res.json();
    const res2 = await api.get(`Account/GetUsersByRole/${id}`);
    const groupUsers = await res2.json();
    return {
      ...group,
      users: groupUsers
    };
  } else {
    throw new Error(res.statusText);
  }
};

// UPDATE
export interface UpdateGroupParams {
  idClient: string;
  roleId: string;
  name: string;
  description: string;
  userRoles?: any; // users
  roleClaims?: any;
}
const updateGroup = async ({
  idClient,
  roleId,
  ...reqBody
}: UpdateGroupParams) => {
  const res = await api.post(`Account/UpdateRole/${idClient}/${roleId}`, {
    body: {
      ...reqBody,
      clientId: idClient
    }
  });
  if (res.ok) {
    return res.statusText;
  } else {
    return Promise.reject(res.statusText);
  }
};

// CREATE
const createGroup = async ({users, ...group}: GroupToCreate) => {
  const res = await api.post(`Account/AddRole/${group.clientId}`, {
    body: group
  });
  if (res.ok) {
    const roleId = await res.text();

    if (users) {
      const usersRange = users?.map((user: ApplicationUser) => ({
        username: user.userName ? user.userName : "",
        email: user.email ? user.email : "",
        emailConfirmed: true,
        firstName: user.firstName ? user.firstName : "",
        lastName: user.lastName ? user.lastName : "",
        midleName: user.middleName ? user.middleName : "",
        phoneNumber: user.phoneNumber ? user.phoneNumber : "",
        userRoles: user.userRoles ?
          user.userRoles
              .map((role: ApplicationUserRole) => role.role?.name)
              .push(group.name) :
          [group.name],
        resetPassword: false
      }));
      await api.post("Account/UpdateUsersRange", {
        body: usersRange
      });
    }

    return roleId;
  }
};

// DELETE
const deleteGroup = async (id: string) => {
  const res = await api.post(`Account/DeleteRole/${id}`);
  if (res.ok) {
    return res.statusText;
  } else {
    throw new Error();
  }
};

// REMOVE USER
interface RemoveUserProps {
  roleName: string;
  userId: string;
}
const removeUserFromGroup = async ({roleName, userId}: RemoveUserProps) => {
  const user = await usersApi.getById(userId);
  const {
    email,
    firstName,
    lastName,
    middleName,
    userName,
    phoneNumber,
    userRoles
  } = user;

  const roles: string[] = [];
  userRoles?.forEach(({role}) => {
    if (role && role.name && role.name !== roleName) {
      roles.push(role.name);
    }
  });

  const updateParams: UpdateUserParams = {
    userId,
    username: userName || "",
    email: email || "",
    firstName: firstName || "",
    lastName: lastName || "",
    middleName: middleName || "",
    phoneNumber: phoneNumber || "",
    userRoles: roles
  };

  await usersApi.update(updateParams);
  return "ok";
};

// ===================== //

export const groupsApi = {
  getAll: getAllGroups,
  getById: getGroupById,
  update: updateGroup,
  create: createGroup,
  delete: deleteGroup,
  removeUser: removeUserFromGroup
};
