import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ActionStatus} from "../../utils/types";
import {IParticipant} from "./api";

interface InitialState {
  participants?: IParticipant[];
  participantToCreate: Partial<IParticipant>;
  participantToCreateClientId?: string;
  currentParticipant?: IParticipant & {id: string};
  actions: {
    getAll: ActionStatus;
    getById: ActionStatus;
    create: ActionStatus;
    update: ActionStatus;
    delete: ActionStatus;
  };
}

const initialState: InitialState = {
  participants: undefined,
  participantToCreateClientId: undefined,
  participantToCreate: {
    name: undefined,
    description: undefined,
    users: undefined,
    managedClaims: undefined
  },
  actions: {
    getAll: "idle",
    getById: "idle",
    create: "idle",
    update: "idle",
    delete: "idle"
  }
};

const slice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    setParticipantsActionStatus(
        state,
        action: PayloadAction<{
        name: keyof typeof initialState.actions;
        status: ActionStatus;
      }>
    ) {
      const {status, name} = action.payload;
      state.actions[name] = status;
    },
    setParticipants(state, action: PayloadAction<IParticipant[]>) {
      state.participants = action.payload;
    },
    setCurrentParticipant(
        state,
        action: PayloadAction<IParticipant & {id: string}>
    ) {
      state.currentParticipant = action.payload;
    },
    setParticipantToCreateInfo(
        state,
        action: PayloadAction<{name: string; description: string}>
    ) {
      state.participantToCreate.name = action.payload.name;
      state.participantToCreate.description = action.payload.description;
    },
    setParticipantToCreateClientId(state, action: PayloadAction<string>) {
      state.participantToCreateClientId = action.payload;
    },
    setParticipantToCreateUsers(state, action: PayloadAction<string[]>) {
      state.participantToCreate.users = action.payload;
    },
    setParticipantToCreateManagedClaims(
        state,
        action: PayloadAction<number[]>
    ) {
      state.participantToCreate.managedClaims = action.payload;
    },
    clearParticipantToCreate(state) {
      state.participantToCreate = {
        name: undefined,
        description: undefined,
        users: undefined,
        managedClaims: undefined
      };
      state.participantToCreateClientId = undefined;
    }
  }
});

export const {
  setParticipants,
  setCurrentParticipant,
  setParticipantToCreateInfo,
  setParticipantToCreateUsers,
  setParticipantToCreateManagedClaims,
  setParticipantToCreateClientId,
  setParticipantsActionStatus,
  clearParticipantToCreate
} = slice.actions;

const participantsReducer = slice.reducer;
export default participantsReducer;
