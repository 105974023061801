/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationRole } from './ApplicationRole';
import {
    ApplicationRoleFromJSON,
    ApplicationRoleFromJSONTyped,
    ApplicationRoleToJSON,
} from './ApplicationRole';
import type { ApplicationUser } from './ApplicationUser';
import {
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ApplicationUserRole
 */
export interface ApplicationUserRole {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    userId: string;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUserRole
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRole
     */
    roleId: string;
    /**
     * 
     * @type {ApplicationRole}
     * @memberof ApplicationUserRole
     */
    role?: ApplicationRole;
}

/**
 * Check if a given object implements the ApplicationUserRole interface.
 */
export function instanceOfApplicationUserRole(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('roleId' in value)) return false;
    return true;
}

export function ApplicationUserRoleFromJSON(json: any): ApplicationUserRole {
    return ApplicationUserRoleFromJSONTyped(json, false);
}

export function ApplicationUserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUserRole {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'user': json['user'] == null ? undefined : ApplicationUserFromJSON(json['user']),
        'roleId': json['roleId'],
        'role': json['role'] == null ? undefined : ApplicationRoleFromJSON(json['role']),
    };
}

export function ApplicationUserRoleToJSON(value?: ApplicationUserRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'user': ApplicationUserToJSON(value['user']),
        'roleId': value['roleId'],
        'role': ApplicationRoleToJSON(value['role']),
    };
}

