/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantAttributeValue } from './ParticipantAttributeValue';
import {
    ParticipantAttributeValueFromJSON,
    ParticipantAttributeValueFromJSONTyped,
    ParticipantAttributeValueToJSON,
} from './ParticipantAttributeValue';
import type { ParticipantClaim } from './ParticipantClaim';
import {
    ParticipantClaimFromJSON,
    ParticipantClaimFromJSONTyped,
    ParticipantClaimToJSON,
} from './ParticipantClaim';
import type { ParticipantRole } from './ParticipantRole';
import {
    ParticipantRoleFromJSON,
    ParticipantRoleFromJSONTyped,
    ParticipantRoleToJSON,
} from './ParticipantRole';
import type { ParticipantType } from './ParticipantType';
import {
    ParticipantTypeFromJSON,
    ParticipantTypeFromJSONTyped,
    ParticipantTypeToJSON,
} from './ParticipantType';
import type { ParticipantUser } from './ParticipantUser';
import {
    ParticipantUserFromJSON,
    ParticipantUserFromJSONTyped,
    ParticipantUserToJSON,
} from './ParticipantUser';

/**
 * Модель участника
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * Идентификатор
     * @type {number}
     * @memberof Participant
     */
    id?: number;
    /**
     * Имя
     * @type {string}
     * @memberof Participant
     */
    name?: string;
    /**
     * Описание
     * @type {string}
     * @memberof Participant
     */
    description?: string;
    /**
     * Статус участника
     * @type {string}
     * @memberof Participant
     */
    status?: string;
    /**
     * 
     * @type {ParticipantType}
     * @memberof Participant
     */
    participantTypes?: ParticipantType;
    /**
     * Значения атрибутов
     * @type {Array<ParticipantAttributeValue>}
     * @memberof Participant
     */
    attributeValues?: Array<ParticipantAttributeValue>;
    /**
     * Клеймы участника
     * @type {Array<ParticipantClaim>}
     * @memberof Participant
     */
    participantClaims?: Array<ParticipantClaim>;
    /**
     * Пользователи в участнике
     * @type {Array<ParticipantUser>}
     * @memberof Participant
     */
    participantUsers?: Array<ParticipantUser>;
    /**
     * Роли участника
     * @type {Array<ParticipantRole>}
     * @memberof Participant
     */
    participantRoles?: Array<ParticipantRole>;
}

/**
 * Check if a given object implements the Participant interface.
 */
export function instanceOfParticipant(value: object): boolean {
    return true;
}

export function ParticipantFromJSON(json: any): Participant {
    return ParticipantFromJSONTyped(json, false);
}

export function ParticipantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Participant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'status': json['status'] == null ? undefined : json['status'],
        'participantTypes': json['participantTypes'] == null ? undefined : ParticipantTypeFromJSON(json['participantTypes']),
        'attributeValues': json['attributeValues'] == null ? undefined : ((json['attributeValues'] as Array<any>).map(ParticipantAttributeValueFromJSON)),
        'participantClaims': json['participantClaims'] == null ? undefined : ((json['participantClaims'] as Array<any>).map(ParticipantClaimFromJSON)),
        'participantUsers': json['participantUsers'] == null ? undefined : ((json['participantUsers'] as Array<any>).map(ParticipantUserFromJSON)),
        'participantRoles': json['participantRoles'] == null ? undefined : ((json['participantRoles'] as Array<any>).map(ParticipantRoleFromJSON)),
    };
}

export function ParticipantToJSON(value?: Participant | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'status': value['status'],
        'participantTypes': ParticipantTypeToJSON(value['participantTypes']),
        'attributeValues': value['attributeValues'] == null ? undefined : ((value['attributeValues'] as Array<any>).map(ParticipantAttributeValueToJSON)),
        'participantClaims': value['participantClaims'] == null ? undefined : ((value['participantClaims'] as Array<any>).map(ParticipantClaimToJSON)),
        'participantUsers': value['participantUsers'] == null ? undefined : ((value['participantUsers'] as Array<any>).map(ParticipantUserToJSON)),
        'participantRoles': value['participantRoles'] == null ? undefined : ((value['participantRoles'] as Array<any>).map(ParticipantRoleToJSON)),
    };
}

