import {Client, ClientClaim} from "../../model-types/modelTypes";
import {createSlice} from "@reduxjs/toolkit";
import {getModules} from "./modulesActions";
import {ModuleWithExtensionsDto} from "../../api_client";

interface InitialState {
  modules?: ModuleWithExtensionsDto[];
  actionStatus: {
    getModules: {
      isFetching: boolean;
      isError: boolean;
      errorMessage?: "";
    };
  };
}

const initialState: InitialState = {
  modules: undefined,
  actionStatus: {
    getModules: {
      isFetching: false,
      isError: false,
      errorMessage: ""
    }
  }
};

const slice = createSlice({
  name: "modules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // === Get modules ===
    builder.addCase(getModules.pending, (state) => {
      state.actionStatus.getModules.isFetching = true;
      state.actionStatus.getModules.isError = false;
    });
    builder.addCase(getModules.fulfilled, (state, action) => {
      state.modules = action.payload;
      state.actionStatus.getModules.isFetching = false;
    });
    builder.addCase(getModules.rejected, (state) => {
      state.actionStatus.getModules.isFetching = false;
      state.actionStatus.getModules.isError = true;
    });
  }
});

const modulesReducer = slice.reducer;
export default modulesReducer;
