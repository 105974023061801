/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationUser } from './ApplicationUser';
import {
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';
import type { ParticipantUserClaim } from './ParticipantUserClaim';
import {
    ParticipantUserClaimFromJSON,
    ParticipantUserClaimFromJSONTyped,
    ParticipantUserClaimToJSON,
} from './ParticipantUserClaim';
import type { ParticipantUserRole } from './ParticipantUserRole';
import {
    ParticipantUserRoleFromJSON,
    ParticipantUserRoleFromJSONTyped,
    ParticipantUserRoleToJSON,
} from './ParticipantUserRole';

/**
 * 
 * @export
 * @interface ParticipantUser
 */
export interface ParticipantUser {
    /**
     * 
     * @type {number}
     * @memberof ParticipantUser
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantUser
     */
    participantId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantUser
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUser
     */
    userId?: string;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ParticipantUser
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {Participant}
     * @memberof ParticipantUser
     */
    participant?: Participant;
    /**
     * 
     * @type {Array<ParticipantUserClaim>}
     * @memberof ParticipantUser
     */
    participantUserClaims?: Array<ParticipantUserClaim>;
    /**
     * 
     * @type {Array<ParticipantUserRole>}
     * @memberof ParticipantUser
     */
    participantUserRoles?: Array<ParticipantUserRole>;
}

/**
 * Check if a given object implements the ParticipantUser interface.
 */
export function instanceOfParticipantUser(value: object): boolean {
    return true;
}

export function ParticipantUserFromJSON(json: any): ParticipantUser {
    return ParticipantUserFromJSONTyped(json, false);
}

export function ParticipantUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantId': json['participantId'] == null ? undefined : json['participantId'],
        'isAdmin': json['isAdmin'] == null ? undefined : json['isAdmin'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'user': json['user'] == null ? undefined : ApplicationUserFromJSON(json['user']),
        'participant': json['participant'] == null ? undefined : ParticipantFromJSON(json['participant']),
        'participantUserClaims': json['participantUserClaims'] == null ? undefined : ((json['participantUserClaims'] as Array<any>).map(ParticipantUserClaimFromJSON)),
        'participantUserRoles': json['participantUserRoles'] == null ? undefined : ((json['participantUserRoles'] as Array<any>).map(ParticipantUserRoleFromJSON)),
    };
}

export function ParticipantUserToJSON(value?: ParticipantUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantId': value['participantId'],
        'isAdmin': value['isAdmin'],
        'userId': value['userId'],
        'user': ApplicationUserToJSON(value['user']),
        'participant': ParticipantToJSON(value['participant']),
        'participantUserClaims': value['participantUserClaims'] == null ? undefined : ((value['participantUserClaims'] as Array<any>).map(ParticipantUserClaimToJSON)),
        'participantUserRoles': value['participantUserRoles'] == null ? undefined : ((value['participantUserRoles'] as Array<any>).map(ParticipantUserRoleToJSON)),
    };
}

