/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupingInfo
 */
export interface GroupingInfo {
    /**
     * 
     * @type {string}
     * @memberof GroupingInfo
     */
    selector?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupingInfo
     */
    desc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GroupingInfo
     */
    groupInterval?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GroupingInfo
     */
    isExpanded?: boolean;
}

/**
 * Check if a given object implements the GroupingInfo interface.
 */
export function instanceOfGroupingInfo(value: object): boolean {
    return true;
}

export function GroupingInfoFromJSON(json: any): GroupingInfo {
    return GroupingInfoFromJSONTyped(json, false);
}

export function GroupingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupingInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'selector': json['selector'] == null ? undefined : json['selector'],
        'desc': json['desc'] == null ? undefined : json['desc'],
        'groupInterval': json['groupInterval'] == null ? undefined : json['groupInterval'],
        'isExpanded': json['isExpanded'] == null ? undefined : json['isExpanded'],
    };
}

export function GroupingInfoToJSON(value?: GroupingInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selector': value['selector'],
        'desc': value['desc'],
        'groupInterval': value['groupInterval'],
        'isExpanded': value['isExpanded'],
    };
}

