import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationRole, ApplicationUser} from "../../model-types/modelTypes";
import {ActionStatus} from "./../../utils/types";

export interface UserToCreate {
  username: string;
  email: string;
  firstName: string;
  password: string;
  lastName: string;
  midleName?: string;
  phoneNumber: string;
  clientIds?: string[];
  userRoles: ApplicationRole[];
  userClaims: any[];
}
interface InitialState {
  users?: ApplicationUser[];
  userToCreate: Partial<UserToCreate>;
  currentUser?: ApplicationUser;
  currentUserRowKey: number;
  actions: {
    getUserById: ActionStatus;
    getUsers: ActionStatus;
    deleteUser: ActionStatus;
    createUser: ActionStatus;
    updateUser: ActionStatus;
  };
}

const initialState: InitialState = {
  users: undefined,
  userToCreate: {},
  currentUser: undefined,
  currentUserRowKey: 0,
  actions: {
    getUsers: "idle",
    getUserById: "idle",
    createUser: "idle",
    deleteUser: "idle",
    updateUser: "idle"
  }
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersActionStatus(
        state,
        action: PayloadAction<{
        name: keyof typeof initialState.actions;
        status: ActionStatus;
      }>
    ) {
      const {status, name} = action.payload;
      state.actions[name] = status;
    },
    setCurrentUser(state, action: PayloadAction<ApplicationUser>) {
      state.currentUser = action.payload;
    },
    setCurrentUserRowKey(state, action: PayloadAction<number>) {
      state.currentUserRowKey = action.payload;
    },
    setUsers(state, action: PayloadAction<ApplicationUser[]>) {
      state.users = action.payload;
    },
    clearCurrentUser(state) {
      state.currentUser = undefined;
    },
    setUserToCreateInfo(
        state,
        action: PayloadAction<Omit<UserToCreate, "userRoles" | "userClaims">>
    ) {
      state.userToCreate = {
        ...action.payload,
        userRoles: []
      };
    },
    clearUserToCreateInfo(state, action: PayloadAction<UserToCreate>) {
      state.userToCreate = {};
    },
    setUserToCreateRoles(state, action: PayloadAction<ApplicationRole[]>) {
      state.userToCreate.userRoles = action.payload;
    },
    setUserToCreateClaims(state, action: PayloadAction<any[]>) {
      state.userToCreate.userClaims = action.payload;
    }
  }
});

export const {
  clearUserToCreateInfo,
  setUserToCreateInfo,
  setUserToCreateRoles,
  setUserToCreateClaims,
  setCurrentUser,
  setCurrentUserRowKey,
  setUsersActionStatus,
  clearCurrentUser,
  setUsers
} = slice.actions;

const usersReducer = slice.reducer;
export default usersReducer;
