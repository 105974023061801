/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantClaim } from './ParticipantClaim';
import {
    ParticipantClaimFromJSON,
    ParticipantClaimFromJSONTyped,
    ParticipantClaimToJSON,
} from './ParticipantClaim';
import type { ParticipantRoleClaim } from './ParticipantRoleClaim';
import {
    ParticipantRoleClaimFromJSON,
    ParticipantRoleClaimFromJSONTyped,
    ParticipantRoleClaimToJSON,
} from './ParticipantRoleClaim';
import type { ParticipantUserClaim } from './ParticipantUserClaim';
import {
    ParticipantUserClaimFromJSON,
    ParticipantUserClaimFromJSONTyped,
    ParticipantUserClaimToJSON,
} from './ParticipantUserClaim';

/**
 * 
 * @export
 * @interface ClientAllClaim
 */
export interface ClientAllClaim {
    /**
     * 
     * @type {string}
     * @memberof ClientAllClaim
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAllClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAllClaim
     */
    valueType?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientAllClaim
     */
    description?: string;
    /**
     * 
     * @type {Array<ParticipantClaim>}
     * @memberof ClientAllClaim
     */
    participantClaims?: Array<ParticipantClaim>;
    /**
     * 
     * @type {Array<ParticipantUserClaim>}
     * @memberof ClientAllClaim
     */
    participantUserClaims?: Array<ParticipantUserClaim>;
    /**
     * 
     * @type {Array<ParticipantRoleClaim>}
     * @memberof ClientAllClaim
     */
    participantRoleClaims?: Array<ParticipantRoleClaim>;
}

/**
 * Check if a given object implements the ClientAllClaim interface.
 */
export function instanceOfClientAllClaim(value: object): boolean {
    return true;
}

export function ClientAllClaimFromJSON(json: any): ClientAllClaim {
    return ClientAllClaimFromJSONTyped(json, false);
}

export function ClientAllClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAllClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'valueType': json['valueType'] == null ? undefined : json['valueType'],
        'description': json['description'] == null ? undefined : json['description'],
        'participantClaims': json['participantClaims'] == null ? undefined : ((json['participantClaims'] as Array<any>).map(ParticipantClaimFromJSON)),
        'participantUserClaims': json['participantUserClaims'] == null ? undefined : ((json['participantUserClaims'] as Array<any>).map(ParticipantUserClaimFromJSON)),
        'participantRoleClaims': json['participantRoleClaims'] == null ? undefined : ((json['participantRoleClaims'] as Array<any>).map(ParticipantRoleClaimFromJSON)),
    };
}

export function ClientAllClaimToJSON(value?: ClientAllClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'claimType': value['claimType'],
        'valueType': value['valueType'],
        'description': value['description'],
        'participantClaims': value['participantClaims'] == null ? undefined : ((value['participantClaims'] as Array<any>).map(ParticipantClaimToJSON)),
        'participantUserClaims': value['participantUserClaims'] == null ? undefined : ((value['participantUserClaims'] as Array<any>).map(ParticipantUserClaimToJSON)),
        'participantRoleClaims': value['participantRoleClaims'] == null ? undefined : ((value['participantRoleClaims'] as Array<any>).map(ParticipantRoleClaimToJSON)),
    };
}

