import {Button, Form, Input} from "@p4ma/p4md-react";
import React, {useEffect, useState} from "react";
import {IRestorePasswordStep} from "../types";
import {useHistory} from "react-router-dom";
import s from "../styles/restorePasswordForm.module.css";
import {useApi} from "../../../hooks/useApi";
import {useAuthorization} from "../api/useAuthorization";
import tryParseApiError from "../../../utils/tryParseApiError";
import {useTranslation} from "react-i18next";

type ValidateStatus = "" | "error" | "success" | "warning" | "validating";

interface IFormData {
  login: string;
  code: string;
}

interface IProps {
  changeStep: (step: IRestorePasswordStep) => void;
  checkCodeStep?: boolean;
}

const RestorePasswordForm = ({checkCodeStep = false, changeStep}: IProps) => {
  const history = useHistory();
  const {restoreAccessApi} = useApi();
  const {setRestore} = useAuthorization();
  const [form] = Form.useForm<{login: string; code: string}>();
  const [firstRenderFormFields, setFirstRenderFormFields] = useState({
    login: true,
    code: true
  });
  const loginValue = Form.useWatch("login", form);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const codeValue = Form.useWatch("code", form);
  const [codeErrorMessage, setCodeErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState(60);
  const [resendCodeButton, setResendCodeButton] = useState<"timer" | "button">();
  const {t} = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => setCounter(counter - 1), 1000);
    if (counter < 1) setResendCodeButton("button");

    return () => clearTimeout(timer);
  }, [counter]);

  useEffect(() => {
    if (!loginValue) {
      return;
    }
    setFirstRenderFormFields((prev) => ({
      ...prev,
      login: false
    }));
    setLoginErrorMessage(undefined);
  }, [loginValue]);

  useEffect(() => {
    if (!codeValue) {
      return;
    }
    setFirstRenderFormFields((prev) => ({
      ...prev,
      code: false
    }));
  }, [codeValue]);

  useEffect(() => {
    return function cleanUp() {
      setLoginErrorMessage(undefined);
      setCodeErrorMessage(undefined);
    };
  }, []);

  const getValidateStatusLogin = (): ValidateStatus => {
    if (loading) {
      return "validating";
    }
    if (loginErrorMessage || (!loginValue && !firstRenderFormFields.login)) {
      return "error";
    }
    return "";
  };

  const getValidateStatusCode = (): ValidateStatus => {
    if (loading) {
      return "validating";
    }
    if (codeErrorMessage || (!codeValue && !firstRenderFormFields.code)) {
      return "error";
    }
    return "";
  };

  const requestCode = async (login: string) => {
    try {
      setLoading(true);
      await restoreAccessApi.uiApiRestoreAccessSendRestoreCodePost({login: login, type: "email"});
      setLoginErrorMessage(undefined);
      changeStep("checkCode");
      setResendCodeButton("timer");
      setCounter(60);
    } catch (error: any) {
      const textParseError = await tryParseApiError(error);
      setLoginErrorMessage(textParseError ? textParseError : t("messages.defaultMessageError"));
    } finally {
      setLoading(false);
    }
  };

  const checkCode = async ({login, code}: {login: string, code: string}) => {
    try {
      setLoading(true);
      const res = await restoreAccessApi.uiApiRestoreAccessCheckRestoreCodePost({login: login, code: code});
      setRestore({login: login, token: res});
      changeStep("updatePassword");

    } catch (error) {
      setCodeErrorMessage(
        t("restorePasswordForm.codeIsIncorrect", {ns: "loginPage"})
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: IFormData) => {
    if (checkCodeStep) checkCode(values);
    else requestCode(values.login);
  };

  const ResendButton = () => {
    if (resendCodeButton == "timer") {
      return (
        <span className={`${s.restore_code_timer} ${s.margin__middle}`}>
          {t("restorePasswordForm.sendCodeRepeatAfterTime", {ns: "loginPage", time: counter})}
        </span>
      );
    } else if (resendCodeButton == "button") {
      return (
        <a
          className={`${s.form__item} ${s.margin__less} ${s.form__href}`}
          onClick={() => requestCode(loginValue)}
        >
          {t("restorePasswordForm.sendCodeRepeat", {ns: "loginPage"})}
        </a>
      );
    }

     return null;
  };

  return (
    <Form form={form} layout="vertical" name="restorePassword" onFinish={onFinish} className={s.form} requiredMark={"optional"}>
      <Form.Item
        className={s.form__item}
        name="login"
        rules={[{required: true, message: t("rules.loginRequiredValidation", {ns: "loginPage"})}]}
        validateStatus={getValidateStatusLogin()}
        help={loginErrorMessage}
        label={loginValue && t("form.loginCredentials", {ns: "loginPage"})}
      >
        <Input
          disabled={checkCodeStep}
          className={s.form__input}
          placeholder={t("form.loginCredentials", {ns: "loginPage"})}
          // autoComplete="login"
        />
      </Form.Item>

      {checkCodeStep &&
        <>
          <span className={s.restore_code_help_text}>
              {t("restorePasswordForm.enterConfirmationCodeOnEmail", {ns: "loginPage"})}
          </span>
          <Form.Item
            className={s.form__item}
            name="code"
            rules={[
              {
                required: true,
                message: t("rules.codeRequiredValidation", {ns: "loginPage"})
              }
            ]}
            validateStatus={getValidateStatusCode()}
            help={codeErrorMessage}
            label={codeValue && t("restorePasswordForm.confirmCode", {ns: "loginPage"})}
          >
            <Input
              className={s.form__input}
              placeholder={t("restorePasswordForm.confirmCode", {ns: "loginPage"})}
            />
          </Form.Item>
          <ResendButton />
        </>
      }

      <Form.Item className={`${s.form__item} ${s.margin__less}`}>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          disabled={checkCodeStep ?  (!loginValue || !codeValue) : !loginValue}
          className={s.form__button}
        >
          {checkCodeStep ? t("continue") : t("restorePassword", {ns: "loginPage"})}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RestorePasswordForm;
