/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoadResult
 */
export interface LoadResult {
    /**
     * 
     * @type {Array<any>}
     * @memberof LoadResult
     */
    data?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof LoadResult
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoadResult
     */
    groupCount?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoadResult
     */
    summary?: Array<any>;
}

/**
 * Check if a given object implements the LoadResult interface.
 */
export function instanceOfLoadResult(value: object): boolean {
    return true;
}

export function LoadResultFromJSON(json: any): LoadResult {
    return LoadResultFromJSONTyped(json, false);
}

export function LoadResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadResult {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : json['data'],
        'totalCount': json['totalCount'] == null ? undefined : json['totalCount'],
        'groupCount': json['groupCount'] == null ? undefined : json['groupCount'],
        'summary': json['summary'] == null ? undefined : json['summary'],
    };
}

export function LoadResultToJSON(value?: LoadResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'totalCount': value['totalCount'],
        'groupCount': value['groupCount'],
        'summary': value['summary'],
    };
}

