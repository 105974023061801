/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * DTO для создания нового участника
 * @export
 * @interface ParticipantCreateDto
 */
export interface ParticipantCreateDto {
    /**
     * Имя участника
     * @type {string}
     * @memberof ParticipantCreateDto
     */
    name: string;
    /**
     * Описание участника
     * @type {string}
     * @memberof ParticipantCreateDto
     */
    description?: string;
    /**
     * Доступ к псевдонимам модулей
     * @type {Array<string>}
     * @memberof ParticipantCreateDto
     */
    clientIds?: Array<string>;
}

/**
 * Check if a given object implements the ParticipantCreateDto interface.
 */
export function instanceOfParticipantCreateDto(value: object): boolean {
    if (!('name' in value)) return false;
    return true;
}

export function ParticipantCreateDtoFromJSON(json: any): ParticipantCreateDto {
    return ParticipantCreateDtoFromJSONTyped(json, false);
}

export function ParticipantCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'clientIds': json['clientIds'] == null ? undefined : json['clientIds'],
    };
}

export function ParticipantCreateDtoToJSON(value?: ParticipantCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'clientIds': value['clientIds'],
    };
}

