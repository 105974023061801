import {call, CallEffect, PutEffect} from "@redux-saga/core/effects";
import {AnyAction} from "@reduxjs/toolkit";
import {put, takeEvery} from "redux-saga/effects";
import {ApplicationRole} from "../../../../model-types/modelTypes";
import {groupsApi} from "../../api/index";
import {
  setGroups,
  setGroupsActionStatus
} from "../../groupsSlice";
import {GET_GROUPS} from "../../actions/index";

function* getGroupsWorker(action: {type: typeof GET_GROUPS}): Generator<
  // step types
  CallEffect<{data: ApplicationRole[]}> | PutEffect<AnyAction>,
  // return type
  void,
  // intermediate argument
  {data: ApplicationRole[]}
> {
  yield put(setGroupsActionStatus({name: "getAll", status: "pending"}));
  const groups = yield call(groupsApi.getAll);
  yield put(setGroups(groups));
  yield put(setGroupsActionStatus({name: "getAll", status: "fulfilled"}));
}

function* getGroupsWatcher() {
  yield takeEvery(GET_GROUPS, getGroupsWorker);
}

export default getGroupsWatcher;
