import {api} from "./../../utils/requestConstructor";
import {Client, ClientClaim} from "../../model-types/modelTypes";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getClients = createAsyncThunk<
    // Return type of the payload creator
    Client[]
>(
    "clients/getClients",
    async () => {
      try {
        const res = await api.get("Client/GetClients");
        if (res.ok) {
          const data = await res.json();
          return data as Client[];
        } else {
          return Promise.reject(res.statusText);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    }
);

export const getClientClaims = createAsyncThunk<
    // Return type of the payload creator
    ClientClaim[],
    // First argument of the payload creator
    string
>(
    "clients/getClientClaims",
    async (clientId: string) => {
      try {
        const res = await api.get(`Client/GetClientAllClaims/${clientId}`);
        if (res.ok) {
          const data = await res.json();
          return data;
        } else {
          return Promise.reject(res.statusText);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    }
);

export const getClaims = createAsyncThunk<
    // Return type of the payload creator
    ClientClaim[]
>(
    "clients/getClaims",
    async () => {
      try {
        const getClientsRes = await api.get("Client/GetClients");
        if (getClientsRes.ok) {
          const clients: Client[] = await getClientsRes.json();
          const claims = clients.map(async (client) => {
            return await api.get(`Client/GetClientClaims/${client.clientId}`);
          });
          return claims as ClientClaim[];
        } else {
          return Promise.reject(getClientsRes.statusText);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    }
);
