/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientAllClaim } from './ClientAllClaim';
import {
    ClientAllClaimFromJSON,
    ClientAllClaimFromJSONTyped,
    ClientAllClaimToJSON,
} from './ClientAllClaim';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';

/**
 * 
 * @export
 * @interface ParticipantClaim
 */
export interface ParticipantClaim {
    /**
     * 
     * @type {number}
     * @memberof ParticipantClaim
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantClaim
     */
    participantId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantClaim
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantClaim
     */
    claimValue?: string;
    /**
     * 
     * @type {Participant}
     * @memberof ParticipantClaim
     */
    participant?: Participant;
    /**
     * 
     * @type {ClientAllClaim}
     * @memberof ParticipantClaim
     */
    clientClaim?: ClientAllClaim;
}

/**
 * Check if a given object implements the ParticipantClaim interface.
 */
export function instanceOfParticipantClaim(value: object): boolean {
    return true;
}

export function ParticipantClaimFromJSON(json: any): ParticipantClaim {
    return ParticipantClaimFromJSONTyped(json, false);
}

export function ParticipantClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantId': json['participantId'] == null ? undefined : json['participantId'],
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'claimValue': json['claimValue'] == null ? undefined : json['claimValue'],
        'participant': json['participant'] == null ? undefined : ParticipantFromJSON(json['participant']),
        'clientClaim': json['clientClaim'] == null ? undefined : ClientAllClaimFromJSON(json['clientClaim']),
    };
}

export function ParticipantClaimToJSON(value?: ParticipantClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantId': value['participantId'],
        'clientId': value['clientId'],
        'claimType': value['claimType'],
        'claimValue': value['claimValue'],
        'participant': ParticipantToJSON(value['participant']),
        'clientClaim': ClientAllClaimToJSON(value['clientClaim']),
    };
}

