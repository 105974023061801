/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClaimDto
 */
export interface ClaimDto {
    /**
     * 
     * @type {string}
     * @memberof ClaimDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimDto
     */
    value?: string;
}

/**
 * Check if a given object implements the ClaimDto interface.
 */
export function instanceOfClaimDto(value: object): boolean {
    if (!('type' in value)) return false;
    return true;
}

export function ClaimDtoFromJSON(json: any): ClaimDto {
    return ClaimDtoFromJSONTyped(json, false);
}

export function ClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimDto {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function ClaimDtoToJSON(value?: ClaimDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'value': value['value'],
    };
}

