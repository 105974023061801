/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantRole } from './ParticipantRole';
import {
    ParticipantRoleFromJSON,
    ParticipantRoleFromJSONTyped,
    ParticipantRoleToJSON,
} from './ParticipantRole';
import type { ParticipantUser } from './ParticipantUser';
import {
    ParticipantUserFromJSON,
    ParticipantUserFromJSONTyped,
    ParticipantUserToJSON,
} from './ParticipantUser';

/**
 * 
 * @export
 * @interface ParticipantUserRole
 */
export interface ParticipantUserRole {
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserRole
     */
    participantRoleId?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserRole
     */
    participantUserId?: number;
    /**
     * 
     * @type {ParticipantRole}
     * @memberof ParticipantUserRole
     */
    participantRole?: ParticipantRole;
    /**
     * 
     * @type {ParticipantUser}
     * @memberof ParticipantUserRole
     */
    participantUser?: ParticipantUser;
}

/**
 * Check if a given object implements the ParticipantUserRole interface.
 */
export function instanceOfParticipantUserRole(value: object): boolean {
    return true;
}

export function ParticipantUserRoleFromJSON(json: any): ParticipantUserRole {
    return ParticipantUserRoleFromJSONTyped(json, false);
}

export function ParticipantUserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUserRole {
    if (json == null) {
        return json;
    }
    return {
        
        'participantRoleId': json['participantRoleId'] == null ? undefined : json['participantRoleId'],
        'participantUserId': json['participantUserId'] == null ? undefined : json['participantUserId'],
        'participantRole': json['participantRole'] == null ? undefined : ParticipantRoleFromJSON(json['participantRole']),
        'participantUser': json['participantUser'] == null ? undefined : ParticipantUserFromJSON(json['participantUser']),
    };
}

export function ParticipantUserRoleToJSON(value?: ParticipantUserRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'participantRoleId': value['participantRoleId'],
        'participantUserId': value['participantUserId'],
        'participantRole': ParticipantRoleToJSON(value['participantRole']),
        'participantUser': ParticipantUserToJSON(value['participantUser']),
    };
}

