/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClaimDto } from './ClaimDto';
import {
    ClaimDtoFromJSON,
    ClaimDtoFromJSONTyped,
    ClaimDtoToJSON,
} from './ClaimDto';

/**
 * 
 * @export
 * @interface UserWithClaimsDto
 */
export interface UserWithClaimsDto {
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    userName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithClaimsDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserWithClaimsDto
     */
    isLocked?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserWithClaimsDto
     */
    lockoutEnd?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWithClaimsDto
     */
    middleName?: string;
    /**
     * 
     * @type {Array<ClaimDto>}
     * @memberof UserWithClaimsDto
     */
    claims?: Array<ClaimDto>;
}

/**
 * Check if a given object implements the UserWithClaimsDto interface.
 */
export function instanceOfUserWithClaimsDto(value: object): boolean {
    return true;
}

export function UserWithClaimsDtoFromJSON(json: any): UserWithClaimsDto {
    return UserWithClaimsDtoFromJSONTyped(json, false);
}

export function UserWithClaimsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWithClaimsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'isEnabled': json['isEnabled'] == null ? undefined : json['isEnabled'],
        'isLocked': json['isLocked'] == null ? undefined : json['isLocked'],
        'lockoutEnd': json['lockoutEnd'] == null ? undefined : (new Date(json['lockoutEnd'])),
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'claims': json['claims'] == null ? undefined : ((json['claims'] as Array<any>).map(ClaimDtoFromJSON)),
    };
}

export function UserWithClaimsDtoToJSON(value?: UserWithClaimsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userName': value['userName'],
        'isEnabled': value['isEnabled'],
        'isLocked': value['isLocked'],
        'lockoutEnd': value['lockoutEnd'] == null ? undefined : ((value['lockoutEnd'] as any).toISOString()),
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'claims': value['claims'] == null ? undefined : ((value['claims'] as Array<any>).map(ClaimDtoToJSON)),
    };
}

