import {AnyAction, PayloadAction} from "@reduxjs/toolkit";
import {call, CallEffect, put, PutEffect, takeEvery} from "redux-saga/effects";
import {ApplicationUser} from "../../../../model-types/modelTypes";
import {setCurrentUser, setUsersActionStatus} from "../../usersSlice";
import {GET_USER_BY_ID} from "./../../actions/index";
import {usersApi} from "./../../api/index";

function* getUserWorker(action: PayloadAction<string>): Generator<
  // step types
  CallEffect<ApplicationUser> | PutEffect<AnyAction>,
  // return type
  void,
  // intermediate argument
  ApplicationUser
> {
  yield put(setUsersActionStatus({name: "getUserById", status: "pending"}));
  try {
    const user = yield call(usersApi.getById, action.payload);
    yield put(setCurrentUser(user));
    yield put(setUsersActionStatus({name: "getUserById", status: "fulfilled"}));
  } catch (e) {
    yield put(setUsersActionStatus({name: "getUserById", status: "rejected"}));
  }
}

function* getUserByIdWatcher() {
  yield takeEvery(GET_USER_BY_ID, getUserWorker);
}

export default getUserByIdWatcher;
