/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddClientAllClaimDto
 */
export interface AddClientAllClaimDto {
    /**
     * Тип клейма
     * @type {string}
     * @memberof AddClientAllClaimDto
     */
    clientId: string;
    /**
     * Тип клейма
     * @type {string}
     * @memberof AddClientAllClaimDto
     */
    type: string;
    /**
     * Значение клейма
     * @type {string}
     * @memberof AddClientAllClaimDto
     */
    value: string;
    /**
     * Начало действия клейма
     * @type {string}
     * @memberof AddClientAllClaimDto
     */
    validFrom?: string;
    /**
     * Окончание действия клейма
     * @type {string}
     * @memberof AddClientAllClaimDto
     */
    validTo?: string;
}

/**
 * Check if a given object implements the AddClientAllClaimDto interface.
 */
export function instanceOfAddClientAllClaimDto(value: object): boolean {
    if (!('clientId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function AddClientAllClaimDtoFromJSON(json: any): AddClientAllClaimDto {
    return AddClientAllClaimDtoFromJSONTyped(json, false);
}

export function AddClientAllClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddClientAllClaimDto {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'type': json['type'],
        'value': json['value'],
        'validFrom': json['validFrom'] == null ? undefined : json['validFrom'],
        'validTo': json['validTo'] == null ? undefined : json['validTo'],
    };
}

export function AddClientAllClaimDtoToJSON(value?: AddClientAllClaimDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'type': value['type'],
        'value': value['value'],
        'validFrom': value['validFrom'],
        'validTo': value['validTo'],
    };
}

