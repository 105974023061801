/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponseWithMessage,
  ClaimDto,
  ClientAllClaimReadDto,
  DataSourceLoadOptions,
  GroupReadDto,
  LoadResult,
  UserClaimAddDto,
  UserClaimReadDto,
  UserCreateDto,
  UserReadDto,
  UserUpdateDto,
} from '../models/index';
import {
    ApiErrorResponseWithMessageFromJSON,
    ApiErrorResponseWithMessageToJSON,
    ClaimDtoFromJSON,
    ClaimDtoToJSON,
    ClientAllClaimReadDtoFromJSON,
    ClientAllClaimReadDtoToJSON,
    DataSourceLoadOptionsFromJSON,
    DataSourceLoadOptionsToJSON,
    GroupReadDtoFromJSON,
    GroupReadDtoToJSON,
    LoadResultFromJSON,
    LoadResultToJSON,
    UserClaimAddDtoFromJSON,
    UserClaimAddDtoToJSON,
    UserClaimReadDtoFromJSON,
    UserClaimReadDtoToJSON,
    UserCreateDtoFromJSON,
    UserCreateDtoToJSON,
    UserReadDtoFromJSON,
    UserReadDtoToJSON,
    UserUpdateDtoFromJSON,
    UserUpdateDtoToJSON,
} from '../models/index';

export interface UiApiUsersCreatePostRequest {
    userCreateDto: UserCreateDto;
    clientId?: string;
}

export interface UiApiUsersGetAllUsersClaimsPostRequest {
    clientId?: string;
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface UiApiUsersGetByClaimsPostRequest {
    clientId: string;
    claimDto: Array<ClaimDto>;
    includeRoleClaims?: boolean;
    includeParticipantClaims?: boolean;
}

export interface UiApiUsersGetUserParticipantsGetRequest {
    userId: string;
    clientId?: string;
}

export interface UiApiUsersGetUsersByClientDXPostRequest {
    client?: string;
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface UiApiUsersIdClaimsDeleteRequest {
    id: string;
    clientAllClaimReadDto: Array<ClientAllClaimReadDto>;
}

export interface UiApiUsersIdClaimsGetRequest {
    id: string;
    clientId?: string;
}

export interface UiApiUsersIdClaimsPostRequest {
    id: string;
    userClaimAddDto: Array<UserClaimAddDto>;
}

export interface UiApiUsersIdGetRequest {
    id: string;
}

export interface UiApiUsersIdGroupsDeleteRequest {
    id: string;
    requestBody: Array<string>;
}

export interface UiApiUsersIdGroupsGetRequest {
    id: string;
    clientId?: string;
}

export interface UiApiUsersIdGroupsPostRequest {
    id: string;
    requestBody: Array<string>;
}

export interface UiApiUsersIdPostRequest {
    id: string;
    userUpdateDto: UserUpdateDto;
}

export interface UiApiUsersPostRequest {
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

/**
 * 
 */
export class UiApiUsersApi extends runtime.BaseAPI {

    /**
     * Создает нового пользователя
     */
    async uiApiUsersCreatePostRaw(requestParameters: UiApiUsersCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userCreateDto'] == null) {
            throw new runtime.RequiredError(
                'userCreateDto',
                'Required parameter "userCreateDto" was null or undefined when calling uiApiUsersCreatePost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateDtoToJSON(requestParameters['userCreateDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Создает нового пользователя
     */
    async uiApiUsersCreatePost(requestParameters: UiApiUsersCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiUsersCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiUsersCurrentGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserReadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserReadDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiApiUsersCurrentGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserReadDto> {
        const response = await this.uiApiUsersCurrentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Возвращает список всех пользователей
     */
    async uiApiUsersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserReadDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserReadDtoFromJSON));
    }

    /**
     * Возвращает список всех пользователей
     */
    async uiApiUsersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserReadDto>> {
        const response = await this.uiApiUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Метод получения всех клеймов пользователей из представления AllUserClaims
     */
    async uiApiUsersGetAllUsersClaimsPostRaw(requestParameters: UiApiUsersGetAllUsersClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/GetAllUsersClaims`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Метод получения всех клеймов пользователей из представления AllUserClaims
     */
    async uiApiUsersGetAllUsersClaimsPost(requestParameters: UiApiUsersGetAllUsersClaimsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiUsersGetAllUsersClaimsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение пользователей по клеймам
     */
    async uiApiUsersGetByClaimsPostRaw(requestParameters: UiApiUsersGetByClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserReadDto>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling uiApiUsersGetByClaimsPost().'
            );
        }

        if (requestParameters['claimDto'] == null) {
            throw new runtime.RequiredError(
                'claimDto',
                'Required parameter "claimDto" was null or undefined when calling uiApiUsersGetByClaimsPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        if (requestParameters['includeRoleClaims'] != null) {
            queryParameters['includeRoleClaims'] = requestParameters['includeRoleClaims'];
        }

        if (requestParameters['includeParticipantClaims'] != null) {
            queryParameters['includeParticipantClaims'] = requestParameters['includeParticipantClaims'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/get-by-claims`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['claimDto']!.map(ClaimDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserReadDtoFromJSON));
    }

    /**
     * Получение пользователей по клеймам
     */
    async uiApiUsersGetByClaimsPost(requestParameters: UiApiUsersGetByClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserReadDto>> {
        const response = await this.uiApiUsersGetByClaimsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Если clientId пуст - то вернутся все участники пользователя во всех модулях
     * Получение участников, в которых состоит пользователь
     */
    async uiApiUsersGetUserParticipantsGetRaw(requestParameters: UiApiUsersGetUserParticipantsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiUsersGetUserParticipantsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['UserId'] = requestParameters['userId'];
        }

        if (requestParameters['clientId'] != null) {
            queryParameters['ClientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/GetUserParticipants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Если clientId пуст - то вернутся все участники пользователя во всех модулях
     * Получение участников, в которых состоит пользователь
     */
    async uiApiUsersGetUserParticipantsGet(requestParameters: UiApiUsersGetUserParticipantsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.uiApiUsersGetUserParticipantsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Список пользователей по клиенту
     */
    async uiApiUsersGetUsersByClientDXPostRaw(requestParameters: UiApiUsersGetUsersByClientDXPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        if (requestParameters['client'] != null) {
            queryParameters['client'] = requestParameters['client'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/GetUsersByClientDX`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Список пользователей по клиенту
     */
    async uiApiUsersGetUsersByClientDXPost(requestParameters: UiApiUsersGetUsersByClientDXPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiUsersGetUsersByClientDXPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Если **хоть один** клейм из списка не будет найден - ничего не удалится
     * Удаление списка клеймов пользователя
     */
    async uiApiUsersIdClaimsDeleteRaw(requestParameters: UiApiUsersIdClaimsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdClaimsDelete().'
            );
        }

        if (requestParameters['clientAllClaimReadDto'] == null) {
            throw new runtime.RequiredError(
                'clientAllClaimReadDto',
                'Required parameter "clientAllClaimReadDto" was null or undefined when calling uiApiUsersIdClaimsDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['clientAllClaimReadDto']!.map(ClientAllClaimReadDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Если **хоть один** клейм из списка не будет найден - ничего не удалится
     * Удаление списка клеймов пользователя
     */
    async uiApiUsersIdClaimsDelete(requestParameters: UiApiUsersIdClaimsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiUsersIdClaimsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Возвращает список клеймов пользователя
     */
    async uiApiUsersIdClaimsGetRaw(requestParameters: UiApiUsersIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserClaimReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdClaimsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserClaimReadDtoFromJSON));
    }

    /**
     * Возвращает список клеймов пользователя
     */
    async uiApiUsersIdClaimsGet(requestParameters: UiApiUsersIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserClaimReadDto>> {
        const response = await this.uiApiUsersIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавляет клеймы пользователя
     */
    async uiApiUsersIdClaimsPostRaw(requestParameters: UiApiUsersIdClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdClaimsPost().'
            );
        }

        if (requestParameters['userClaimAddDto'] == null) {
            throw new runtime.RequiredError(
                'userClaimAddDto',
                'Required parameter "userClaimAddDto" was null or undefined when calling uiApiUsersIdClaimsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['userClaimAddDto']!.map(UserClaimAddDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавляет клеймы пользователя
     */
    async uiApiUsersIdClaimsPost(requestParameters: UiApiUsersIdClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiUsersIdClaimsPostRaw(requestParameters, initOverrides);
    }

    /**
     * Возвращает пользователя по ИД
     */
    async uiApiUsersIdGetRaw(requestParameters: UiApiUsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserReadDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserReadDtoFromJSON(jsonValue));
    }

    /**
     * Возвращает пользователя по ИД
     */
    async uiApiUsersIdGet(requestParameters: UiApiUsersIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserReadDto> {
        const response = await this.uiApiUsersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Убирает пользователя из группы
     */
    async uiApiUsersIdGroupsDeleteRaw(requestParameters: UiApiUsersIdGroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdGroupsDelete().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiUsersIdGroupsDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/groups`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Убирает пользователя из группы
     */
    async uiApiUsersIdGroupsDelete(requestParameters: UiApiUsersIdGroupsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiUsersIdGroupsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Возвращает список групп пользователя
     */
    async uiApiUsersIdGroupsGetRaw(requestParameters: UiApiUsersIdGroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdGroupsGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/groups`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupReadDtoFromJSON));
    }

    /**
     * Возвращает список групп пользователя
     */
    async uiApiUsersIdGroupsGet(requestParameters: UiApiUsersIdGroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupReadDto>> {
        const response = await this.uiApiUsersIdGroupsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавляет пользователя в группу(ы)
     */
    async uiApiUsersIdGroupsPostRaw(requestParameters: UiApiUsersIdGroupsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdGroupsPost().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiUsersIdGroupsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}/groups`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавляет пользователя в группу(ы)
     */
    async uiApiUsersIdGroupsPost(requestParameters: UiApiUsersIdGroupsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiUsersIdGroupsPostRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление данных пользователя
     */
    async uiApiUsersIdPostRaw(requestParameters: UiApiUsersIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiUsersIdPost().'
            );
        }

        if (requestParameters['userUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'userUpdateDto',
                'Required parameter "userUpdateDto" was null or undefined when calling uiApiUsersIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateDtoToJSON(requestParameters['userUpdateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление данных пользователя
     */
    async uiApiUsersIdPost(requestParameters: UiApiUsersIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiUsersIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Поля объекта \"пользователь\":  ```  {    id: string;    userName: string;    firstName: string;    lastName: string;    middleName: string;    email: string;    phoneNumber: string;  }  ```
     * Список пользователей
     */
    async uiApiUsersPostRaw(requestParameters: UiApiUsersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/ui-api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Поля объекта \"пользователь\":  ```  {    id: string;    userName: string;    firstName: string;    lastName: string;    middleName: string;    email: string;    phoneNumber: string;  }  ```
     * Список пользователей
     */
    async uiApiUsersPost(requestParameters: UiApiUsersPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiUsersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
