import {message, ConfigProvider} from "@p4ma/p4md-react";
import ruRU from "antd/lib/locale/ru_RU";
import {loadMessages, locale} from "devextreme/localization";
import ruMessages from "devextreme/localization/messages/ru.json";
import enMessages from "devextreme/localization/messages/en.json";
import kkMessages from "./locales/kk/devextreme.json";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {Route, HashRouter as Router, Switch} from "react-router-dom";
import {
  AuthClaimReadDto,
  Configuration,
  ModulesApi,
  UiApiAuthApi,
  UiApiClientsApi,
  UiApiGroupsApi,
  UiApiParticipantsApi,
  UiApiParticipantsUsersApi,
  UiApiUsersApi,
  RestoreAccessApi
} from "./api_client";
import "./App.css";
import {AUTH_COOKIE, BasePathUrl} from "./appsettings";
import DefaultLayout from "./components/default-layout/DefaultLayout";
import Loader from "./components/loader/Loader";
import NotAuthRoute from "./components/not-auth-route/not-auth-route";
import PrivateRoute from "./components/private-route/private-route";
import {authStore, Login, RestorePassword} from "./features/auth";
import {routes} from "./pages/pages";
import ApiContext from "./utils/ApiContext";
import {getCookie} from "./utils/cookie";
import ModulesList from "./pages/modules-list/ModulesList";
import UISettingsContext, {UISettingsInterface} from "./utils/UISettingsContext";
import {useTranslation} from "react-i18next";
import antRuRu from "antd/es/locale/ru_RU";
import antEnUS from "antd/es/locale/en_US";
import antKkKZ from "antd/es/locale/kk_KZ";
import configureSentry from "./ConfigureSentry";
import {ErrorBoundary, withProfiler} from "@sentry/react";


configureSentry({
  dsn: "https://09442192e79aa23c561c6f480c1ffae2@sentry.data-platform.ru/42",
  //TODO: наверное нужно получать конфигурацию с бека для тегов
  initialScope: {
    tags: {
      "app.type": "module",
      "app.alias": "auth",
      "application": "P4m.Module.Auth.API"
    }
  }
});

const App = () => {
  const [isInit, setInit] = useState(false);
  const [uisettings, setUisettings] = useState<UISettingsInterface>({name: "", logo: ""});
  const dispatch = useDispatch();
  const {i18n, t} = useTranslation();

  locale(i18n.language);
  loadMessages({
    ru: ruMessages.ru,
    en: enMessages.en,
    kk: kkMessages.kk
  });

  let antLocale = useMemo(() => {
    switch (i18n.language)
    {
      case "en":
        {
          return antEnUS;
        }
      case "kk":
        {
          return antKkKZ;
        }
      case "ru":
      default:
        {
          return antRuRu;
        }
    }
  }, [i18n.language]);

  const getUserAccess = async (): Promise<boolean> => {
    const authCookie = getCookie(AUTH_COOKIE);
    const res = await fetch(`${BasePathUrl}/ui-api/auth/user-permissions`);
    const userClaims: AuthClaimReadDto[] = await res.json();
    dispatch(authStore.actions.setUserClaims(userClaims));
    return userClaims.some(({type, value}) => {
      return type === "access" && value === "accounts";
    });
  };

  const getUserInfo = async () => {
    const res = await fetch(`${BasePathUrl}/ui-api/users/current`);
    const user = await res.json();

    dispatch(authStore.actions.setUser(user));
  };

  const getUISettings = async () => {
    const response = await fetch("uisettings.json", {cache: "no-cache"});
    const result = await response.json();
    return result;
  };

  const initialize = async () => {
    const authCookie = getCookie(AUTH_COOKIE);
    const uisettings = await getUISettings();
    setUisettings(uisettings);

    if (authCookie) {
      getUserAccess()
        .then((isAllowed) => {
          if (isAllowed === true) {
            dispatch(authStore.actions.setIsAuth(true));
          } else {
            message.error(t("messages.userAccessIsNotAllowed"));
          }
        })
        .then(() => getUserInfo())
        .finally(() => {
          setInit(true);
        });
    } else {
      setInit(true);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const config = new Configuration({
    basePath: BasePathUrl
  });

  if (isInit) {
    return (
      <ErrorBoundary>
        <UISettingsContext.Provider value={{...uisettings}}>
        <ConfigProvider locale={antLocale}>
          <ApiContext.Provider
            value={{
              authApi: new UiApiAuthApi(config),
              usersApi: new UiApiUsersApi(config),
              groupsApi: new UiApiGroupsApi(config),
              clientsApi: new UiApiClientsApi(config),
              participantsApi: new UiApiParticipantsApi(config),
              participantsUsersApi: new UiApiParticipantsUsersApi(config),
              modulesApi: new ModulesApi(config),
              restoreAccessApi: new RestoreAccessApi(config)
            }}
          >
            <Router>
                <Switch>
                  <NotAuthRoute path="/login" exact>
                    <Login />
                  </NotAuthRoute>
                  <NotAuthRoute path="/restore" exact>
                    <RestorePassword />
                  </NotAuthRoute>
                  <NotAuthRoute path="/registration" exact>
                    <ModulesList />
                  </NotAuthRoute>
                  {routes.map(({ChildComponent, ...params}, index) => (
                    <PrivateRoute key={String(index) + params.path} {...params}>
                      <DefaultLayout>
                        <ChildComponent />
                      </DefaultLayout>
                    </PrivateRoute>
                  ))}
                </Switch>
            </Router>
          </ApiContext.Provider>
        </ConfigProvider>
        </UISettingsContext.Provider>
      </ErrorBoundary>
    );
  }
  return (
  <ErrorBoundary>
    <Loader />
  </ErrorBoundary>);
};

export default withProfiler(App);
