/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * DTO для создания группы
 * @export
 * @interface GroupCreateDto
 */
export interface GroupCreateDto {
    /**
     * ID модуля
     * @type {string}
     * @memberof GroupCreateDto
     */
    clientId: string;
    /**
     * Имя группы
     * @type {string}
     * @memberof GroupCreateDto
     */
    name?: string;
    /**
     * Описание группы
     * @type {string}
     * @memberof GroupCreateDto
     */
    description?: string;
}

/**
 * Check if a given object implements the GroupCreateDto interface.
 */
export function instanceOfGroupCreateDto(value: object): boolean {
    if (!('clientId' in value)) return false;
    return true;
}

export function GroupCreateDtoFromJSON(json: any): GroupCreateDto {
    return GroupCreateDtoFromJSONTyped(json, false);
}

export function GroupCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function GroupCreateDtoToJSON(value?: GroupCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'name': value['name'],
        'description': value['description'],
    };
}

