/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantReadDto } from './ParticipantReadDto';
import {
    ParticipantReadDtoFromJSON,
    ParticipantReadDtoFromJSONTyped,
    ParticipantReadDtoToJSON,
} from './ParticipantReadDto';

/**
 * DTO для отправки данных о пользователе участника в UI
 * @export
 * @interface ParticipantUserReadDto
 */
export interface ParticipantUserReadDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserReadDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantUserReadDto
     */
    participantId?: number;
    /**
     * 
     * @type {ParticipantReadDto}
     * @memberof ParticipantUserReadDto
     */
    participant?: ParticipantReadDto;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantUserReadDto
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserReadDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantUserReadDto
     */
    isEnabled?: boolean;
}

/**
 * Check if a given object implements the ParticipantUserReadDto interface.
 */
export function instanceOfParticipantUserReadDto(value: object): boolean {
    return true;
}

export function ParticipantUserReadDtoFromJSON(json: any): ParticipantUserReadDto {
    return ParticipantUserReadDtoFromJSONTyped(json, false);
}

export function ParticipantUserReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUserReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantId': json['participantId'] == null ? undefined : json['participantId'],
        'participant': json['participant'] == null ? undefined : ParticipantReadDtoFromJSON(json['participant']),
        'isAdmin': json['isAdmin'] == null ? undefined : json['isAdmin'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'isEnabled': json['isEnabled'] == null ? undefined : json['isEnabled'],
    };
}

export function ParticipantUserReadDtoToJSON(value?: ParticipantUserReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantId': value['participantId'],
        'participant': ParticipantReadDtoToJSON(value['participant']),
        'isAdmin': value['isAdmin'],
        'userId': value['userId'],
        'userName': value['userName'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'isEnabled': value['isEnabled'],
    };
}

