import {Button, Form, Input} from "@p4ma/p4md-react";
import React, {useEffect, useState, useCallback, useMemo, useRef} from "react";
import {IAuthError} from "../types";
import {useHistory} from "react-router-dom";
import s from "../styles/loginForm.module.css";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {useApi} from "../../../hooks/useApi";
import {useAuthorization} from "../api/useAuthorization";
import {useTranslation} from "react-i18next";

interface IFormData {
  email: string;
  code: string;
}

const ChangePasswordForm = () => {
  const history = useHistory();
  const [form] = Form.useForm<{email: string; code: string}>();
  const {restoreAccessApi} = useApi();
  const {restore} = useAuthorization();
  const newPasswordValue = Form.useWatch("newPassword", form);
  const confirmPasswordValue = Form.useWatch("confirmPassword", form);
  const [loading, setLoading] = useState(false);
  const disabledUpdatePassword = useMemo(() => {
    return !(newPasswordValue && newPasswordValue.length > 6
          && confirmPasswordValue && confirmPasswordValue == newPasswordValue);
  }, [newPasswordValue, confirmPasswordValue]);
  const {t} = useTranslation();


  const changePassword = async (password: string) => {
    try {
      setLoading(true);
      await restoreAccessApi.uiApiRestoreAccessResetPasswordPost({
        resetPasswordDto: {
          userName: restore?.login || "",
          token: restore?.token || "",
          newPassword: password
        }
      });
      history.push("/login");
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const onFinish = (values: IFormData) => {
    changePassword(newPasswordValue);
  };

  const visibilityIconRender = (visible: boolean) => {
    return visible ? <EyeInvisibleOutlined /> : <EyeOutlined />;
  };


  return (
    <Form form={form} layout="vertical" name="changePassword" onFinish={onFinish} className={s.form} requiredMark={"optional"}>
      <Form.Item
        className={`${s.form__item} ${s.margin__more}`}
        name="newPassword"
        rules={[
          {
            required: true,
            message: t("rules.passwordRequiredValidation", {ns: "loginPage"})
          }
        ]}
        validateStatus={newPasswordValue && newPasswordValue.length < 6 ? "error" : undefined}
        help={
            newPasswordValue && newPasswordValue.length < 6
              ? t("rules.passwordRequiredValidation", {ns: "loginPage"})
              : undefined
        }
        label={newPasswordValue && t("restorePasswordForm.newPassword", {ns: "loginPage"})}
      >
        <Input.Password
          iconRender={visibilityIconRender}
          placeholder={t("restorePasswordForm.newPassword", {ns: "loginPage"})}
          className={`${s.form__input}`}
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item
        className={`${s.form__item} ${s.margin__more}`}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: t("restorePasswordForm.newPassword", {ns: "loginPage"})
          }]}
        validateStatus={
          confirmPasswordValue && newPasswordValue !== confirmPasswordValue ? "error" : undefined
        }
        help={
          confirmPasswordValue && newPasswordValue !== confirmPasswordValue
            ?  t("rules.passwordConfirmationNotEqualToPassword", {ns: "usersModule"})
            : undefined
        }
        label={confirmPasswordValue && t("restorePasswordForm.confirmNewPassword", {ns: "loginPage"})}
      >
        <Input.Password
          iconRender={visibilityIconRender}
          placeholder={t("restorePasswordForm.confirmNewPassword", {ns: "loginPage"})}
          className={`${s.form__input}`}
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item className={`${s.form__item} ${s.margin__less}`}>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          disabled={disabledUpdatePassword}
          className={s.form__button}
        >
          {t("continue")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
