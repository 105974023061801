import {usersApi} from "./../../api/index";
import {GET_USERS} from "./../../actions/index";
import {call, put, takeEvery, CallEffect, PutEffect} from "redux-saga/effects";
import {ApplicationUser} from "../../../../model-types/modelTypes";
import {AnyAction} from "redux";
import {setUsers, setUsersActionStatus} from "../../usersSlice";

function* getUsersWorker(): Generator<
  CallEffect<ApplicationUser[]> | PutEffect<AnyAction>,
  any,
  ApplicationUser[]
  > {
  yield put(setUsersActionStatus({name: "getUsers", status: "pending"}));
  try {
    const users = yield call(usersApi.getAll);
    yield put(setUsers(users));
    yield put(setUsersActionStatus({name: "getUsers", status: "fulfilled"}));
  } catch (error: any) {
    yield put(setUsersActionStatus({name: "getUsers", status: "rejected"}));
  }
}

function* getUsersWatcher() {
  yield takeEvery(GET_USERS, getUsersWorker);
}

export default getUsersWatcher;
