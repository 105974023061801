import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  ClientAllClaimReadDto,
  Participant,
  ParticipantUserReadDto,
  ParticipantUserUpdateDto
} from "../../../api_client";

/* #region  State declaration */
interface ILoadingState {
  getInfo: boolean;
  updateInfo: boolean;
  getUsers: boolean;
  removeUsers: boolean;
  addUsers: boolean;
  updateUsers: boolean;
  claims: boolean;
}
export type ParticipantLoadingTypes = keyof ILoadingState;

interface IParticipantState {
  data: {
    info?: Participant;
    users?: ParticipantUserReadDto[];
    claims?: ClientAllClaimReadDto[];
  };
  error: {
    info: any;
    users: any;
    claims: any;
  };
  loading: ILoadingState;
  editMode: boolean;
}

const initialState: IParticipantState = {
  loading: {
    getInfo: false,
    updateInfo: false,
    getUsers: false,
    removeUsers: false,
    addUsers: false,
    updateUsers: false,
    claims: false
  },
  error: {
    info: false,
    users: false,
    claims: false
  },
  data: {},
  editMode: false
};
/* #endregion */

/* #region  Action Payload Types */
interface SetLoadingPayload {
  type: keyof ILoadingState;
  isLoading: boolean;
}
interface SetErrorPayload {
  type: "info" | "users" | "claims";
  error: any;
}
/* #endregion */

const slice = createSlice({
  name: "participant",
  initialState: initialState,
  reducers: {
    setLoading(state, action: PayloadAction<SetLoadingPayload>) {
      const {type, isLoading} = action.payload;
      state.loading[type] = isLoading;
    },
    setEditMode(state, action: PayloadAction<boolean>) {
      state.editMode = action.payload;
    },
    setError(state, action: PayloadAction<SetErrorPayload>) {
      const {type, error} = action.payload;
      state.error[type] = error;
    },
    clearStore(state) {
      state = initialState;
    },
    setInfo(state, action: PayloadAction<Participant | undefined>) {
      state.data.info = action.payload;
    },
    setClaims(state, action: PayloadAction<ClientAllClaimReadDto[]>) {
      state.data.claims = action.payload;
    },
    setUsers(state, action: PayloadAction<ParticipantUserReadDto[]>) {
      state.data.users = action.payload;
    },
    removeUsers(state, action: PayloadAction<string[]>) {
      const ids = action.payload;
      state.data.users = state.data.users?.filter((u) => !ids.some((id) => id === u.userId));
    },
    addUsers(state, action: PayloadAction<ParticipantUserReadDto[]>) {
      state.data.users?.push(...action.payload);
    },
    updateUsers(state, action: PayloadAction<ParticipantUserUpdateDto[]>) {
      if (state.data.users) {
        const usersToUpdate = action.payload;
        state.data.users = [
          ...state.data.users.filter(
            (user) => !usersToUpdate.some((u) => u.userId === user.userId)
          ),
          ...usersToUpdate
        ];
      }
    }
  }
});

export const {reducer: participantReducer, ...participantStore} = slice;
