import {spawn} from "redux-saga/effects";
import createGroupWatcher from "./watchers/create";
import deleteGroupWatcher from "./watchers/delete";
import getGroupsWatcher from "./watchers/getAll";


function* groupSaga() {
  yield spawn(getGroupsWatcher);
  yield spawn(createGroupWatcher);
  yield spawn(deleteGroupWatcher);
}

export default groupSaga;
