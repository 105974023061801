/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationUser } from './ApplicationUser';
import {
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ApplicationUserToken
 */
export interface ApplicationUserToken {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserToken
     */
    loginProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserToken
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserToken
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserToken
     */
    userId: string;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUserToken
     */
    user?: ApplicationUser;
}

/**
 * Check if a given object implements the ApplicationUserToken interface.
 */
export function instanceOfApplicationUserToken(value: object): boolean {
    if (!('userId' in value)) return false;
    return true;
}

export function ApplicationUserTokenFromJSON(json: any): ApplicationUserToken {
    return ApplicationUserTokenFromJSONTyped(json, false);
}

export function ApplicationUserTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUserToken {
    if (json == null) {
        return json;
    }
    return {
        
        'loginProvider': json['loginProvider'] == null ? undefined : json['loginProvider'],
        'name': json['name'] == null ? undefined : json['name'],
        'value': json['value'] == null ? undefined : json['value'],
        'userId': json['userId'],
        'user': json['user'] == null ? undefined : ApplicationUserFromJSON(json['user']),
    };
}

export function ApplicationUserTokenToJSON(value?: ApplicationUserToken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'loginProvider': value['loginProvider'],
        'name': value['name'],
        'value': value['value'],
        'userId': value['userId'],
        'user': ApplicationUserToJSON(value['user']),
    };
}

