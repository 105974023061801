import {spawn} from "redux-saga/effects";
import getUserByIdWatcher from "./watchers/getById";
import getUsersWatcher from "./watchers/getUsers";
import updateUserWatcher from "./watchers/update";

function* usersSaga() {
  yield spawn(getUsersWatcher);
  yield spawn(getUserByIdWatcher);
  yield spawn(updateUserWatcher);
}

export default usersSaga;
