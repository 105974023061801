/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClaimValuesTree,
  ClientAllClaimReadDto,
  ClientClaimReadDto,
  ClientReadDto,
  DataSourceLoadOptions,
} from '../models/index';
import {
    ClaimValuesTreeFromJSON,
    ClaimValuesTreeToJSON,
    ClientAllClaimReadDtoFromJSON,
    ClientAllClaimReadDtoToJSON,
    ClientClaimReadDtoFromJSON,
    ClientClaimReadDtoToJSON,
    ClientReadDtoFromJSON,
    ClientReadDtoToJSON,
    DataSourceLoadOptionsFromJSON,
    DataSourceLoadOptionsToJSON,
} from '../models/index';

export interface UiApiClientsGetAllClaimsIdClientGetRequest {
    idClient: string;
}

export interface UiApiClientsGetClaimsIdGetRequest {
    id: string;
}

export interface UiApiClientsGetClaimsTypesGetRequest {
    extension: string;
    idClient: string;
}

export interface UiApiClientsGetClaimsValuesDxPostRequest {
    typeClaim: string;
    idClient: string;
    dataSourceLoadOptions: DataSourceLoadOptions;
}

export interface UiApiClientsGetClaimsValuesGetRequest {
    typeClaim: string;
    idClient: string;
}

/**
 * 
 */
export class UiApiClientsApi extends runtime.BaseAPI {

    /**
     * Получение списка клиентов
     */
    async uiApiClientsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientReadDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/clients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientReadDtoFromJSON));
    }

    /**
     * Получение списка клиентов
     */
    async uiApiClientsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientReadDto>> {
        const response = await this.uiApiClientsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Получение списка всех клаймов клиента
     */
    async uiApiClientsGetAllClaimsIdClientGetRaw(requestParameters: UiApiClientsGetAllClaimsIdClientGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientAllClaimReadDto>>> {
        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling uiApiClientsGetAllClaimsIdClientGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/clients/get-all-claims/{idClient}`.replace(`{${"idClient"}}`, encodeURIComponent(String(requestParameters['idClient']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientAllClaimReadDtoFromJSON));
    }

    /**
     * Получение списка всех клаймов клиента
     */
    async uiApiClientsGetAllClaimsIdClientGet(requestParameters: UiApiClientsGetAllClaimsIdClientGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientAllClaimReadDto>> {
        const response = await this.uiApiClientsGetAllClaimsIdClientGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка клайм клиента
     */
    async uiApiClientsGetClaimsIdGetRaw(requestParameters: UiApiClientsGetClaimsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientClaimReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiClientsGetClaimsIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/clients/get-claims/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientClaimReadDtoFromJSON));
    }

    /**
     * Получение списка клайм клиента
     */
    async uiApiClientsGetClaimsIdGet(requestParameters: UiApiClientsGetClaimsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientClaimReadDto>> {
        const response = await this.uiApiClientsGetClaimsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение типов клайм из другого расширения
     */
    async uiApiClientsGetClaimsTypesGetRaw(requestParameters: UiApiClientsGetClaimsTypesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['extension'] == null) {
            throw new runtime.RequiredError(
                'extension',
                'Required parameter "extension" was null or undefined when calling uiApiClientsGetClaimsTypesGet().'
            );
        }

        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling uiApiClientsGetClaimsTypesGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['extension'] != null) {
            queryParameters['extension'] = requestParameters['extension'];
        }

        if (requestParameters['idClient'] != null) {
            queryParameters['idClient'] = requestParameters['idClient'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/clients/get-claims-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Получение типов клайм из другого расширения
     */
    async uiApiClientsGetClaimsTypesGet(requestParameters: UiApiClientsGetClaimsTypesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiClientsGetClaimsTypesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Поля объекта \"ClientAllClaimValues\":  ```  {    Value: string;    Description: string;    Children: ClientAllClaimValues[];  }  ```
     * Получение значений клайм из другого расширения (DX)
     */
    async uiApiClientsGetClaimsValuesDxPostRaw(requestParameters: UiApiClientsGetClaimsValuesDxPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['typeClaim'] == null) {
            throw new runtime.RequiredError(
                'typeClaim',
                'Required parameter "typeClaim" was null or undefined when calling uiApiClientsGetClaimsValuesDxPost().'
            );
        }

        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling uiApiClientsGetClaimsValuesDxPost().'
            );
        }

        if (requestParameters['dataSourceLoadOptions'] == null) {
            throw new runtime.RequiredError(
                'dataSourceLoadOptions',
                'Required parameter "dataSourceLoadOptions" was null or undefined when calling uiApiClientsGetClaimsValuesDxPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['typeClaim'] != null) {
            queryParameters['typeClaim'] = requestParameters['typeClaim'];
        }

        if (requestParameters['idClient'] != null) {
            queryParameters['idClient'] = requestParameters['idClient'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/clients/get-claims-values-dx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Поля объекта \"ClientAllClaimValues\":  ```  {    Value: string;    Description: string;    Children: ClientAllClaimValues[];  }  ```
     * Получение значений клайм из другого расширения (DX)
     */
    async uiApiClientsGetClaimsValuesDxPost(requestParameters: UiApiClientsGetClaimsValuesDxPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiClientsGetClaimsValuesDxPostRaw(requestParameters, initOverrides);
    }

    /**
     * Поля объекта \"ClientAllClaimValues\":  ```  {    Value: string;    Description: string;    Children: ClientAllClaimValues[];  }  ```
     * Получение значений клайм из другого расширения
     */
    async uiApiClientsGetClaimsValuesGetRaw(requestParameters: UiApiClientsGetClaimsValuesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClaimValuesTree>>> {
        if (requestParameters['typeClaim'] == null) {
            throw new runtime.RequiredError(
                'typeClaim',
                'Required parameter "typeClaim" was null or undefined when calling uiApiClientsGetClaimsValuesGet().'
            );
        }

        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling uiApiClientsGetClaimsValuesGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['typeClaim'] != null) {
            queryParameters['typeClaim'] = requestParameters['typeClaim'];
        }

        if (requestParameters['idClient'] != null) {
            queryParameters['idClient'] = requestParameters['idClient'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/clients/get-claims-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaimValuesTreeFromJSON));
    }

    /**
     * Поля объекта \"ClientAllClaimValues\":  ```  {    Value: string;    Description: string;    Children: ClientAllClaimValues[];  }  ```
     * Получение значений клайм из другого расширения
     */
    async uiApiClientsGetClaimsValuesGet(requestParameters: UiApiClientsGetClaimsValuesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClaimValuesTree>> {
        const response = await this.uiApiClientsGetClaimsValuesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
