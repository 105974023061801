/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExtensionDto,
  ModuleWithExtensionsDto,
} from '../models/index';
import {
    ExtensionDtoFromJSON,
    ExtensionDtoToJSON,
    ModuleWithExtensionsDtoFromJSON,
    ModuleWithExtensionsDtoToJSON,
} from '../models/index';

export interface ApiModulesGetExtensionsGetRequest {
    moduleId?: number;
    moduleAlias?: string;
}

/**
 * 
 */
export class ModulesApi extends runtime.BaseAPI {

    /**
     * Получение расширений
     */
    async apiModulesGetExtensionsGetRaw(requestParameters: ApiModulesGetExtensionsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExtensionDto>>> {
        const queryParameters: any = {};

        if (requestParameters['moduleId'] != null) {
            queryParameters['moduleId'] = requestParameters['moduleId'];
        }

        if (requestParameters['moduleAlias'] != null) {
            queryParameters['moduleAlias'] = requestParameters['moduleAlias'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Modules/GetExtensions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExtensionDtoFromJSON));
    }

    /**
     * Получение расширений
     */
    async apiModulesGetExtensionsGet(requestParameters: ApiModulesGetExtensionsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExtensionDto>> {
        const response = await this.apiModulesGetExtensionsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение модулей
     */
    async apiModulesGetModulesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModuleWithExtensionsDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Modules/GetModules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModuleWithExtensionsDtoFromJSON));
    }

    /**
     * Получение модулей
     */
    async apiModulesGetModulesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModuleWithExtensionsDto>> {
        const response = await this.apiModulesGetModulesGetRaw(initOverrides);
        return await response.value();
    }

}
