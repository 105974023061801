import React from "react";
import {
  UiApiAuthApi,
  UiApiClientsApi,
  UiApiGroupsApi,
  UiApiParticipantsApi,
  UiApiParticipantsUsersApi,
  UiApiUsersApi,
  ModulesApi,
  RestoreAccessApi
} from "../api_client";

interface ApiContext {
  authApi: InstanceType<typeof UiApiAuthApi>;
  usersApi: InstanceType<typeof UiApiUsersApi>;
  groupsApi: InstanceType<typeof UiApiGroupsApi>;
  clientsApi: InstanceType<typeof UiApiClientsApi>;
  participantsApi: InstanceType<typeof UiApiParticipantsApi>;
  participantsUsersApi: InstanceType<typeof UiApiParticipantsUsersApi>;
  modulesApi: InstanceType<typeof ModulesApi>;
  restoreAccessApi: InstanceType<typeof RestoreAccessApi>;
}

const ApiContext = React.createContext<ApiContext>({} as ApiContext);

export default ApiContext;
