import {PayloadAction} from "@reduxjs/toolkit";
import {notification, Spin} from "@p4ma/p4md-react";
import React from "react";
import {call, put, takeEvery} from "redux-saga/effects";
import {UPDATE_USER, usersActions} from "../../actions/index";
import {UpdateUserParams, usersApi} from "../../api/index";
import {setUsersActionStatus} from "../../usersSlice";
import {t} from "i18next";

function* updateUserWorker(action: PayloadAction<UpdateUserParams>) {
  yield put(setUsersActionStatus({name: "updateUser", status: "pending"}));
  notification.open({
    key: "loading",
    message: t("loading"),
    icon: <Spin />
  });
  try {
    yield call(usersApi.update, action.payload);
    yield put(usersActions.getById(action.payload.userId));
    yield put(setUsersActionStatus({name: "updateUser", status: "fulfilled"}));
    notification.close("loading");
    notification.success({
      message: t("messages.userUpdatedSuccessfully", {ns: "usersModule"})
    });
  } catch (error: any) {
    notification.close("loading");
    notification.error({
      message: t("messages.userUpdatedError", {ns: "usersModule"})
    });
    yield put(setUsersActionStatus({name: "updateUser", status: "rejected"}));
  }
}

function* updateUserWatcher() {
  yield takeEvery(UPDATE_USER, updateUserWorker);
}

export default updateUserWatcher;
