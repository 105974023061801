import React, {useContext, useEffect, useState} from "react";
import LocalizationSelect from "../../../components/LocalizationSelect/LocalizationSelect";
import {Trans, useTranslation} from "react-i18next";
import s from "../styles/login.module.css";

export const Footer = () => {
  const {t, i18n} = useTranslation(["loginPage", "static"]);

  return (
    <div className={s.footer}>
      <LocalizationSelect className={s.info__select}/>
      <div className={s.font__gray}>
        <span className={`${s.font__small} ${s.font__blue}`}>{t("troubleLoggingIn")}</span>
        <br/>
        <Trans
              i18nKey="contactTechnicalSupportWithLink"
              ns="common"
              components={{
                "linkToSupport": <a href="https://my-sp.ru"/>
              }}
        />
      </div>
    </div>
  );
};