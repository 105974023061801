/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientAllClaim } from './ClientAllClaim';
import {
    ClientAllClaimFromJSON,
    ClientAllClaimFromJSONTyped,
    ClientAllClaimToJSON,
} from './ClientAllClaim';
import type { ParticipantRole } from './ParticipantRole';
import {
    ParticipantRoleFromJSON,
    ParticipantRoleFromJSONTyped,
    ParticipantRoleToJSON,
} from './ParticipantRole';

/**
 * 
 * @export
 * @interface ParticipantRoleClaim
 */
export interface ParticipantRoleClaim {
    /**
     * 
     * @type {number}
     * @memberof ParticipantRoleClaim
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantRoleClaim
     */
    participantRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRoleClaim
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRoleClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRoleClaim
     */
    claimValue?: string;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantRoleClaim
     */
    validFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantRoleClaim
     */
    validTo?: Date;
    /**
     * 
     * @type {ParticipantRole}
     * @memberof ParticipantRoleClaim
     */
    participantRole?: ParticipantRole;
    /**
     * 
     * @type {ClientAllClaim}
     * @memberof ParticipantRoleClaim
     */
    clientClaim?: ClientAllClaim;
}

/**
 * Check if a given object implements the ParticipantRoleClaim interface.
 */
export function instanceOfParticipantRoleClaim(value: object): boolean {
    return true;
}

export function ParticipantRoleClaimFromJSON(json: any): ParticipantRoleClaim {
    return ParticipantRoleClaimFromJSONTyped(json, false);
}

export function ParticipantRoleClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantRoleClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantRoleId': json['participantRoleId'] == null ? undefined : json['participantRoleId'],
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'claimValue': json['claimValue'] == null ? undefined : json['claimValue'],
        'validFrom': json['validFrom'] == null ? undefined : (new Date(json['validFrom'])),
        'validTo': json['validTo'] == null ? undefined : (new Date(json['validTo'])),
        'participantRole': json['participantRole'] == null ? undefined : ParticipantRoleFromJSON(json['participantRole']),
        'clientClaim': json['clientClaim'] == null ? undefined : ClientAllClaimFromJSON(json['clientClaim']),
    };
}

export function ParticipantRoleClaimToJSON(value?: ParticipantRoleClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantRoleId': value['participantRoleId'],
        'clientId': value['clientId'],
        'claimType': value['claimType'],
        'claimValue': value['claimValue'],
        'validFrom': value['validFrom'] == null ? undefined : ((value['validFrom']).toISOString()),
        'validTo': value['validTo'] == null ? undefined : ((value['validTo']).toISOString()),
        'participantRole': ParticipantRoleToJSON(value['participantRole']),
        'clientClaim': ClientAllClaimToJSON(value['clientClaim']),
    };
}

