import {
  ApplicationUser,
  UpdateUserModel
} from "../../../model-types/modelTypes";
import {api} from "./../../../utils/requestConstructor";

// Get Users
const getUsers = async () => {
  const res = await api.get("ui-api/users");
  if (res.ok) {
    const users: any = await res.json();
    return users;
  } else {
    throw new Error(res.statusText);
  }
};

// Get User By Id
const getUserById = async (id: string): Promise<ApplicationUser> => {
  const res = await api.get(`ui-api/users/${id}`);
  if (res.ok) {
    return await res.json();
  } else {
    return Promise.reject(res.statusText);
  }
};

// Get User Claims
const getUserClaims = async (id: string): Promise<ApplicationUser> => {
  const res = await api.get(`ui-api/users/${id}/claims`);
  if (res.ok) {
    return await res.json();
  } else {
    return Promise.reject(res.statusText);
  }
};

// Update User
export interface UpdateUserParams extends UpdateUserModel {
  userId: string;
  username: string;
  email: string;
  userRoles?: string[];
  userClaims?: {type: string; value: string; clientId: string}[];
}
const updateUser = async (params: UpdateUserParams) => {
  const {
    email,
    userRoles,
    username,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    userId,
    userClaims
  } = params;
  const updateUserParams: any = {
    username,
    email,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    resetPassword: false,
    emailConfirmed: true
  };
  if (userRoles) {
    updateUserParams.userRoles = userRoles;
  }
  if (userClaims) {
    updateUserParams.userClaims = userClaims;
  }

  const updateUserResponse = await api.post(`ui-api/users/${userId}`, {
    body: updateUserParams
  });
  if (updateUserResponse.ok) {
    return "ok";
  } else {
    return Promise.reject(updateUserResponse.statusText);
  }
};

// Create user
export interface CreateUserParams extends UpdateUserModel {
  username: string;
  email: string;
  password: string;
  userRoles?: string[];
  userClaims?: {type: string; value: string; clientId: string}[];
}

export const usersApi = {
  getAll: getUsers,
  getById: getUserById,
  getUserClaims,
  update: updateUser
};
