/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientAllClaimReadDto,
  DataSourceLoadOptions,
  LoadResult,
  Participant,
  ParticipantCreateDto,
  ParticipantReadDto,
  ParticipantUserCreateDto,
  ParticipantUserReadDto,
  ParticipantUserUpdateDto,
} from '../models/index';
import {
    ClientAllClaimReadDtoFromJSON,
    ClientAllClaimReadDtoToJSON,
    DataSourceLoadOptionsFromJSON,
    DataSourceLoadOptionsToJSON,
    LoadResultFromJSON,
    LoadResultToJSON,
    ParticipantFromJSON,
    ParticipantToJSON,
    ParticipantCreateDtoFromJSON,
    ParticipantCreateDtoToJSON,
    ParticipantReadDtoFromJSON,
    ParticipantReadDtoToJSON,
    ParticipantUserCreateDtoFromJSON,
    ParticipantUserCreateDtoToJSON,
    ParticipantUserReadDtoFromJSON,
    ParticipantUserReadDtoToJSON,
    ParticipantUserUpdateDtoFromJSON,
    ParticipantUserUpdateDtoToJSON,
} from '../models/index';

export interface UiApiParticipantsAddPostRequest {
    participantCreateDto?: ParticipantCreateDto;
}

export interface UiApiParticipantsByUserGetRequest {
    userId?: string;
}

export interface UiApiParticipantsDxPostRequest {
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface UiApiParticipantsGetParticipantsByClientDXPostRequest {
    client?: string;
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface UiApiParticipantsIdClaimsAddPostRequest {
    id: number;
    clientAllClaimReadDto: Array<ClientAllClaimReadDto>;
}

export interface UiApiParticipantsIdClaimsGetRequest {
    id: number;
}

export interface UiApiParticipantsIdClaimsRemoveDeleteRequest {
    id: number;
    clientAllClaimReadDto: Array<ClientAllClaimReadDto>;
}

export interface UiApiParticipantsIdDeleteRequest {
    id: number;
}

export interface UiApiParticipantsIdGetRequest {
    id: number;
}

export interface UiApiParticipantsIdPostRequest {
    id: number;
    participantCreateDto?: ParticipantCreateDto;
}

export interface UiApiParticipantsIdUsersAddPostRequest {
    id: number;
    participantUserCreateDto: Array<ParticipantUserCreateDto>;
}

export interface UiApiParticipantsIdUsersGetRequest {
    id: number;
}

export interface UiApiParticipantsIdUsersRemoveDeleteRequest {
    id: number;
    requestBody: Array<string>;
}

export interface UiApiParticipantsIdUsersUpdatePostRequest {
    id: number;
    participantUserUpdateDto: Array<ParticipantUserUpdateDto>;
}

/**
 * 
 */
export class UiApiParticipantsApi extends runtime.BaseAPI {

    /**
     * Создать нового участника
     */
    async uiApiParticipantsAddPostRaw(requestParameters: UiApiParticipantsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantCreateDtoToJSON(requestParameters['participantCreateDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Создать нового участника
     */
    async uiApiParticipantsAddPost(requestParameters: UiApiParticipantsAddPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение участников пользователя
     */
    async uiApiParticipantsByUserGetRaw(requestParameters: UiApiParticipantsByUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantReadDto>>> {
        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants/by-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantReadDtoFromJSON));
    }

    /**
     * Получение участников пользователя
     */
    async uiApiParticipantsByUserGet(requestParameters: UiApiParticipantsByUserGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantReadDto>> {
        const response = await this.uiApiParticipantsByUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Поля объекта \"участник\":  ```  {    id: string;    name: string;    description: string;  }  ```
     * Возвращает список всех участников
     */
    async uiApiParticipantsDxPostRaw(requestParameters: UiApiParticipantsDxPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/dx`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Поля объекта \"участник\":  ```  {    id: string;    name: string;    description: string;  }  ```
     * Возвращает список всех участников
     */
    async uiApiParticipantsDxPost(requestParameters: UiApiParticipantsDxPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiParticipantsDxPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение участников по клиенту
     */
    async uiApiParticipantsGetParticipantsByClientDXPostRaw(requestParameters: UiApiParticipantsGetParticipantsByClientDXPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        if (requestParameters['client'] != null) {
            queryParameters['client'] = requestParameters['client'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/GetParticipantsByClientDX`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Получение участников по клиенту
     */
    async uiApiParticipantsGetParticipantsByClientDXPost(requestParameters: UiApiParticipantsGetParticipantsByClientDXPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiParticipantsGetParticipantsByClientDXPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление клеймов участника
     */
    async uiApiParticipantsIdClaimsAddPostRaw(requestParameters: UiApiParticipantsIdClaimsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdClaimsAddPost().'
            );
        }

        if (requestParameters['clientAllClaimReadDto'] == null) {
            throw new runtime.RequiredError(
                'clientAllClaimReadDto',
                'Required parameter "clientAllClaimReadDto" was null or undefined when calling uiApiParticipantsIdClaimsAddPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}/claims/add`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['clientAllClaimReadDto']!.map(ClientAllClaimReadDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление клеймов участника
     */
    async uiApiParticipantsIdClaimsAddPost(requestParameters: UiApiParticipantsIdClaimsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdClaimsAddPostRaw(requestParameters, initOverrides);
    }

    /**
     * Получение клеймов участника
     */
    async uiApiParticipantsIdClaimsGetRaw(requestParameters: UiApiParticipantsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientAllClaimReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdClaimsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientAllClaimReadDtoFromJSON));
    }

    /**
     * Получение клеймов участника
     */
    async uiApiParticipantsIdClaimsGet(requestParameters: UiApiParticipantsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientAllClaimReadDto>> {
        const response = await this.uiApiParticipantsIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление клеймов участника
     */
    async uiApiParticipantsIdClaimsRemoveDeleteRaw(requestParameters: UiApiParticipantsIdClaimsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdClaimsRemoveDelete().'
            );
        }

        if (requestParameters['clientAllClaimReadDto'] == null) {
            throw new runtime.RequiredError(
                'clientAllClaimReadDto',
                'Required parameter "clientAllClaimReadDto" was null or undefined when calling uiApiParticipantsIdClaimsRemoveDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}/claims/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['clientAllClaimReadDto']!.map(ClientAllClaimReadDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление клеймов участника
     */
    async uiApiParticipantsIdClaimsRemoveDelete(requestParameters: UiApiParticipantsIdClaimsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdClaimsRemoveDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Удаление участника
     */
    async uiApiParticipantsIdDeleteRaw(requestParameters: UiApiParticipantsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление участника
     */
    async uiApiParticipantsIdDelete(requestParameters: UiApiParticipantsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Получить данные участника
     */
    async uiApiParticipantsIdGetRaw(requestParameters: UiApiParticipantsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Participant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantFromJSON(jsonValue));
    }

    /**
     * Получить данные участника
     */
    async uiApiParticipantsIdGet(requestParameters: UiApiParticipantsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Participant> {
        const response = await this.uiApiParticipantsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить данные участника
     */
    async uiApiParticipantsIdPostRaw(requestParameters: UiApiParticipantsIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParticipantCreateDtoToJSON(requestParameters['participantCreateDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновить данные участника
     */
    async uiApiParticipantsIdPost(requestParameters: UiApiParticipantsIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Добавление пользователей участника
     */
    async uiApiParticipantsIdUsersAddPostRaw(requestParameters: UiApiParticipantsIdUsersAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdUsersAddPost().'
            );
        }

        if (requestParameters['participantUserCreateDto'] == null) {
            throw new runtime.RequiredError(
                'participantUserCreateDto',
                'Required parameter "participantUserCreateDto" was null or undefined when calling uiApiParticipantsIdUsersAddPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}/users/add`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['participantUserCreateDto']!.map(ParticipantUserCreateDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление пользователей участника
     */
    async uiApiParticipantsIdUsersAddPost(requestParameters: UiApiParticipantsIdUsersAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdUsersAddPostRaw(requestParameters, initOverrides);
    }

    /**
     * Получение пользователей участника
     */
    async uiApiParticipantsIdUsersGetRaw(requestParameters: UiApiParticipantsIdUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantUserReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdUsersGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantUserReadDtoFromJSON));
    }

    /**
     * Получение пользователей участника
     */
    async uiApiParticipantsIdUsersGet(requestParameters: UiApiParticipantsIdUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantUserReadDto>> {
        const response = await this.uiApiParticipantsIdUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление пользователей из участника
     */
    async uiApiParticipantsIdUsersRemoveDeleteRaw(requestParameters: UiApiParticipantsIdUsersRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdUsersRemoveDelete().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiParticipantsIdUsersRemoveDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}/users/remove`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление пользователей из участника
     */
    async uiApiParticipantsIdUsersRemoveDelete(requestParameters: UiApiParticipantsIdUsersRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdUsersRemoveDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление пользователей участника
     */
    async uiApiParticipantsIdUsersUpdatePostRaw(requestParameters: UiApiParticipantsIdUsersUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiParticipantsIdUsersUpdatePost().'
            );
        }

        if (requestParameters['participantUserUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'participantUserUpdateDto',
                'Required parameter "participantUserUpdateDto" was null or undefined when calling uiApiParticipantsIdUsersUpdatePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants/{id}/users/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['participantUserUpdateDto']!.map(ParticipantUserUpdateDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление пользователей участника
     */
    async uiApiParticipantsIdUsersUpdatePost(requestParameters: UiApiParticipantsIdUsersUpdatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiParticipantsIdUsersUpdatePostRaw(requestParameters, initOverrides);
    }

}
