/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Participant } from './Participant';
import {
    ParticipantFromJSON,
    ParticipantFromJSONTyped,
    ParticipantToJSON,
} from './Participant';
import type { ParticipantRoleClaim } from './ParticipantRoleClaim';
import {
    ParticipantRoleClaimFromJSON,
    ParticipantRoleClaimFromJSONTyped,
    ParticipantRoleClaimToJSON,
} from './ParticipantRoleClaim';
import type { ParticipantUserRole } from './ParticipantUserRole';
import {
    ParticipantUserRoleFromJSON,
    ParticipantUserRoleFromJSONTyped,
    ParticipantUserRoleToJSON,
} from './ParticipantUserRole';

/**
 * 
 * @export
 * @interface ParticipantRole
 */
export interface ParticipantRole {
    /**
     * 
     * @type {number}
     * @memberof ParticipantRole
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ParticipantRole
     */
    participantId?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRole
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRole
     */
    description?: string;
    /**
     * 
     * @type {Participant}
     * @memberof ParticipantRole
     */
    participant?: Participant;
    /**
     * 
     * @type {Array<ParticipantUserRole>}
     * @memberof ParticipantRole
     */
    participantUserRoles?: Array<ParticipantUserRole>;
    /**
     * 
     * @type {Array<ParticipantRoleClaim>}
     * @memberof ParticipantRole
     */
    participantRoleClaims?: Array<ParticipantRoleClaim>;
}

/**
 * Check if a given object implements the ParticipantRole interface.
 */
export function instanceOfParticipantRole(value: object): boolean {
    return true;
}

export function ParticipantRoleFromJSON(json: any): ParticipantRole {
    return ParticipantRoleFromJSONTyped(json, false);
}

export function ParticipantRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'participantId': json['participantId'] == null ? undefined : json['participantId'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'participant': json['participant'] == null ? undefined : ParticipantFromJSON(json['participant']),
        'participantUserRoles': json['participantUserRoles'] == null ? undefined : ((json['participantUserRoles'] as Array<any>).map(ParticipantUserRoleFromJSON)),
        'participantRoleClaims': json['participantRoleClaims'] == null ? undefined : ((json['participantRoleClaims'] as Array<any>).map(ParticipantRoleClaimFromJSON)),
    };
}

export function ParticipantRoleToJSON(value?: ParticipantRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'participantId': value['participantId'],
        'name': value['name'],
        'description': value['description'],
        'participant': ParticipantToJSON(value['participant']),
        'participantUserRoles': value['participantUserRoles'] == null ? undefined : ((value['participantUserRoles'] as Array<any>).map(ParticipantUserRoleToJSON)),
        'participantRoleClaims': value['participantRoleClaims'] == null ? undefined : ((value['participantRoleClaims'] as Array<any>).map(ParticipantRoleClaimToJSON)),
    };
}

