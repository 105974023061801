/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationUser } from './ApplicationUser';
import {
    ApplicationUserFromJSON,
    ApplicationUserFromJSONTyped,
    ApplicationUserToJSON,
} from './ApplicationUser';

/**
 * 
 * @export
 * @interface ApplicationUserClaim
 */
export interface ApplicationUserClaim {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserClaim
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserClaim
     */
    claimType?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserClaim
     */
    claimValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserClaim
     */
    userId: string;
    /**
     * 
     * @type {ApplicationUser}
     * @memberof ApplicationUserClaim
     */
    user?: ApplicationUser;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserClaim
     */
    clientId?: string;
}

/**
 * Check if a given object implements the ApplicationUserClaim interface.
 */
export function instanceOfApplicationUserClaim(value: object): boolean {
    if (!('userId' in value)) return false;
    return true;
}

export function ApplicationUserClaimFromJSON(json: any): ApplicationUserClaim {
    return ApplicationUserClaimFromJSONTyped(json, false);
}

export function ApplicationUserClaimFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUserClaim {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'claimType': json['claimType'] == null ? undefined : json['claimType'],
        'claimValue': json['claimValue'] == null ? undefined : json['claimValue'],
        'userId': json['userId'],
        'user': json['user'] == null ? undefined : ApplicationUserFromJSON(json['user']),
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
    };
}

export function ApplicationUserClaimToJSON(value?: ApplicationUserClaim | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'claimType': value['claimType'],
        'claimValue': value['claimValue'],
        'userId': value['userId'],
        'user': ApplicationUserToJSON(value['user']),
        'clientId': value['clientId'],
    };
}

