import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthClaimReadDto, ClientAllClaimReadDto, UserReadDto} from "../../../api_client";
import {IAuthError} from "../types";

export interface ILoadingState {
  signIn: boolean;
  signOut: boolean;
  getCurrentUser: boolean;
}
interface SetLoadingPayload {
  type: keyof ILoadingState;
  isLoading: boolean;
}

export interface IRestore {
  login: string;
  token: string;
}

interface IAuthState {
  data: {
    isAuth: boolean;
    user?: UserReadDto;
    userClaims?: AuthClaimReadDto[];
  };
  restore?: IRestore;
  error?: IAuthError;
  loading: ILoadingState;
}

const initialState: IAuthState = {
  data: {
    isAuth: false,
    user: {}
  },
  loading: {
    signIn: false,
    signOut: false,
    getCurrentUser: false
  }
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<SetLoadingPayload>) {
      const {type, isLoading} = action.payload;
      state.loading[type] = isLoading;
    },
    setError(state, action: PayloadAction<IAuthError | undefined>) {
      const error = action.payload;
      if (error && error.code === "LOCKED") {
        error.lockoutEnd = new Date(error.lockoutEnd);
      }
      state.error = error;
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.data.isAuth = action.payload;
    },
    setUser(state, action: PayloadAction<UserReadDto>) {
      state.data.user = action.payload;
    },
    setUserClaims(state, action: PayloadAction<AuthClaimReadDto[]>) {
      state.data.userClaims = action.payload;
    },
    setRestore(state, action: PayloadAction<IRestore>) {
      state.restore = action.payload;
    }
  }
});

export const {reducer: authReducer, ...authStore} = slice;
