/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserInformationDto
 */
export interface GetUserInformationDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserInformationDto
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInformationDto
     */
    password?: string;
}

/**
 * Check if a given object implements the GetUserInformationDto interface.
 */
export function instanceOfGetUserInformationDto(value: object): boolean {
    return true;
}

export function GetUserInformationDtoFromJSON(json: any): GetUserInformationDto {
    return GetUserInformationDtoFromJSONTyped(json, false);
}

export function GetUserInformationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserInformationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'login': json['login'] == null ? undefined : json['login'],
        'password': json['password'] == null ? undefined : json['password'],
    };
}

export function GetUserInformationDtoToJSON(value?: GetUserInformationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'login': value['login'],
        'password': value['password'],
    };
}

