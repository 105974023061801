import {Alert, Button} from "@p4ma/p4md-react";
import React from "react";
import {useDispatch} from "react-redux";
import {authStore} from "../data";
import {IUserLockedError} from "../types";
import {Trans, useTranslation} from "react-i18next";

interface IProps {
  error: IUserLockedError;
}

const LockedError: React.FC<IProps> = ({error}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {actions} = authStore;
  const clearError = () => {
    dispatch(actions.setError(undefined));
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center"
      }}
    >
      <Alert
        message={<b>{error.message}</b>}
        description={
          <Trans
          ns="loginPage"
          i18nKey="form.loginRepeatAvailabledAfterTime"
          components={{
            afterTimeWrapper:<b/>
          }}
          values={{
            afterTime: error.lockoutEnd.toLocaleTimeString()
          }}
          />
        }
        type="error"
      />
      <Button onClick={clearError} style={{width: "220px", marginTop: 10}}>
        {t("back")}
      </Button>
    </div>
  );
};

export default LockedError;
