/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddClientAllClaimDto,
  ParticipantRoleReadDto,
  ParticipantUserClaimReadDto,
  ParticipantUserReadDto,
  RemoveClientAllClaimDto,
} from '../models/index';
import {
    AddClientAllClaimDtoFromJSON,
    AddClientAllClaimDtoToJSON,
    ParticipantRoleReadDtoFromJSON,
    ParticipantRoleReadDtoToJSON,
    ParticipantUserClaimReadDtoFromJSON,
    ParticipantUserClaimReadDtoToJSON,
    ParticipantUserReadDtoFromJSON,
    ParticipantUserReadDtoToJSON,
    RemoveClientAllClaimDtoFromJSON,
    RemoveClientAllClaimDtoToJSON,
} from '../models/index';

export interface UiApiParticipantsUsersPUserIdClaimsAvailableGetRequest {
    pUserId: number;
}

export interface UiApiParticipantsUsersPUserIdClaimsGetRequest {
    pUserId: number;
}

export interface UiApiParticipantsUsersUserIdAdminPermissionsPostRequest {
    userId: number;
    body: boolean;
}

export interface UiApiParticipantsUsersUserIdClaimsAddPostRequest {
    userId: number;
    addClientAllClaimDto: Array<AddClientAllClaimDto>;
}

export interface UiApiParticipantsUsersUserIdClaimsRemoveDeleteRequest {
    userId: number;
    removeClientAllClaimDto: Array<RemoveClientAllClaimDto>;
}

export interface UiApiParticipantsUsersUserIdGetRequest {
    userId: number;
}

export interface UiApiParticipantsUsersUserIdGroupsAddPostRequest {
    userId: number;
    requestBody: Array<number>;
}

export interface UiApiParticipantsUsersUserIdGroupsAvailableGetRequest {
    userId: number;
}

export interface UiApiParticipantsUsersUserIdGroupsGetRequest {
    userId: number;
}

export interface UiApiParticipantsUsersUserIdGroupsRemoveDeleteRequest {
    userId: number;
    requestBody: Array<number>;
}

/**
 * 
 */
export class UiApiParticipantsUsersApi extends runtime.BaseAPI {

    /**
     */
    async uiApiParticipantsUsersPUserIdClaimsAvailableGetRaw(requestParameters: UiApiParticipantsUsersPUserIdClaimsAvailableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantUserClaimReadDto>>> {
        if (requestParameters['pUserId'] == null) {
            throw new runtime.RequiredError(
                'pUserId',
                'Required parameter "pUserId" was null or undefined when calling uiApiParticipantsUsersPUserIdClaimsAvailableGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants-users/{pUserId}/claims/available`.replace(`{${"pUserId"}}`, encodeURIComponent(String(requestParameters['pUserId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantUserClaimReadDtoFromJSON));
    }

    /**
     */
    async uiApiParticipantsUsersPUserIdClaimsAvailableGet(requestParameters: UiApiParticipantsUsersPUserIdClaimsAvailableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantUserClaimReadDto>> {
        const response = await this.uiApiParticipantsUsersPUserIdClaimsAvailableGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersPUserIdClaimsGetRaw(requestParameters: UiApiParticipantsUsersPUserIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantUserClaimReadDto>>> {
        if (requestParameters['pUserId'] == null) {
            throw new runtime.RequiredError(
                'pUserId',
                'Required parameter "pUserId" was null or undefined when calling uiApiParticipantsUsersPUserIdClaimsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants-users/{pUserId}/claims`.replace(`{${"pUserId"}}`, encodeURIComponent(String(requestParameters['pUserId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantUserClaimReadDtoFromJSON));
    }

    /**
     */
    async uiApiParticipantsUsersPUserIdClaimsGet(requestParameters: UiApiParticipantsUsersPUserIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantUserClaimReadDto>> {
        const response = await this.uiApiParticipantsUsersPUserIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdAdminPermissionsPostRaw(requestParameters: UiApiParticipantsUsersUserIdAdminPermissionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdAdminPermissionsPost().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling uiApiParticipantsUsersUserIdAdminPermissionsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/admin-permissions`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uiApiParticipantsUsersUserIdAdminPermissionsPost(requestParameters: UiApiParticipantsUsersUserIdAdminPermissionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsUsersUserIdAdminPermissionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdClaimsAddPostRaw(requestParameters: UiApiParticipantsUsersUserIdClaimsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdClaimsAddPost().'
            );
        }

        if (requestParameters['addClientAllClaimDto'] == null) {
            throw new runtime.RequiredError(
                'addClientAllClaimDto',
                'Required parameter "addClientAllClaimDto" was null or undefined when calling uiApiParticipantsUsersUserIdClaimsAddPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/claims/add`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['addClientAllClaimDto']!.map(AddClientAllClaimDtoToJSON),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uiApiParticipantsUsersUserIdClaimsAddPost(requestParameters: UiApiParticipantsUsersUserIdClaimsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsUsersUserIdClaimsAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdClaimsRemoveDeleteRaw(requestParameters: UiApiParticipantsUsersUserIdClaimsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdClaimsRemoveDelete().'
            );
        }

        if (requestParameters['removeClientAllClaimDto'] == null) {
            throw new runtime.RequiredError(
                'removeClientAllClaimDto',
                'Required parameter "removeClientAllClaimDto" was null or undefined when calling uiApiParticipantsUsersUserIdClaimsRemoveDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/claims/remove`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['removeClientAllClaimDto']!.map(RemoveClientAllClaimDtoToJSON),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uiApiParticipantsUsersUserIdClaimsRemoveDelete(requestParameters: UiApiParticipantsUsersUserIdClaimsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsUsersUserIdClaimsRemoveDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGetRaw(requestParameters: UiApiParticipantsUsersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParticipantUserReadDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParticipantUserReadDtoFromJSON(jsonValue));
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGet(requestParameters: UiApiParticipantsUsersUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParticipantUserReadDto> {
        const response = await this.uiApiParticipantsUsersUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsAddPostRaw(requestParameters: UiApiParticipantsUsersUserIdGroupsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsAddPost().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsAddPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/groups/add`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsAddPost(requestParameters: UiApiParticipantsUsersUserIdGroupsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsUsersUserIdGroupsAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsAvailableGetRaw(requestParameters: UiApiParticipantsUsersUserIdGroupsAvailableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantRoleReadDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsAvailableGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/groups/available`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantRoleReadDtoFromJSON));
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsAvailableGet(requestParameters: UiApiParticipantsUsersUserIdGroupsAvailableGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantRoleReadDto>> {
        const response = await this.uiApiParticipantsUsersUserIdGroupsAvailableGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsGetRaw(requestParameters: UiApiParticipantsUsersUserIdGroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParticipantRoleReadDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/groups`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipantRoleReadDtoFromJSON));
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsGet(requestParameters: UiApiParticipantsUsersUserIdGroupsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParticipantRoleReadDto>> {
        const response = await this.uiApiParticipantsUsersUserIdGroupsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsRemoveDeleteRaw(requestParameters: UiApiParticipantsUsersUserIdGroupsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsRemoveDelete().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiParticipantsUsersUserIdGroupsRemoveDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/participants-users/{userId}/groups/remove`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uiApiParticipantsUsersUserIdGroupsRemoveDelete(requestParameters: UiApiParticipantsUsersUserIdGroupsRemoveDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiParticipantsUsersUserIdGroupsRemoveDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
