import {Alert, Button, Form} from "@p4ma/p4md-react";
import React, {useEffect, useState} from "react";
import {BasePathUrl} from "../../../appsettings";
import esia_logo from "../../../assets/images/esia_logo.svg";
import s from "../styles/loginForm.module.css";
import {useTranslation} from "react-i18next";

export const EsiaLoginButtons: React.FC = () => {
  const [commonAuthRequired, setCommonAuthRequired] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string>();
  const searchParams = new URLSearchParams(window.location.search);
  const {t} = useTranslation();

  const onEsiaLoginClick = () => {
    window.location.href = `${BasePathUrl}/api/ext-auth/LoginEsia?redirectUrl=${
      returnUrl ? returnUrl : ""
    }`;
  };

  useEffect(() => {
    const idx1 = searchParams.toString().indexOf("https");
    if (idx1 > 0) {
      let rUrl = searchParams.toString().substring(idx1);
      rUrl = rUrl.substring(0, rUrl.indexOf("signin-oidc"));
      setReturnUrl(rUrl);
    }
    setCommonAuthRequired(searchParams.get("connect") != null);
  }, [searchParams]);

  return (
    <>
      <Form.Item className={`${s.form__item} ${s.margin__less}`}>
        <Button
          disabled={commonAuthRequired}
          block
          htmlType="button"
          onClick={onEsiaLoginClick}
          className={s.form__button}
        >
          <img src={esia_logo} alt="esia" className={s.form__button__esiaIcon} />
          {t("enterThroughtESIA", {ns: "loginPage"})}
        </Button>
      </Form.Item>

      {commonAuthRequired && (
        <Alert
          message={t("enterThroughtESIAMessage", {ns: "loginPage"})}
          type="warning"/>
      )}
    </>
  );
};
