import {api} from "../../utils/requestConstructor";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {ModuleWithExtensionsDto} from "../../api_client";

export const getModules = createAsyncThunk<
    // Return type of the payload creator
    ModuleWithExtensionsDto[]
>(
    "modules/getModules",
    async () => {
      try {
        const res = await api.get("api/Modules/GetModules");
        if (res.ok) {
          const data = await res.json();
          return data as ModuleWithExtensionsDto[];
        } else {
          return Promise.reject(res.statusText);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    }
);


