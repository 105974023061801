import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  loader: {
    display: "flex",
    widht: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.3
  }
});

export const FallbackLoader = () => {
  const styles = useStyles();
  return (
    <div className={styles.loader}>
      <object data={process.env.PUBLIC_URL + "/logo_loader.svg"} width="100" />
    </div>
  );
};
