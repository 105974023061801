import React, {useContext, useEffect, useState} from "react";
import s from "../styles/login.module.css";
import UISettingsContext from "../../../utils/UISettingsContext";
import RestorePasswordForm from "../components/RestorePasswordForm";
import {useHistory} from "react-router-dom";;
import ChangePasswordForm from "../components/ChangePasswordForm";
import {IRestorePasswordStep} from "../types";
import {Trans, useTranslation} from "react-i18next";
import {Footer} from "../components/Footer";

export const RestorePassword = () => {
  const history = useHistory();
  const [step, setStep] = useState<IRestorePasswordStep>("requestCode");
  const {name, logo} = useContext(UISettingsContext);
  const {t} = useTranslation();

  const onGoBackClick = () => {
    if (step == "updatePassword") {
      setStep("checkCode");
    } else if (step == "checkCode") {
      setStep("requestCode");
    } else {
      if (history.location.state === undefined) {
        history.push("/login");
      } else {
        history.goBack();
      }
    }
  };

  const changeStep = (step: IRestorePasswordStep) => {
    setStep(step);
  };

  return (
    <div className={s.container}>
      <div className={s.restore}>
        <div className={s.restore__header}>
          <div className={s.goBack}  onClick={onGoBackClick} >
            <span className={s.goBack__icon}/>
          </div>
          <span className={s.restore__header__title}>
            {
              step == "updatePassword"  ?
                t("restorePasswordForm.newPasswordHeader", {ns: "loginPage"}) :
                t("restorePasswordForm.header", {ns: "loginPage"})
            }
          </span>
        </div>

        <div className={s.restore__content}>
          <div className={s.login__header}>
            <img
              src={logo}
              alt="P4m"
              className={s.login__header__logo}
            />
            <span className={s.login__header__title}>
              {name || ""}
            </span>
            {step == "requestCode" &&
              <span className={s.restore_help_text}>
              {t("restorePasswordForm.restoreDescription", {ns: "loginPage"})}
              </span>
            }
          </div>

          {step == "updatePassword"
              ? <ChangePasswordForm />
              : <RestorePasswordForm
                  checkCodeStep={step == "checkCode"}
                  changeStep={changeStep}
                />
          }
        </div>
      </div>

      <Footer />
    </div>
  );
};
