/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserClaimAddDto
 */
export interface UserClaimAddDto {
    /**
     * 
     * @type {string}
     * @memberof UserClaimAddDto
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimAddDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimAddDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof UserClaimAddDto
     */
    description?: string;
}

/**
 * Check if a given object implements the UserClaimAddDto interface.
 */
export function instanceOfUserClaimAddDto(value: object): boolean {
    if (!('clientId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function UserClaimAddDtoFromJSON(json: any): UserClaimAddDto {
    return UserClaimAddDtoFromJSONTyped(json, false);
}

export function UserClaimAddDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserClaimAddDto {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'type': json['type'],
        'value': json['value'],
        'description': json['description'] == null ? undefined : json['description'],
    };
}

export function UserClaimAddDtoToJSON(value?: UserClaimAddDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'type': value['type'],
        'value': value['value'],
        'description': value['description'],
    };
}

