import React from "react";
import {DataGrid} from "devextreme-react";
import {createStore} from "@p4ma/devextreme-tools";
import {BasePathUrl} from "../../appsettings";
import "./UserClaims.css";
import {Column, ColumnChooser, FilterRow, Grouping, GroupPanel, HeaderFilter, Item, Scrolling, SearchPanel, StateStoring, Summary, Toolbar, TotalItem} from "devextreme-react/data-grid";
import {Typography} from "@p4ma/p4md-react";
import {useTranslation} from "react-i18next";

const UserClaims = () => {
  const store = createStore({
    loadUrl: `${BasePathUrl}/ui-api/users/GetAllUsersClaims`
  });
  const {t} = useTranslation();

  return (
    <DataGrid
      style={{maxHeight: "calc(100vh - 64px - 48px - 32px)"}}
      showBorders
      showRowLines
      showColumnLines
      showColumnHeaders
      remoteOperations
      allowColumnReordering
      width="100%"
      allowColumnResizing
      columnResizingMode="widget"
      columnAutoWidth
      columnMinWidth={180}
      dataSource={store}
    >
      <ColumnChooser allowSearch enabled mode="select" height="450" />
      <Scrolling mode="virtual" />
      <StateStoring enabled type="localStorage" storageKey="userClaims" />
      <SearchPanel
        visible
        placeholder={t("search")}
        width={200}
        highlightSearchText
        highlightCaseSensitive
      />
      <FilterRow visible />
      <HeaderFilter visible allowSearch={true} />
      <Grouping contextMenuEnabled expandMode="rowClick" />
      <GroupPanel
        visible
        emptyPanelText={t("dataGrid.groupingHint", {ns: "common"})}
      />
      <Column dataField="claimType" dataType="string" caption={t("captions.claimType", {ns: "claimsModule"})} visible={false} />
      <Column dataField="claimTypeDescription" dataType="string" caption={t("captions.claimDescription", {ns: "claimsModule"})} />
      <Column dataField="claimValue" dataType="string" caption={t("captions.claimValue", {ns: "claimsModule"})} visible={false} />
      <Column dataField="claimValueDescription" dataType="string" caption={t("captions.claimValueDescription", {ns: "claimsModule"})} />
      <Column dataField="clientId" dataType="string" caption={t("captions.clientId", {ns: "claimsModule"})} visible={false} />
      <Column dataField="clientName" dataType="string" caption={t("module")} />
      <Column dataField="userId" dataType="string" caption={t("captions.userId", {ns: "claimsModule"})} visible={false} />
      <Column dataField="userName" dataType="string" caption={t("username")} />
      <Column dataField="lastName" dataType="string" caption={t("lastName")} />
      <Column dataField="firstName" dataType="string" caption={t("name")} />
      <Column dataField="middleName" dataType="string" caption={t("middleName")} />
      <Column dataField="roleId" dataType="" caption={t("captions.roleId", {ns: "claimsModule"})} visible={false} />
      <Column dataField="roleName" dataType="string" caption={t("role")} />
      <Column dataField="participantId" dataType="string" caption={t("captions.participantId", {ns: "claimsModule"})} visible={false} />
      <Column dataField="participantName" dataType="string" caption={t("participant")} />
      <Column dataField="participantRoleId" dataType="string" caption={t("captions.participantRoleId", {ns: "claimsModule"})} visible={false} />
      <Column dataField="participantRoleName" dataType="string" caption={t("captions.participantRole", {ns: "claimsModule"})} visible={false} />
      <Toolbar>
        <Item location="before">
          <Typography.Title className="user-claims-title" ellipsis={{rows: 1}} level={3}>
                  {t("userRights")}
          </Typography.Title>
        </Item>
        <Item name="groupPanel" location="before" />
        <Item name="columnChooserButton" location="after" />
        <Item name="searchPanel" location="after" />
      </Toolbar>
    </DataGrid>
  );
};

export default UserClaims;