/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParticipantReadDto } from './ParticipantReadDto';
import {
    ParticipantReadDtoFromJSON,
    ParticipantReadDtoFromJSONTyped,
    ParticipantReadDtoToJSON,
} from './ParticipantReadDto';

/**
 * 
 * @export
 * @interface ParticipantRoleReadDto
 */
export interface ParticipantRoleReadDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantRoleReadDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRoleReadDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantRoleReadDto
     */
    description: string;
    /**
     * 
     * @type {ParticipantReadDto}
     * @memberof ParticipantRoleReadDto
     */
    participant: ParticipantReadDto;
}

/**
 * Check if a given object implements the ParticipantRoleReadDto interface.
 */
export function instanceOfParticipantRoleReadDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('description' in value)) return false;
    if (!('participant' in value)) return false;
    return true;
}

export function ParticipantRoleReadDtoFromJSON(json: any): ParticipantRoleReadDto {
    return ParticipantRoleReadDtoFromJSONTyped(json, false);
}

export function ParticipantRoleReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantRoleReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'participant': ParticipantReadDtoFromJSON(json['participant']),
    };
}

export function ParticipantRoleReadDtoToJSON(value?: ParticipantRoleReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'participant': ParticipantReadDtoToJSON(value['participant']),
    };
}

