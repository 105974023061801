import React, {ReactElement} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useAuthStore} from "../../features/auth/api/useAuthStore";

interface Props {
  children?: ReactElement<any>;
}

const PrivateRoute: React.FC<Props & RouteProps> = (props: Props) => {
  const {children, ...rest} = props;
  const {data: {isAuth}} = useAuthStore();

  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get("ReturnUrl");
  const query = !returnUrl ? "" : `?ReturnUrl=${returnUrl}`;

  return (
    <Route {...rest}>
      {isAuth ? children : <Redirect to={`/login${query}`} />}
    </Route>
  );
};

export default PrivateRoute;
