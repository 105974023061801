/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserReadDto
 */
export interface UserReadDto {
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReadDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserReadDto
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserReadDto
     */
    isOwnedByClient?: boolean;
}

/**
 * Check if a given object implements the UserReadDto interface.
 */
export function instanceOfUserReadDto(value: object): boolean {
    return true;
}

export function UserReadDtoFromJSON(json: any): UserReadDto {
    return UserReadDtoFromJSONTyped(json, false);
}

export function UserReadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserReadDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'isEnabled': json['isEnabled'] == null ? undefined : json['isEnabled'],
        'isOwnedByClient': json['isOwnedByClient'] == null ? undefined : json['isOwnedByClient'],
    };
}

export function UserReadDtoToJSON(value?: UserReadDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userName': value['userName'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'isEnabled': value['isEnabled'],
        'isOwnedByClient': value['isOwnedByClient'],
    };
}

