import {init, BrowserOptions, BrowserTracing, Replay} from "@sentry/react";



const configureSentry = (options?: BrowserOptions) => {
    init({
        integrations: [
            new BrowserTracing(),
            new Replay()
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        ...options
    });
};

export default configureSentry;