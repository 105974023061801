import createSagaMiddleware from "@redux-saga/core";
import {configureStore} from "@reduxjs/toolkit";
import {authReducer} from "../features/auth";
import {participantReducer} from "../features/participants/data";
import clientsReducer from "./clients/clientsSlice";
import groupsReducer from "./groups/groupsSlice";
import modulesReducer from "./modules/modulesSlice";
import participantsReducer from "./participants/participantsSlice";
import rootSaga from "./rootSaga";
import usersReducer from "./users/usersSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    groups: groupsReducer,
    users: usersReducer,
    clients: clientsReducer,
    participants: participantsReducer,
    participant: participantReducer,
    modules: modulesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export type State = ReturnType<typeof store.getState>;
export default store;
