/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ExtensionDto } from './ExtensionDto';
import {
    ExtensionDtoFromJSON,
    ExtensionDtoFromJSONTyped,
    ExtensionDtoToJSON,
} from './ExtensionDto';

/**
 * 
 * @export
 * @interface ModuleWithExtensionsDto
 */
export interface ModuleWithExtensionsDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleWithExtensionsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleWithExtensionsDto
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWithExtensionsDto
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWithExtensionsDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ModuleWithExtensionsDto
     */
    name?: string;
    /**
     * 
     * @type {Array<ExtensionDto>}
     * @memberof ModuleWithExtensionsDto
     */
    extensions?: Array<ExtensionDto>;
}

/**
 * Check if a given object implements the ModuleWithExtensionsDto interface.
 */
export function instanceOfModuleWithExtensionsDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('alias' in value)) return false;
    if (!('url' in value)) return false;
    return true;
}

export function ModuleWithExtensionsDtoFromJSON(json: any): ModuleWithExtensionsDto {
    return ModuleWithExtensionsDtoFromJSONTyped(json, false);
}

export function ModuleWithExtensionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleWithExtensionsDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'alias': json['alias'],
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'url': json['url'],
        'name': json['name'] == null ? undefined : json['name'],
        'extensions': json['extensions'] == null ? undefined : ((json['extensions'] as Array<any>).map(ExtensionDtoFromJSON)),
    };
}

export function ModuleWithExtensionsDtoToJSON(value?: ModuleWithExtensionsDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'alias': value['alias'],
        'clientId': value['clientId'],
        'url': value['url'],
        'name': value['name'],
        'extensions': value['extensions'] == null ? undefined : ((value['extensions'] as Array<any>).map(ExtensionDtoToJSON)),
    };
}

