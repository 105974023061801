/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SortingInfo
 */
export interface SortingInfo {
    /**
     * 
     * @type {string}
     * @memberof SortingInfo
     */
    selector?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortingInfo
     */
    desc?: boolean;
}

/**
 * Check if a given object implements the SortingInfo interface.
 */
export function instanceOfSortingInfo(value: object): boolean {
    return true;
}

export function SortingInfoFromJSON(json: any): SortingInfo {
    return SortingInfoFromJSONTyped(json, false);
}

export function SortingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortingInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'selector': json['selector'] == null ? undefined : json['selector'],
        'desc': json['desc'] == null ? undefined : json['desc'],
    };
}

export function SortingInfoToJSON(value?: SortingInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selector': value['selector'],
        'desc': value['desc'],
    };
}

