/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationUserClaim } from './ApplicationUserClaim';
import {
    ApplicationUserClaimFromJSON,
    ApplicationUserClaimFromJSONTyped,
    ApplicationUserClaimToJSON,
} from './ApplicationUserClaim';
import type { ApplicationUserLogin } from './ApplicationUserLogin';
import {
    ApplicationUserLoginFromJSON,
    ApplicationUserLoginFromJSONTyped,
    ApplicationUserLoginToJSON,
} from './ApplicationUserLogin';
import type { ApplicationUserRole } from './ApplicationUserRole';
import {
    ApplicationUserRoleFromJSON,
    ApplicationUserRoleFromJSONTyped,
    ApplicationUserRoleToJSON,
} from './ApplicationUserRole';
import type { ApplicationUserToken } from './ApplicationUserToken';
import {
    ApplicationUserTokenFromJSON,
    ApplicationUserTokenFromJSONTyped,
    ApplicationUserTokenToJSON,
} from './ApplicationUserToken';
import type { ParticipantUser } from './ParticipantUser';
import {
    ParticipantUserFromJSON,
    ParticipantUserFromJSONTyped,
    ParticipantUserToJSON,
} from './ParticipantUser';

/**
 * 
 * @export
 * @interface ApplicationUser
 */
export interface ApplicationUser {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    normalizedUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    normalizedEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    passwordHash?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    securityStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    concurrencyStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    phoneNumberConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    twoFactorEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    lockoutEnd?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    lockoutEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    accessFailedCount?: number;
    /**
     * 
     * @type {Array<ApplicationUserClaim>}
     * @memberof ApplicationUser
     */
    claims?: Array<ApplicationUserClaim>;
    /**
     * 
     * @type {Array<ApplicationUserLogin>}
     * @memberof ApplicationUser
     */
    logins?: Array<ApplicationUserLogin>;
    /**
     * 
     * @type {Array<ApplicationUserToken>}
     * @memberof ApplicationUser
     */
    tokens?: Array<ApplicationUserToken>;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUser
     */
    userRoles?: Array<ApplicationUserRole>;
    /**
     * 
     * @type {Array<ParticipantUser>}
     * @memberof ApplicationUser
     */
    participantUsers?: Array<ParticipantUser>;
    /**
     * Числовой уникальный порядковый идентификатор записи
     * @type {number}
     * @memberof ApplicationUser
     */
    numId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    middleName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    createDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    auditDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationUser
     */
    claimsAuditDate?: Date;
}

/**
 * Check if a given object implements the ApplicationUser interface.
 */
export function instanceOfApplicationUser(value: object): boolean {
    return true;
}

export function ApplicationUserFromJSON(json: any): ApplicationUser {
    return ApplicationUserFromJSONTyped(json, false);
}

export function ApplicationUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'normalizedUserName': json['normalizedUserName'] == null ? undefined : json['normalizedUserName'],
        'email': json['email'] == null ? undefined : json['email'],
        'normalizedEmail': json['normalizedEmail'] == null ? undefined : json['normalizedEmail'],
        'emailConfirmed': json['emailConfirmed'] == null ? undefined : json['emailConfirmed'],
        'passwordHash': json['passwordHash'] == null ? undefined : json['passwordHash'],
        'securityStamp': json['securityStamp'] == null ? undefined : json['securityStamp'],
        'concurrencyStamp': json['concurrencyStamp'] == null ? undefined : json['concurrencyStamp'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'phoneNumberConfirmed': json['phoneNumberConfirmed'] == null ? undefined : json['phoneNumberConfirmed'],
        'twoFactorEnabled': json['twoFactorEnabled'] == null ? undefined : json['twoFactorEnabled'],
        'lockoutEnd': json['lockoutEnd'] == null ? undefined : (new Date(json['lockoutEnd'])),
        'lockoutEnabled': json['lockoutEnabled'] == null ? undefined : json['lockoutEnabled'],
        'accessFailedCount': json['accessFailedCount'] == null ? undefined : json['accessFailedCount'],
        'claims': json['claims'] == null ? undefined : ((json['claims'] as Array<any>).map(ApplicationUserClaimFromJSON)),
        'logins': json['logins'] == null ? undefined : ((json['logins'] as Array<any>).map(ApplicationUserLoginFromJSON)),
        'tokens': json['tokens'] == null ? undefined : ((json['tokens'] as Array<any>).map(ApplicationUserTokenFromJSON)),
        'userRoles': json['userRoles'] == null ? undefined : ((json['userRoles'] as Array<any>).map(ApplicationUserRoleFromJSON)),
        'participantUsers': json['participantUsers'] == null ? undefined : ((json['participantUsers'] as Array<any>).map(ParticipantUserFromJSON)),
        'numId': json['numId'] == null ? undefined : json['numId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'middleName': json['middleName'] == null ? undefined : json['middleName'],
        'isEnabled': json['isEnabled'] == null ? undefined : json['isEnabled'],
        'createDate': json['createDate'] == null ? undefined : (new Date(json['createDate'])),
        'auditDate': json['auditDate'] == null ? undefined : (new Date(json['auditDate'])),
        'claimsAuditDate': json['claimsAuditDate'] == null ? undefined : (new Date(json['claimsAuditDate'])),
    };
}

export function ApplicationUserToJSON(value?: ApplicationUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userName': value['userName'],
        'normalizedUserName': value['normalizedUserName'],
        'email': value['email'],
        'normalizedEmail': value['normalizedEmail'],
        'emailConfirmed': value['emailConfirmed'],
        'passwordHash': value['passwordHash'],
        'securityStamp': value['securityStamp'],
        'concurrencyStamp': value['concurrencyStamp'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumberConfirmed': value['phoneNumberConfirmed'],
        'twoFactorEnabled': value['twoFactorEnabled'],
        'lockoutEnd': value['lockoutEnd'] == null ? undefined : ((value['lockoutEnd'] as any).toISOString()),
        'lockoutEnabled': value['lockoutEnabled'],
        'accessFailedCount': value['accessFailedCount'],
        'claims': value['claims'] == null ? undefined : ((value['claims'] as Array<any>).map(ApplicationUserClaimToJSON)),
        'logins': value['logins'] == null ? undefined : ((value['logins'] as Array<any>).map(ApplicationUserLoginToJSON)),
        'tokens': value['tokens'] == null ? undefined : ((value['tokens'] as Array<any>).map(ApplicationUserTokenToJSON)),
        'userRoles': value['userRoles'] == null ? undefined : ((value['userRoles'] as Array<any>).map(ApplicationUserRoleToJSON)),
        'participantUsers': value['participantUsers'] == null ? undefined : ((value['participantUsers'] as Array<any>).map(ParticipantUserToJSON)),
        'numId': value['numId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'middleName': value['middleName'],
        'isEnabled': value['isEnabled'],
        'createDate': value['createDate'] == null ? undefined : ((value['createDate'] as any).toISOString()),
        'auditDate': value['auditDate'] == null ? undefined : ((value['auditDate'] as any).toISOString()),
        'claimsAuditDate': value['claimsAuditDate'] == null ? undefined : ((value['claimsAuditDate'] as any).toISOString()),
    };
}

