import {Select} from "antd";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {SupportedLanguage, supportedLanguages} from "../../i18n";
import {DefaultOptionType, SelectProps} from "antd/lib/select";
import {locale} from "devextreme/localization";
import "./LocalizationSelect.css";

type LocalizationSelectProps = {
    languageView?: "short" | "long"
} & SelectProps<SupportedLanguage, DefaultOptionType>

const LocalizationSelect: FC<LocalizationSelectProps> = ({
    languageView = "short",
    ...selectProps
}) => {
    const {i18n, t} = useTranslation();
    const languageItems: DefaultOptionType[] = supportedLanguages.map((lngKey) => {
        let lngLocalizationKey = "";
        if (languageView == "short") {
            lngLocalizationKey = "currentLanguageShortName";
        } else {
            lngLocalizationKey = "currentLanguageName";
        }
        return {
            label: t(lngLocalizationKey, {lng: lngKey, ns: ["static"]}),
            value: lngKey
        };
    });

    return (
        <Select
            size={"small"}
            defaultValue={i18n.language as SupportedLanguage}
            options={languageItems}
            onChange={(value: SupportedLanguage) => {
                i18n.changeLanguage(value);
                locale(value);
            }}
            {...selectProps}
        />
    );
};

export default LocalizationSelect;