/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiErrorResponseWithMessage
 */
export interface ApiErrorResponseWithMessage {
    /**
     * 
     * @type {number}
     * @memberof ApiErrorResponseWithMessage
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorResponseWithMessage
     */
    trace?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorResponseWithMessage
     */
    message?: string;
    /**
     * 
     * @type {any}
     * @memberof ApiErrorResponseWithMessage
     */
    payload?: any;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorResponseWithMessage
     */
    errorName?: string;
}

/**
 * Check if a given object implements the ApiErrorResponseWithMessage interface.
 */
export function instanceOfApiErrorResponseWithMessage(value: object): boolean {
    return true;
}

export function ApiErrorResponseWithMessageFromJSON(json: any): ApiErrorResponseWithMessage {
    return ApiErrorResponseWithMessageFromJSONTyped(json, false);
}

export function ApiErrorResponseWithMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiErrorResponseWithMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'trace': json['trace'] == null ? undefined : json['trace'],
        'message': json['message'] == null ? undefined : json['message'],
        'payload': json['payload'] == null ? undefined : json['payload'],
        'errorName': json['errorName'] == null ? undefined : json['errorName'],
    };
}

export function ApiErrorResponseWithMessageToJSON(value?: ApiErrorResponseWithMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statusCode': value['statusCode'],
        'trace': value['trace'],
        'message': value['message'],
        'payload': value['payload'],
        'errorName': value['errorName'],
    };
}

