import {Client, ClientClaim} from "../../model-types/modelTypes";
import {createSlice} from "@reduxjs/toolkit";
import {getClients, getClaims, getClientClaims} from "./clientsActions";

interface InitialState {
  currentClientClaims?: ClientClaim[];
  clients?: Client[];
  claims?: ClientClaim[];
  actionStatus: {
    getClients: {
      isFetching: boolean;
      isError: boolean;
      errorMessage?: "";
    };
    getClaims: {
      isFetching: boolean;
      isError: boolean;
      errorMessage?: "";
    };
    getClaimsByRole: {
      isFetching: boolean;
      isError: boolean;
      errorMessage?: "";
    };
  };
}

const initialState: InitialState = {
  clients: undefined,
  claims: undefined,
  currentClientClaims: undefined,
  actionStatus: {
    getClients: {
      isFetching: false,
      isError: false,
      errorMessage: ""
    },
    getClaims: {
      isFetching: false,
      isError: false,
      errorMessage: ""
    },
    getClaimsByRole: {
      isFetching: false,
      isError: false,
      errorMessage: ""
    }
  }
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clearCurrentClientClaims(state) {
      state.currentClientClaims = undefined;
    }
  },
  extraReducers: (builder) => {
    // === Get clients ===
    builder.addCase(getClients.pending, (state) => {
      state.actionStatus.getClients.isFetching = true;
      state.actionStatus.getClients.isError = false;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.clients = action.payload;
      state.actionStatus.getClients.isFetching = false;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.actionStatus.getClients.isFetching = false;
      state.actionStatus.getClients.isError = true;
    });

    // === Get claims by role ===
    builder.addCase(getClientClaims.pending, (state) => {
      state.actionStatus.getClaimsByRole.isFetching = true;
      state.actionStatus.getClaimsByRole.isError = false;
    });
    builder.addCase(getClientClaims.fulfilled, (state, action) => {
      state.currentClientClaims = action.payload;
      state.actionStatus.getClaimsByRole.isFetching = false;
    });
    builder.addCase(getClientClaims.rejected, (state) => {
      state.actionStatus.getClaimsByRole.isFetching = false;
      state.actionStatus.getClaimsByRole.isError = true;
    });

    // === Get claims ===
    builder.addCase(getClaims.pending, (state) => {
      state.claims = undefined;
      state.actionStatus.getClaims.isFetching = true;
      state.actionStatus.getClaims.isError = false;
    });
    builder.addCase(getClaims.fulfilled, (state, action) => {
      state.claims = action.payload;
      state.actionStatus.getClaims.isFetching = false;
    });
    builder.addCase(getClaims.rejected, (state) => {
      state.actionStatus.getClaims.isFetching = false;
      state.actionStatus.getClaims.isError = true;
    });
  }
});

export const {clearCurrentClientClaims} = slice.actions;

const clientsReducer = slice.reducer;
export default clientsReducer;
