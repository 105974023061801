import {PayloadAction} from "@reduxjs/toolkit";
import {notification} from "@p4ma/p4md-react";
import {call, put, takeEvery} from "redux-saga/effects";
import {DELETE_GROUP} from "../../actions/index";
import {groupsApi} from "../../api/index";
import {setGroupsActionStatus} from "../../groupsSlice";
import {t} from "i18next";

function* deleteGroup(action: PayloadAction<string>) {
  const id = action.payload;
  try {
    yield put(setGroupsActionStatus({name: "delete", status: "pending"}));
    yield call(groupsApi.delete, id);
    yield put(setGroupsActionStatus({name: "delete", status: "fulfilled"}));
    notification.success({
      message: t("messages.roleDeletedSuccessfully", {ns: "rolesModule"})
    });
  } catch (e) {
    yield put(setGroupsActionStatus({name: "delete", status: "rejected"}));
    notification.error({
      message: t("messages.roleDeletedError", {ns: "rolesModule"})
    });
  }
}

function* deleteGroupWatcher() {
  yield takeEvery(DELETE_GROUP, deleteGroup);
}

export default deleteGroupWatcher;
