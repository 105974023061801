/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequestResult,
  ResetPasswordDto,
} from '../models/index';
import {
    BadRequestResultFromJSON,
    BadRequestResultToJSON,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
} from '../models/index';

export interface UiApiRestoreAccessCheckRestoreCodePostRequest {
    login?: string;
    code?: string;
}

export interface UiApiRestoreAccessResetPasswordPostRequest {
    resetPasswordDto?: ResetPasswordDto;
}

export interface UiApiRestoreAccessSendRestoreCodePostRequest {
    login?: string;
    type?: string;
}

/**
 * 
 */
export class RestoreAccessApi extends runtime.BaseAPI {

    /**
     * Проверяет правильность кода восстановления
     */
    async uiApiRestoreAccessCheckRestoreCodePostRaw(requestParameters: UiApiRestoreAccessCheckRestoreCodePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters['login'] != null) {
            queryParameters['login'] = requestParameters['login'];
        }

        if (requestParameters['code'] != null) {
            queryParameters['code'] = requestParameters['code'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/RestoreAccess/CheckRestoreCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Проверяет правильность кода восстановления
     */
    async uiApiRestoreAccessCheckRestoreCodePost(requestParameters: UiApiRestoreAccessCheckRestoreCodePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiRestoreAccessCheckRestoreCodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Заменяет старый пароль пользователя новым
     */
    async uiApiRestoreAccessResetPasswordPostRaw(requestParameters: UiApiRestoreAccessResetPasswordPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/RestoreAccess/ResetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDtoToJSON(requestParameters['resetPasswordDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Заменяет старый пароль пользователя новым
     */
    async uiApiRestoreAccessResetPasswordPost(requestParameters: UiApiRestoreAccessResetPasswordPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiRestoreAccessResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Отправляет код восстановления пользователю в зависимости от типа восстановления (например, по эл. почте)
     */
    async uiApiRestoreAccessSendRestoreCodePostRaw(requestParameters: UiApiRestoreAccessSendRestoreCodePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters['login'] != null) {
            queryParameters['login'] = requestParameters['login'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/RestoreAccess/SendRestoreCode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Отправляет код восстановления пользователю в зависимости от типа восстановления (например, по эл. почте)
     */
    async uiApiRestoreAccessSendRestoreCodePost(requestParameters: UiApiRestoreAccessSendRestoreCodePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.uiApiRestoreAccessSendRestoreCodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
