import {Input, message} from "@p4ma/p4md-react";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ExternalModuleDto} from "./ExternalModuleDto";
import {BasePathUrl} from "../../appsettings";
import s from "./modulesList.module.css";
import search_icon from "../../assets/images/search_icon.svg";
import {trim} from "lodash";
import {Trans, useTranslation} from "react-i18next";
import {Footer} from "../../features/auth/components/Footer";

export const getModules = async (): Promise<ExternalModuleDto[]> => {
  const response = await fetch(`${BasePathUrl}/ui-api/registration/modules`);
  return response.json();
};


const ModulesList = (props: RouteComponentProps): ReactElement => {
  const [modules, setModules] = useState<ExternalModuleDto[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const {t} = useTranslation();

  useEffect(() => {
    const inner = async () => {
      try {
        const externalModules = await getModules();
        setModules(externalModules);
      } catch (e) {
        message.error(t("messages.receiveModulesInfoError", {ns: "loginPage"}));
        console.error(e);
      }
    };

    inner();
  }, []);

  const renderModules = () => {

    let _modules = modules;
    if(trim(searchValue)) {
      _modules = _modules.filter((elem) => {
        return !!~elem.name.toLowerCase().indexOf(trim(searchValue.toLowerCase()));
      });
    }

    return _modules.map((element) => (

      <div
        key={element.code}
        className={s.modulesList__item}
        onClick={() => window.location.href = element.formUrl}
      >
        <div className={s.item__iconBlock}>
          <img
            className={s.item__iconImg}
            src={process.env.PUBLIC_URL + "/" + element.icon}
          />
        </div>
        <span className={s.item__name}>
          {element.name}
        </span>
      </div>
    ));
  };


  return (
    <div className={s.container}>

      <div className={s.container__title}>
        {t("availableModules", {ns: "loginPage"})}
      </div>

      <div className={s.container__search}>
        <Input
          value={searchValue}
          placeholder={t("search")}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <img src={search_icon} alt="search" />
      </div>

      <div className={s.separator} />

      <div className={s.modulesList}>
        {renderModules()}
      </div>

      <Footer />
    </div>
  );
};

export default withRouter(ModulesList);
