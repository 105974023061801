/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveClientAllClaimDto
 */
export interface RemoveClientAllClaimDto {
    /**
     * Тип клейма
     * @type {string}
     * @memberof RemoveClientAllClaimDto
     */
    clientId: string;
    /**
     * Тип клейма
     * @type {string}
     * @memberof RemoveClientAllClaimDto
     */
    type: string;
    /**
     * Значение клейма
     * @type {string}
     * @memberof RemoveClientAllClaimDto
     */
    value: string;
}

/**
 * Check if a given object implements the RemoveClientAllClaimDto interface.
 */
export function instanceOfRemoveClientAllClaimDto(value: object): boolean {
    if (!('clientId' in value)) return false;
    if (!('type' in value)) return false;
    if (!('value' in value)) return false;
    return true;
}

export function RemoveClientAllClaimDtoFromJSON(json: any): RemoveClientAllClaimDto {
    return RemoveClientAllClaimDtoFromJSONTyped(json, false);
}

export function RemoveClientAllClaimDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveClientAllClaimDto {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'type': json['type'],
        'value': json['value'],
    };
}

export function RemoveClientAllClaimDtoToJSON(value?: RemoveClientAllClaimDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'type': value['type'],
        'value': value['value'],
    };
}

