import {AnyAction, PayloadAction} from "@reduxjs/toolkit";
import {notification} from "@p4ma/p4md-react";
import {call, CallEffect, put, PutEffect, takeEvery} from "redux-saga/effects";
import {CREATE_GROUP} from "../../actions/index";
import {groupsApi} from "../../api/index";
import {
  clearGroupToCreate,
  GroupToCreate,
  setGroupsActionStatus
} from "../../groupsSlice";
import {t} from "i18next";

function* createGroupWorker(action: PayloadAction<GroupToCreate>): Generator<
// step types
CallEffect<string | undefined> | PutEffect<AnyAction>,
// return type
void,
// intermediate argument
string
> {
  yield put(setGroupsActionStatus({name: "create", status: "pending"}));
  try {
    const groupId = yield call(groupsApi.create, action.payload);
    yield put(setGroupsActionStatus({name: "create", status: "fulfilled"}));
    yield put(clearGroupToCreate());
    notification.success({
      message: t("messages.roleCreatedSuccessfully", {ns: "rolesModule"})
    });
  } catch (error: any) {
    yield put(setGroupsActionStatus({name: "create", status: "rejected"}));
    notification.success({
      message: t("messages.roleCreatedError", {ns: "rolesModule"})
    });
  }
}

function* createGroupWatcher() {
  yield takeEvery(CREATE_GROUP, createGroupWorker);
}

export default createGroupWatcher;
