import {LogoutOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Dropdown, Menu, Spin, Typography} from "@p4ma/p4md-react";
import React from "react";
import {useAuthorization} from "../../../../features/auth/api/useAuthorization";
// import {selectAuthActions} from "../../../../redux/auth/selectors";
import s from "./usermenu.module.css";
import {useTranslation} from "react-i18next";

const UserMenu = () => {
  const {loading, signOut, user} = useAuthorization();
  const {t} = useTranslation();

  const userFirstName = user?.firstName && user.firstName !== "-" ? user.firstName : "";
  const userLastName = user?.lastName && user.lastName !== "-" ? user.lastName : "";
  const userMiddleName = user?.middleName && user.middleName !== "-" ? user.middleName : "";

  const menu = (
    <Menu>
      <Menu.Item key="0" icon={<SettingOutlined />} disabled>
      {t("settings")}
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={signOut}
        icon={loading.signOut ? <Spin size="small" /> : <LogoutOutlined />}
      >
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={s.header_user}>
      <div className={s.header_user_info}>
          <span className={s.header_user_info_fullName}>
              {userLastName} {userFirstName} {userMiddleName}
          </span>
          <span className={s.header_user_info_userName} >
              {user?.userName}
          </span>
        </div>
      <Dropdown overlay={menu} trigger={["click"]} className={s.header_avatar}>
        <Avatar shape="circle" size={40} icon={<UserOutlined />} />
      </Dropdown>
    </div>
  );
};

export default UserMenu;
