import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ModuleWithExtensionsDto} from "../../api_client";
import {ApplicationRole, ApplicationUser} from "../../model-types/modelTypes";
import {getClientIdFromModuleName} from "../../utils/getClientIdFromModuleName";
import {ActionStatus} from "../../utils/types";

export interface Group extends ApplicationRole {
  hidden: boolean;
}

interface GroupToCreateClaim {
  claimType: string;
  claimValue: string;
}
export interface GroupToCreate {
  name?: string;
  description?: string;
  clientId?: string;
  module?: ModuleWithExtensionsDto;
  roleClaims?: GroupToCreateClaim[];
  users?: ApplicationUser[];
}

export interface CurrentGroup extends ApplicationRole {
  users: ApplicationUser[];
}

interface InitialState {
  groups?: Group[];
  currentGroup?: CurrentGroup;
  groupToCreate: GroupToCreate;
  actions: {
    getAll: ActionStatus;
    getById: ActionStatus;
    removeUser: ActionStatus;
    create: ActionStatus;
    update: ActionStatus;
    delete: ActionStatus;
  }
}

const initialState: InitialState = {
  groups: undefined,
  groupToCreate: {},
  actions: {
    getAll: "idle",
    getById: "idle",
    create: "idle",
    update: "idle",
    delete: "idle",
    removeUser: "idle"
  }
};

const slice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroupsActionStatus(
        state,
        action: PayloadAction<{
      name: keyof typeof initialState.actions;
      status: ActionStatus;
    }>
    ) {
      const {status, name} = action.payload;
      state.actions[name] = status;
    },
    setCurrentGroup(state, action: PayloadAction<CurrentGroup>) {
      state.currentGroup = action.payload;
    },
    clearCurrentGroup(state) {
      state.currentGroup = undefined;
    },
    setGroups(state, action: PayloadAction<{data: ApplicationRole[]}>) {
      state.groups = action.payload.data.map((groups) => {
        return {
          ...groups,
          createdDate: undefined,
          hidden: false
        };
      });
    },
    filterGroups(state, action: PayloadAction<string>) {
      const searchStr = action.payload;
      const regExp = new RegExp(searchStr, "i");
      state.groups = state.groups?.map((group) => {
        const result = {
          ...group,
          hidden: false
        };
        if (group.name && !regExp.test(group.name)) {
          result.hidden = true;
        }
        return result;
      });
    },
    clearGroupsFilter(state) {
      state.groups = state.groups?.map((group) => {
        return {
          ...group,
          hidden: false
        };
      });
    },
    setGroupToCreateInfo(
        state,
        action: PayloadAction<{name: string; description: string}>
    ) {
      state.groupToCreate = {
        ...action.payload
      };
    },
    setGroupToCreateModule(state, action: PayloadAction<ModuleWithExtensionsDto>) {
      state.groupToCreate.clientId = getClientIdFromModuleName(action.payload?.alias);
      state.groupToCreate.module = action.payload;
      state.groupToCreate.roleClaims = undefined;
    },
    setGroupToCreateClaims(state, action: PayloadAction<GroupToCreateClaim[]>) {
      state.groupToCreate.roleClaims = action.payload;
    },
    setGroupToCreateUsers(state, action: PayloadAction<ApplicationUser[]>) {
      state.groupToCreate.users = action.payload;
    },
    clearGroupToCreate(state) {
      state.groupToCreate = {};
    }
  }
});

export const {
  filterGroups,
  clearGroupsFilter,
  setCurrentGroup,
  setGroupToCreateInfo,
  setGroupToCreateClaims,
  setGroupToCreateModule,
  setGroupToCreateUsers,
  clearGroupToCreate,
  setGroupsActionStatus,
  setGroups,
  clearCurrentGroup
} = slice.actions;

const groupsReducer = slice.reducer;
export default groupsReducer;
