import React, {useContext, useEffect, useState} from "react";
import {validateEmail} from "../../../utils/validateEmail";
import {useAuthorization} from "../api/useAuthorization";
import LockedError from "../components/LockedError";
import LoginForm from "../components/LoginForm";
import UserImportError from "../components/UserImportError";
import {BasePathUrl} from "../../../appsettings";
import {EsiaLoginButtons} from "../components/EsiaLoginButtons";
import s from "../styles/login.module.css";
import {getModules} from "../../../pages/modules-list/ModulesList";
import UISettingsContext from "../../../utils/UISettingsContext";
import {Dropdown, Button, MenuProps, Select, SelectProps} from "antd";
import {SupportedLanguage, supportedLanguages} from "../../../i18n";
import {DefaultOptionType} from "antd/lib/select";
import {useTranslation} from "react-i18next";
import {Footer} from "../components/Footer";

export const Login = () => {
  const {error, loading, signIn} = useAuthorization();
  const searchParams = new URLSearchParams(window.location.search);
  const returnUrl = searchParams.get("ReturnUrl");
  const [isEsiaEnabled, setEsiaEnabled] = useState<boolean>();
  const [showRegistrationBtn, setShowRegistrationBtn] = useState(false);
  const {name, logo} = useContext(UISettingsContext);
  const {t, i18n} = useTranslation(["loginPage", "static"]);

  useEffect(() => {
    const inner = async () => {
      try {
        const externalModules = await getModules();
        if(externalModules.length > 0) {
          setShowRegistrationBtn(true);
        };
      } catch (e) {
        console.error(e);
      }
    };

    inner();
  }, []);

  const onSignIn = ({login, password}: {login: string, password: string}) => {
    if (validateEmail(login)) {
      signIn({
        email: login,
        password: password,
        returnUrl: returnUrl ? returnUrl : ""
      });
    } else {
      signIn({
        username: login,
        password: password,
        returnUrl: returnUrl ? returnUrl : ""
      });
    }
  };

  const getConfiguration = async () => {
    const res = await fetch(`${BasePathUrl}/api/ext-auth/config`);
    const config = await res.json();
    return config;
  };

  const initialize = async () => {
      const config = await getConfiguration();

      setEsiaEnabled(config.esiaEnabled);
  };

  useEffect(() => {
      initialize();
  }, []);

  const languageItems: DefaultOptionType[] = supportedLanguages.map((lngKey) => {
    return {
        label: t("currentLanguageShortName", {lng: lngKey, ns: "static"}),
        value: lngKey
    };
  });

  return (
    <div className={s.container}>
      <div className={s.login}>

        <div className={s.login__header}>
          <img
            src={logo}
            alt="P4m"
            className={s.login__header__logo}
          />
          <span className={s.login__header__title}>{name || ""}</span>
        </div>

        {(!error || error.code !== "LOCKED" && error.code !== "USER_IMPORT_ERR") && (
          <LoginForm
            showRegistrationBtn={showRegistrationBtn}
            loading={loading.signIn}
            error={error}
            onSignIn={onSignIn}
            extra={!isEsiaEnabled ? undefined : <EsiaLoginButtons />}
          />
        )}
        {error?.code === "LOCKED" && (
          <LockedError error={error} />
        )}
        {error?.code === "USER_IMPORT_ERR" && (
          <UserImportError error={error} />
        )}

      </div>

      <Footer />
    </div>
  );
};
