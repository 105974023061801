/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * DTO для обновления данных о пользователе участника в UI
 * @export
 * @interface ParticipantUserCreateDto
 */
export interface ParticipantUserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof ParticipantUserCreateDto
     */
    userId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantUserCreateDto
     */
    isAdmin?: boolean;
}

/**
 * Check if a given object implements the ParticipantUserCreateDto interface.
 */
export function instanceOfParticipantUserCreateDto(value: object): boolean {
    if (!('userId' in value)) return false;
    return true;
}

export function ParticipantUserCreateDtoFromJSON(json: any): ParticipantUserCreateDto {
    return ParticipantUserCreateDtoFromJSONTyped(json, false);
}

export function ParticipantUserCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantUserCreateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'isAdmin': json['isAdmin'] == null ? undefined : json['isAdmin'],
    };
}

export function ParticipantUserCreateDtoToJSON(value?: ParticipantUserCreateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'isAdmin': value['isAdmin'],
    };
}

