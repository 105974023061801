import {LoadingOutlined} from "@ant-design/icons";
import {Row, Spin} from "@p4ma/p4md-react";
import React from "react";
import s from "./loader.module.css";

const antIcon = <LoadingOutlined style={{fontSize: "48px"}} spin />;

const Loader: React.FC = () => (
  <Row className={s.loader}>
    <Spin size="large" indicator={antIcon} />
  </Row>
);

export default Loader;
