/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponseWithMessage,
  ClaimDto,
  ClientAllClaimReadDto,
  DataSourceLoadOptions,
  GroupCreateDto,
  GroupReadDto,
  LoadResult,
  UserReadDto,
} from '../models/index';
import {
    ApiErrorResponseWithMessageFromJSON,
    ApiErrorResponseWithMessageToJSON,
    ClaimDtoFromJSON,
    ClaimDtoToJSON,
    ClientAllClaimReadDtoFromJSON,
    ClientAllClaimReadDtoToJSON,
    DataSourceLoadOptionsFromJSON,
    DataSourceLoadOptionsToJSON,
    GroupCreateDtoFromJSON,
    GroupCreateDtoToJSON,
    GroupReadDtoFromJSON,
    GroupReadDtoToJSON,
    LoadResultFromJSON,
    LoadResultToJSON,
    UserReadDtoFromJSON,
    UserReadDtoToJSON,
} from '../models/index';

export interface UiApiGroupsAddPostRequest {
    groupCreateDto: GroupCreateDto;
}

export interface UiApiGroupsGetByClaimsPostRequest {
    clientId: string;
    claimDto: Array<ClaimDto>;
}

export interface UiApiGroupsGroupIdDeleteRequest {
    groupId: string;
}

export interface UiApiGroupsIdClaimsDeleteRequest {
    id: string;
    clientAllClaimReadDto: Array<ClientAllClaimReadDto>;
}

export interface UiApiGroupsIdClaimsGetRequest {
    id: string;
}

export interface UiApiGroupsIdClaimsPostRequest {
    id: string;
    clientAllClaimReadDto: Array<ClientAllClaimReadDto>;
}

export interface UiApiGroupsIdGetRequest {
    id: string;
}

export interface UiApiGroupsIdPostRequest {
    id: string;
    groupCreateDto: GroupCreateDto;
}

export interface UiApiGroupsIdUsersDeleteRequest {
    id: string;
    requestBody: Array<string>;
}

export interface UiApiGroupsIdUsersGetRequest {
    id: string;
    clientId?: string;
}

export interface UiApiGroupsIdUsersPostRequest {
    id: string;
    requestBody: Array<string>;
}

export interface UiApiGroupsPostRequest {
    clientId?: string;
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

/**
 * 
 */
export class UiApiGroupsApi extends runtime.BaseAPI {

    /**
     * Создание группы
     */
    async uiApiGroupsAddPostRaw(requestParameters: UiApiGroupsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupReadDto>> {
        if (requestParameters['groupCreateDto'] == null) {
            throw new runtime.RequiredError(
                'groupCreateDto',
                'Required parameter "groupCreateDto" was null or undefined when calling uiApiGroupsAddPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupCreateDtoToJSON(requestParameters['groupCreateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupReadDtoFromJSON(jsonValue));
    }

    /**
     * Создание группы
     */
    async uiApiGroupsAddPost(requestParameters: UiApiGroupsAddPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupReadDto> {
        const response = await this.uiApiGroupsAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение групп, содержащих **хотя бы одно** разрешение из переданной коллекции
     * Получение групп по разрешениям
     */
    async uiApiGroupsGetByClaimsPostRaw(requestParameters: UiApiGroupsGetByClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupReadDto>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling uiApiGroupsGetByClaimsPost().'
            );
        }

        if (requestParameters['claimDto'] == null) {
            throw new runtime.RequiredError(
                'claimDto',
                'Required parameter "claimDto" was null or undefined when calling uiApiGroupsGetByClaimsPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/get-by-claims`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['claimDto']!.map(ClaimDtoToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupReadDtoFromJSON));
    }

    /**
     * Получение групп, содержащих **хотя бы одно** разрешение из переданной коллекции
     * Получение групп по разрешениям
     */
    async uiApiGroupsGetByClaimsPost(requestParameters: UiApiGroupsGetByClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupReadDto>> {
        const response = await this.uiApiGroupsGetByClaimsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление группы
     */
    async uiApiGroupsGroupIdDeleteRaw(requestParameters: UiApiGroupsGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['groupId'] == null) {
            throw new runtime.RequiredError(
                'groupId',
                'Required parameter "groupId" was null or undefined when calling uiApiGroupsGroupIdDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/groups/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters['groupId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление группы
     */
    async uiApiGroupsGroupIdDelete(requestParameters: UiApiGroupsGroupIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiGroupsGroupIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Если **хоть один** клейм из списка не будет найден - ничего не удалится
     * Удаление списка клеймов группы
     */
    async uiApiGroupsIdClaimsDeleteRaw(requestParameters: UiApiGroupsIdClaimsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdClaimsDelete().'
            );
        }

        if (requestParameters['clientAllClaimReadDto'] == null) {
            throw new runtime.RequiredError(
                'clientAllClaimReadDto',
                'Required parameter "clientAllClaimReadDto" was null or undefined when calling uiApiGroupsIdClaimsDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['clientAllClaimReadDto']!.map(ClientAllClaimReadDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Если **хоть один** клейм из списка не будет найден - ничего не удалится
     * Удаление списка клеймов группы
     */
    async uiApiGroupsIdClaimsDelete(requestParameters: UiApiGroupsIdClaimsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiGroupsIdClaimsDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Получение клеймов группы
     */
    async uiApiGroupsIdClaimsGetRaw(requestParameters: UiApiGroupsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientAllClaimReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdClaimsGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/groups/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientAllClaimReadDtoFromJSON));
    }

    /**
     * Получение клеймов группы
     */
    async uiApiGroupsIdClaimsGet(requestParameters: UiApiGroupsIdClaimsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientAllClaimReadDto>> {
        const response = await this.uiApiGroupsIdClaimsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление клеймов группы
     */
    async uiApiGroupsIdClaimsPostRaw(requestParameters: UiApiGroupsIdClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdClaimsPost().'
            );
        }

        if (requestParameters['clientAllClaimReadDto'] == null) {
            throw new runtime.RequiredError(
                'clientAllClaimReadDto',
                'Required parameter "clientAllClaimReadDto" was null or undefined when calling uiApiGroupsIdClaimsPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/{id}/claims`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['clientAllClaimReadDto']!.map(ClientAllClaimReadDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление клеймов группы
     */
    async uiApiGroupsIdClaimsPost(requestParameters: UiApiGroupsIdClaimsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiGroupsIdClaimsPostRaw(requestParameters, initOverrides);
    }

    /**
     * Получение группы
     */
    async uiApiGroupsIdGetRaw(requestParameters: UiApiGroupsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupReadDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupReadDtoFromJSON(jsonValue));
    }

    /**
     * Получение группы
     */
    async uiApiGroupsIdGet(requestParameters: UiApiGroupsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupReadDto> {
        const response = await this.uiApiGroupsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновление группы
     */
    async uiApiGroupsIdPostRaw(requestParameters: UiApiGroupsIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdPost().'
            );
        }

        if (requestParameters['groupCreateDto'] == null) {
            throw new runtime.RequiredError(
                'groupCreateDto',
                'Required parameter "groupCreateDto" was null or undefined when calling uiApiGroupsIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupCreateDtoToJSON(requestParameters['groupCreateDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Обновление группы
     */
    async uiApiGroupsIdPost(requestParameters: UiApiGroupsIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uiApiGroupsIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление пользователей из группы
     */
    async uiApiGroupsIdUsersDeleteRaw(requestParameters: UiApiGroupsIdUsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdUsersDelete().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiGroupsIdUsersDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление пользователей из группы
     */
    async uiApiGroupsIdUsersDelete(requestParameters: UiApiGroupsIdUsersDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiGroupsIdUsersDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Получение пользователей группы
     */
    async uiApiGroupsIdUsersGetRaw(requestParameters: UiApiGroupsIdUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserReadDto>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdUsersGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ui-api/groups/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserReadDtoFromJSON));
    }

    /**
     * Получение пользователей группы
     */
    async uiApiGroupsIdUsersGet(requestParameters: UiApiGroupsIdUsersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserReadDto>> {
        const response = await this.uiApiGroupsIdUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление пользователей группы
     */
    async uiApiGroupsIdUsersPostRaw(requestParameters: UiApiGroupsIdUsersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling uiApiGroupsIdUsersPost().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling uiApiGroupsIdUsersPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление пользователей группы
     */
    async uiApiGroupsIdUsersPost(requestParameters: UiApiGroupsIdUsersPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uiApiGroupsIdUsersPostRaw(requestParameters, initOverrides);
    }

    /**
     * Поля объекта \"группа\":  ```  {    id: string;    clientId: string;    createdDate: string;    name: string;    description: string;  }  ```
     * Список групп
     */
    async uiApiGroupsPostRaw(requestParameters: UiApiGroupsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        if (requestParameters['clientId'] != null) {
            queryParameters['clientId'] = requestParameters['clientId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/ui-api/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Поля объекта \"группа\":  ```  {    id: string;    clientId: string;    createdDate: string;    name: string;    description: string;  }  ```
     * Список групп
     */
    async uiApiGroupsPost(requestParameters: UiApiGroupsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.uiApiGroupsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
