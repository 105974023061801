import {Alert, Button} from "@p4ma/p4md-react";
import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {authStore} from "../data";
import {IUserImportError} from "../types";
import {Trans, useTranslation} from "react-i18next";

interface IProps {
  error: IUserImportError;
}

const UserImportError: React.FC<IProps> = ({error}) => {
  const dispatch = useDispatch();
  const {actions} = authStore;
  const {t} = useTranslation();
  const clearError = () => {
    dispatch(actions.setError(undefined));
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center"
      }}
    >
      <Alert
        style={{width: 360}}
        message={<b>{t("messages.syncUserAccountError", {ns: "loginPage"})}</b>}
        description={
          <>
            <p>{error.message}</p>
            <p style={{margin: 0}}>
              <Trans
              i18nKey="toChangePasswordNavigateThroughtLink"
              ns="loginPage"
              components={{
                link: <a target="_blank" rel="noreferrer" href={error.changePasswordLink}/>
              }}
              />
            </p>
          </>
        }
        type="error"
      />
      <Button onClick={clearError} style={{width: 220, marginTop: 10}}>
        {t("back")}
      </Button>
    </div>
  );
};

export default UserImportError;
