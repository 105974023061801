/* tslint:disable */
/* eslint-disable */
/**
 * P4m.Module.Auth.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddUserModel,
  ApplicationRole,
  ApplicationUser,
  Claim,
  DataSourceLoadOptions,
  IdentityResult,
  LoadResult,
  LoginInputModel,
  OrganisationInfo,
  P4mClaim,
  UpdateUserModel,
} from '../models/index';
import {
    AddUserModelFromJSON,
    AddUserModelToJSON,
    ApplicationRoleFromJSON,
    ApplicationRoleToJSON,
    ApplicationUserFromJSON,
    ApplicationUserToJSON,
    ClaimFromJSON,
    ClaimToJSON,
    DataSourceLoadOptionsFromJSON,
    DataSourceLoadOptionsToJSON,
    IdentityResultFromJSON,
    IdentityResultToJSON,
    LoadResultFromJSON,
    LoadResultToJSON,
    LoginInputModelFromJSON,
    LoginInputModelToJSON,
    OrganisationInfoFromJSON,
    OrganisationInfoToJSON,
    P4mClaimFromJSON,
    P4mClaimToJSON,
    UpdateUserModelFromJSON,
    UpdateUserModelToJSON,
} from '../models/index';

export interface AccountAddRoleIdClientPostRequest {
    idClient: string;
    applicationRole: ApplicationRole;
}

export interface AccountAddUserPostRequest {
    addUserModel: AddUserModel;
}

export interface AccountChangePasswordUserIdUserPostRequest {
    idUser: string;
    oldPassworc: string;
    newPassword: string;
}

export interface AccountDeleteRoleRoleIdPostRequest {
    roleId: string;
}

export interface AccountDeleteUserIdUserPostRequest {
    idUser: string;
}

export interface AccountGetRoleRoleIdGetRequest {
    roleId: string;
}

export interface AccountGetRolesByUserUserIdGetRequest {
    userId: string;
}

export interface AccountGetRolesPostRequest {
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface AccountGetUserByIdIdUserGetRequest {
    idUser: string;
}

export interface AccountGetUserByLoginLoginGetRequest {
    login: string;
}

export interface AccountGetUserClaimsIdClientIdUserGetRequest {
    idClient: string;
    idUser: string;
}

export interface AccountGetUserOrganisationUserIdGetRequest {
    userId: string;
}

export interface AccountGetUsersByRoleRoleIdGetRequest {
    roleId: string;
}

export interface AccountGetUsersDXPostRequest {
    dataSourceLoadOptions?: DataSourceLoadOptions;
}

export interface AccountListUserLayoutsIdUserGetRequest {
    idUser: string;
}

export interface AccountLoginPostRequest {
    loginInputModel: LoginInputModel;
    button?: string;
}

export interface AccountLogoutGetRequest {
    logoutId?: string;
}

export interface AccountUpdateRoleIdClientRoleIdPostRequest {
    idClient: string;
    roleId: string;
    applicationRole?: ApplicationRole;
}

export interface AccountUpdateUserClaimsIdClientIdUserPostRequest {
    idClient: string;
    idUser: string;
    p4mClaim: Array<P4mClaim>;
}

export interface AccountUpdateUserIdUserPostRequest {
    idUser: string;
    updateUserModel: UpdateUserModel;
}

export interface AccountUpdateUserRolesIdUserPostRequest {
    idUser: string;
    requestBody: Array<string>;
}

export interface AccountUpdateUsersRangePostRequest {
    updateUserModel: Array<UpdateUserModel>;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Добавление роли
     */
    async accountAddRoleIdClientPostRaw(requestParameters: AccountAddRoleIdClientPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling accountAddRoleIdClientPost().'
            );
        }

        if (requestParameters['applicationRole'] == null) {
            throw new runtime.RequiredError(
                'applicationRole',
                'Required parameter "applicationRole" was null or undefined when calling accountAddRoleIdClientPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/AddRole/{idClient}`.replace(`{${"idClient"}}`, encodeURIComponent(String(requestParameters['idClient']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationRoleToJSON(requestParameters['applicationRole']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Добавление роли
     */
    async accountAddRoleIdClientPost(requestParameters: AccountAddRoleIdClientPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.accountAddRoleIdClientPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление пользователя
     */
    async accountAddUserPostRaw(requestParameters: AccountAddUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['addUserModel'] == null) {
            throw new runtime.RequiredError(
                'addUserModel',
                'Required parameter "addUserModel" was null or undefined when calling accountAddUserPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/AddUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddUserModelToJSON(requestParameters['addUserModel']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Добавление пользователя
     */
    async accountAddUserPost(requestParameters: AccountAddUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.accountAddUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Смена пароля пользователя
     */
    async accountChangePasswordUserIdUserPostRaw(requestParameters: AccountChangePasswordUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityResult>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountChangePasswordUserIdUserPost().'
            );
        }

        if (requestParameters['oldPassworc'] == null) {
            throw new runtime.RequiredError(
                'oldPassworc',
                'Required parameter "oldPassworc" was null or undefined when calling accountChangePasswordUserIdUserPost().'
            );
        }

        if (requestParameters['newPassword'] == null) {
            throw new runtime.RequiredError(
                'newPassword',
                'Required parameter "newPassword" was null or undefined when calling accountChangePasswordUserIdUserPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['oldPassworc'] != null) {
            queryParameters['OldPassworc'] = requestParameters['oldPassworc'];
        }

        if (requestParameters['newPassword'] != null) {
            queryParameters['NewPassword'] = requestParameters['newPassword'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/ChangePasswordUser/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityResultFromJSON(jsonValue));
    }

    /**
     * Смена пароля пользователя
     */
    async accountChangePasswordUserIdUserPost(requestParameters: AccountChangePasswordUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityResult> {
        const response = await this.accountChangePasswordUserIdUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление роли
     */
    async accountDeleteRoleRoleIdPostRaw(requestParameters: AccountDeleteRoleRoleIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityResult>> {
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError(
                'roleId',
                'Required parameter "roleId" was null or undefined when calling accountDeleteRoleRoleIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/DeleteRole/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityResultFromJSON(jsonValue));
    }

    /**
     * Удаление роли
     */
    async accountDeleteRoleRoleIdPost(requestParameters: AccountDeleteRoleRoleIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityResult> {
        const response = await this.accountDeleteRoleRoleIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удаление пользователя
     */
    async accountDeleteUserIdUserPostRaw(requestParameters: AccountDeleteUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityResult>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountDeleteUserIdUserPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/DeleteUser/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityResultFromJSON(jsonValue));
    }

    /**
     * Удаление пользователя
     */
    async accountDeleteUserIdUserPost(requestParameters: AccountDeleteUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityResult> {
        const response = await this.accountDeleteUserIdUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetListUsersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApplicationUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetListUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationUserFromJSON));
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetListUsersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApplicationUser>> {
        const response = await this.accountGetListUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Получение роли с клайм
     */
    async accountGetRoleRoleIdGetRaw(requestParameters: AccountGetRoleRoleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationRole>> {
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError(
                'roleId',
                'Required parameter "roleId" was null or undefined when calling accountGetRoleRoleIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetRole/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationRoleFromJSON(jsonValue));
    }

    /**
     * Получение роли с клайм
     */
    async accountGetRoleRoleIdGet(requestParameters: AccountGetRoleRoleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationRole> {
        const response = await this.accountGetRoleRoleIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка ролей пользователя
     */
    async accountGetRolesByUserUserIdGetRaw(requestParameters: AccountGetRolesByUserUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling accountGetRolesByUserUserIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetRolesByUser/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Получение списка ролей пользователя
     */
    async accountGetRolesByUserUserIdGet(requestParameters: AccountGetRolesByUserUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.accountGetRolesByUserUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка ролей с клайм
     */
    async accountGetRolesPostRaw(requestParameters: AccountGetRolesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetRoles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Получение списка ролей с клайм
     */
    async accountGetRolesPost(requestParameters: AccountGetRolesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.accountGetRolesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка клайм модуля авторизации текущего пользователя
     */
    async accountGetUserAuthClaimsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Claim>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUserAuthClaims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaimFromJSON));
    }

    /**
     * Получение списка клайм модуля авторизации текущего пользователя
     */
    async accountGetUserAuthClaimsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Claim>> {
        const response = await this.accountGetUserAuthClaimsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Возвращает пользователя по ИД
     */
    async accountGetUserByIdIdUserGetRaw(requestParameters: AccountGetUserByIdIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationUser>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountGetUserByIdIdUserGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUserById/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationUserFromJSON(jsonValue));
    }

    /**
     * Возвращает пользователя по ИД
     */
    async accountGetUserByIdIdUserGet(requestParameters: AccountGetUserByIdIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationUser> {
        const response = await this.accountGetUserByIdIdUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает пользователя по логину Name|Email
     */
    async accountGetUserByLoginLoginGetRaw(requestParameters: AccountGetUserByLoginLoginGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['login'] == null) {
            throw new runtime.RequiredError(
                'login',
                'Required parameter "login" was null or undefined when calling accountGetUserByLoginLoginGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUserByLogin/{login}`.replace(`{${"login"}}`, encodeURIComponent(String(requestParameters['login']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Возвращает пользователя по логину Name|Email
     */
    async accountGetUserByLoginLoginGet(requestParameters: AccountGetUserByLoginLoginGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.accountGetUserByLoginLoginGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка клайм пользователя
     */
    async accountGetUserClaimsIdClientIdUserGetRaw(requestParameters: AccountGetUserClaimsIdClientIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Claim>>> {
        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling accountGetUserClaimsIdClientIdUserGet().'
            );
        }

        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountGetUserClaimsIdClientIdUserGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUserClaims/{idClient}/{idUser}`.replace(`{${"idClient"}}`, encodeURIComponent(String(requestParameters['idClient']))).replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClaimFromJSON));
    }

    /**
     * Получение списка клайм пользователя
     */
    async accountGetUserClaimsIdClientIdUserGet(requestParameters: AccountGetUserClaimsIdClientIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Claim>> {
        const response = await this.accountGetUserClaimsIdClientIdUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение информации об участнке пользователя
     */
    async accountGetUserOrganisationUserIdGetRaw(requestParameters: AccountGetUserOrganisationUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganisationInfo>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling accountGetUserOrganisationUserIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUserOrganisation/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationInfoFromJSON(jsonValue));
    }

    /**
     * Получение информации об участнке пользователя
     */
    async accountGetUserOrganisationUserIdGet(requestParameters: AccountGetUserOrganisationUserIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganisationInfo> {
        const response = await this.accountGetUserOrganisationUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение списка пользователей, принадлежащих роли
     */
    async accountGetUsersByRoleRoleIdGetRaw(requestParameters: AccountGetUsersByRoleRoleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApplicationUser>>> {
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError(
                'roleId',
                'Required parameter "roleId" was null or undefined when calling accountGetUsersByRoleRoleIdGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUsersByRole/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationUserFromJSON));
    }

    /**
     * Получение списка пользователей, принадлежащих роли
     */
    async accountGetUsersByRoleRoleIdGet(requestParameters: AccountGetUsersByRoleRoleIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApplicationUser>> {
        const response = await this.accountGetUsersByRoleRoleIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetUsersDXPostRaw(requestParameters: AccountGetUsersDXPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoadResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUsersDX`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataSourceLoadOptionsToJSON(requestParameters['dataSourceLoadOptions']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadResultFromJSON(jsonValue));
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetUsersDXPost(requestParameters: AccountGetUsersDXPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoadResult> {
        const response = await this.accountGetUsersDXPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetUsersGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApplicationUser>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/GetUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApplicationUserFromJSON));
    }

    /**
     * Возвращает список всех пользователей
     */
    async accountGetUsersGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApplicationUser>> {
        const response = await this.accountGetUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Возвращает список рабочих столов пользователя всех модулей
     */
    async accountListUserLayoutsIdUserGetRaw(requestParameters: AccountListUserLayoutsIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountListUserLayoutsIdUserGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/ListUserLayouts/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Возвращает список рабочих столов пользователя всех модулей
     */
    async accountListUserLayoutsIdUserGet(requestParameters: AccountListUserLayoutsIdUserGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.accountListUserLayoutsIdUserGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Вход пользователя в модуль
     */
    async accountLoginPostRaw(requestParameters: AccountLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['loginInputModel'] == null) {
            throw new runtime.RequiredError(
                'loginInputModel',
                'Required parameter "loginInputModel" was null or undefined when calling accountLoginPost().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['button'] != null) {
            queryParameters['button'] = requestParameters['button'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginInputModelToJSON(requestParameters['loginInputModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Вход пользователя в модуль
     */
    async accountLoginPost(requestParameters: AccountLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountLoginPostRaw(requestParameters, initOverrides);
    }

    /**
     * Выход пользователя из модуля
     */
    async accountLogoutGetRaw(requestParameters: AccountLogoutGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['logoutId'] != null) {
            queryParameters['logoutId'] = requestParameters['logoutId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/Logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Выход пользователя из модуля
     */
    async accountLogoutGet(requestParameters: AccountLogoutGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountLogoutGetRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление роли
     */
    async accountUpdateRoleIdClientRoleIdPostRaw(requestParameters: AccountUpdateRoleIdClientRoleIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling accountUpdateRoleIdClientRoleIdPost().'
            );
        }

        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError(
                'roleId',
                'Required parameter "roleId" was null or undefined when calling accountUpdateRoleIdClientRoleIdPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/UpdateRole/{idClient}/{roleId}`.replace(`{${"idClient"}}`, encodeURIComponent(String(requestParameters['idClient']))).replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationRoleToJSON(requestParameters['applicationRole']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Обновление роли
     */
    async accountUpdateRoleIdClientRoleIdPost(requestParameters: AccountUpdateRoleIdClientRoleIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.accountUpdateRoleIdClientRoleIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновление клайм пользователя
     */
    async accountUpdateUserClaimsIdClientIdUserPostRaw(requestParameters: AccountUpdateUserClaimsIdClientIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['idClient'] == null) {
            throw new runtime.RequiredError(
                'idClient',
                'Required parameter "idClient" was null or undefined when calling accountUpdateUserClaimsIdClientIdUserPost().'
            );
        }

        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountUpdateUserClaimsIdClientIdUserPost().'
            );
        }

        if (requestParameters['p4mClaim'] == null) {
            throw new runtime.RequiredError(
                'p4mClaim',
                'Required parameter "p4mClaim" was null or undefined when calling accountUpdateUserClaimsIdClientIdUserPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/UpdateUserClaims/{idClient}/{idUser}`.replace(`{${"idClient"}}`, encodeURIComponent(String(requestParameters['idClient']))).replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['p4mClaim']!.map(P4mClaimToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление клайм пользователя
     */
    async accountUpdateUserClaimsIdClientIdUserPost(requestParameters: AccountUpdateUserClaimsIdClientIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountUpdateUserClaimsIdClientIdUserPostRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление данных пользователя
     */
    async accountUpdateUserIdUserPostRaw(requestParameters: AccountUpdateUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountUpdateUserIdUserPost().'
            );
        }

        if (requestParameters['updateUserModel'] == null) {
            throw new runtime.RequiredError(
                'updateUserModel',
                'Required parameter "updateUserModel" was null or undefined when calling accountUpdateUserIdUserPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/UpdateUser/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserModelToJSON(requestParameters['updateUserModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление данных пользователя
     */
    async accountUpdateUserIdUserPost(requestParameters: AccountUpdateUserIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountUpdateUserIdUserPostRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление ролей пользователя
     */
    async accountUpdateUserRolesIdUserPostRaw(requestParameters: AccountUpdateUserRolesIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['idUser'] == null) {
            throw new runtime.RequiredError(
                'idUser',
                'Required parameter "idUser" was null or undefined when calling accountUpdateUserRolesIdUserPost().'
            );
        }

        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling accountUpdateUserRolesIdUserPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/UpdateUserRoles/{idUser}`.replace(`{${"idUser"}}`, encodeURIComponent(String(requestParameters['idUser']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление ролей пользователя
     */
    async accountUpdateUserRolesIdUserPost(requestParameters: AccountUpdateUserRolesIdUserPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountUpdateUserRolesIdUserPostRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление данных нескольких пользователей
     */
    async accountUpdateUsersRangePostRaw(requestParameters: AccountUpdateUsersRangePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['updateUserModel'] == null) {
            throw new runtime.RequiredError(
                'updateUserModel',
                'Required parameter "updateUserModel" was null or undefined when calling accountUpdateUsersRangePost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/Account/UpdateUsersRange`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['updateUserModel']!.map(UpdateUserModelToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновление данных нескольких пользователей
     */
    async accountUpdateUsersRangePost(requestParameters: AccountUpdateUsersRangePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountUpdateUsersRangePostRaw(requestParameters, initOverrides);
    }

}
